import { Injectable } from '@angular/core';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { Observable } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import {
	CreateRestorativeExportFileRequestDto,
	CreateOrthodonticsExportFileRequestDto,
	CreateExportFilesRequestDto,
	ExportRequestDto
} from '../generalInterfaces';
import { ExportStatus } from '../enums';
import { PlatformDetectorService } from '../../core/platformDetector.service';

@Injectable()
export class ExportPopupService {
	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private golbalSettingService: GlobalSettingsService,
		private platformDetector: PlatformDetectorService
	) {}

	static windowNumber: number = 1;

	static show(request: ExportRequestDto): void {
		const width = 700;
		const height = 280;
		const leftPosition = window.screen.width / 2 - (width / 2 + 10);
		const topPosition = window.screen.height / 2 - (height / 2 + 50);

		window.open(
			`/rx/export/popup?parallelWorkOrderId=${request.parallelWorkOrderId}&orderId=${request.orderId}&orderDetailsId=${request.orderDetailsId}`,
			`exportWindow${ExportPopupService.windowNumber}`,
			`status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no,resizable=no`
		);

		ExportPopupService.windowNumber++;
	}

	getOrthodonticsExportInfo(requestData: CreateOrthodonticsExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getOrthodonticsExportInfo, requestData);
	}

	getRestorativeExportInfo(requestData: CreateRestorativeExportFileRequestDto): Observable<ExportInfoDto> {
		return this.http.post(this.eupRoutesService.orders.getRestorativeExportInfo, requestData);
	}

	getExportInfoBulk(requestData: CreateExportFilesRequestDto): Observable<ExportInfoDto[]> {
		return this.http.post(this.eupRoutesService.orders.getExportInfoBulk, requestData);
  }

  getExportInfoBulkByWorkOrder(requestData: CreateExportFilesRequestDto): Observable<ExportInfoDto[]> {
    return this.http.post(this.eupRoutesService.orders.getExportInfoBulkByWorkOrder, requestData);
  }

	getOrthodonticsExportInfoByWorkOrder(requestData: CreateOrthodonticsExportFileRequestDto): Observable<ExportInfoDto> {
    return this.http.post(this.eupRoutesService.orders.getOrthodonticsExportInfoByWorkOrder, requestData);
  }
  
  getRestorativeExportInfoByWorkOrder(requestData: CreateRestorativeExportFileRequestDto): Observable<ExportInfoDto> {
    return this.http.post(this.eupRoutesService.orders.getRestorativeExportInfoByWorkOrder, requestData);
  }

	getExportStatus(requestData: ExportRequestDto): Observable<ExportStatus> {
		return this.http.post(this.eupRoutesService.orders.getExportStatus, requestData);
	}

	downloadStlFile(requestData: ExportRequestDto): void {
		const url =
			this.eupRoutesService.orders.getStlFile +
			`?parallelWorkOrderId=${requestData.parallelWorkOrderId}&orderId=${requestData.orderId}&orderDetailsId=${requestData.orderDetailsId}`;
		const target = `download${ExportPopupService.windowNumber}`;
		if (this.platformDetector.isSafari) {
			window.open(url, target, 'height=50,width=50');
		} else {
			window.open(url, target);
		}
	}
}

export class ExportInfoDto {
	status: ExportStatus;
	parallelWorkOrderId: number;
  isFileExists: boolean;
	orderId?: number;
  workOrderId?: number;
}

export class CheckIfFileReadyResult {
	orderId: number;
	isFileExists: boolean;
}
