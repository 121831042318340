﻿import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { ErrorsModule } from './errors/errors.module';
import { NotificationComponent } from './shared/notification/notification.component';
import { NotificationService } from './shared/notification/notification.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCaseUrlSerializerService } from './core/lowerCaseUrlSerializer.service';
import { NanobarService } from './core/nanobar.service';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { DownloadFileService } from './shared/downloadFile.service';
import { SoftwareOptionsService } from './core/softwareOptions.service';
import { RxService } from './rx/rx.service';
import { ModalIframeService } from './services/ModalIframe/modalIframe.service';
import { LabRxExtensionService } from './rx/labRxExtension/labRxExtension.service';
import { OrdersService } from './doctors/orders/orders.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EupToastService } from './shared/eupToast.service';
import { FeaturesToggleSettingsService } from './featuresToggleSettings/service/featuresToggleSettings.service';
import { FeaturesToggleSettingsGuard } from './featuresToggleSettings/featuresToggleSettingsGuard.service';
import { AppConfigService } from './services/appConfig/appConfigService';
import { FeatureAvailabilityService } from './services/featureAvailability/featureAvailability.service';
import { DesktopNotificationsInitializerService } from './services/DesktopNotifications/desktop-notifications-initializer.service';

@NgModule({
	imports: [
		BrowserModule,
		RouterModule,
		CoreModule,
		ErrorsModule,
		AppRoutes,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		ProgressbarModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TranslateModule,
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot()
	],
	declarations: [ AppComponent, NotificationComponent ],
	providers: [
		NotificationService,
		LabRxExtensionService,
		RxService,
		ModalIframeService,
		DownloadNotificationService,
		DownloadFileService,
		SoftwareOptionsService,
		FeaturesToggleSettingsService,
		FeaturesToggleSettingsGuard,
		EupToastService,
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializerService
		},
		OrdersService,
		NanobarService,
		FeatureAvailabilityService,
		// *** Error handler code to restore for 20A
		// {
		// 	provide: ErrorHandler,
		// 	useClass: ErrorHandlerService
		// }, ***
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [
				AppConfigService,
				Injector
			],
			useFactory: (appConfigService: AppConfigService,
						 injector: Injector) => {
				return () => {
					return appConfigService.loadAppConfig()
						.then(() => {
							injector.get(DesktopNotificationsInitializerService).trackAuthentication();
						}).catch(console.error);
				};
			}
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
