/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./error-page.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../core/globalSettings.service";
var styles_ErrorPage = [i0.styles];
var RenderType_ErrorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPage, data: {} });
export { RenderType_ErrorPage as RenderType_ErrorPage };
function View_ErrorPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 3), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(6, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 4, 0, "/", _co.rolePath, "home"); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescriptionToDisplay; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _co.linkToText; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.errorDescriptionToDisplay2; _ck(_v, 6, 0, currVal_5); }); }
function View_ErrorPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(6, null, ["", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/login"); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescriptionToDisplay; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _co.linkToText; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.errorDescriptionToDisplay2; _ck(_v, 6, 0, currVal_5); }); }
export function View_ErrorPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "error-reason"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPage_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPage_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isUserLoggedIn(); _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.isUserLoggedIn(); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusCode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errorHeader; _ck(_v, 4, 0, currVal_1); }); }
export function View_ErrorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eup-error-page", [], null, null, null, View_ErrorPage_0, RenderType_ErrorPage)), i1.ɵdid(1, 2146304, null, 0, i4.ErrorPage, [i2.Router, i1.ElementRef, i5.TranslateService, i6.GlobalSettingsService], null, null)], null, null); }
var ErrorPageNgFactory = i1.ɵccf("eup-error-page", i4.ErrorPage, View_ErrorPage_Host_0, { statusCode: "statusCode", errorHeader: "errorHeader", errorDescription: "errorDescription", errorDescriptionToDisplay: "errorDescriptionToDisplay", errorDescriptionToDisplay2: "errorDescriptionToDisplay2" }, {}, []);
export { ErrorPageNgFactory as ErrorPageNgFactory };
