﻿import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
	transform(value: string, search: string): string {
		if (!search || !value) {
			return value;
		}

		return value.toString().replace(new RegExp(search, 'gi'), (match) => {
			return `<span class="eup-highlight-text">${match}</span>`;
		});
	}
}
