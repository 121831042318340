export enum CaseTypeEnum {
	iCast = 5,
	iRecord = 13,
	InvisalignAndiRecord = 34
}

export enum ExportTypeFormatEnum {
	OpenShell = 1,
	SolidModelWithLowProfileBase = 4,
	HollowABOBase = 5,
	SolidABOBase = 8
}

export enum ExportDataFormatEnum {
	Unknown = 0,
	TwoFiles_EachArchWithTeethUp = 3,
	TwoFiles_ArchesOrientedInOcclusion = 2,
	OneFile_ArchesLockedInOcclusion = 1
}

export enum FileTypeParallelEnum {
	Unknown = 0,
	STL = 1,
	PLY = 2,
	STL_PLY = 3
}

export enum ExportStatus {
	NoStatus = -1,
	Created = 1,
	Released = 2,
	Active = 3,
	Completed = 4,
	Failed = 5,
	Canceled = 6
}

export enum RoleTypeEnum {
	Doctor = 0,
	Lab = 1,
	Personnel = 2,
	Distributor = 3,
	ManagementCompany = 4
}

export enum ExcelExtensionsEnum {
	Xlsx,
	Xls,
	Csv
}

export enum Appearance {
	Dark = 1,
	Light
}

export enum LabCasesState {
	CurrentCases,
	PendingCases,
	TotalCases
}

export enum AssetReferenceType {
	OrderId = 1,
	ParallelWorkOrderId = 2,
	ActivityId = 3,
	IdeSalesOrderDetailsId = 4,
	Fms_none = 5,
	Fms_3ds = 6,
	Fms_3dc = 7,
	Fms_3dz = 8,
	Fms_gCode = 9,
	Fms_catalog = 10,
	Fms_images = 11,
	Fms_3dm = 12,
	Fms_fs3dm = 13,
	Fms_evx = 14,
	Fms_niri = 15,
	Fms_capture = 16
}

export enum FileType {
	Evx = 9,
	Niri = 10,
	Capture = 11
}

export enum WindowsApplicationProtocol {
	IteroLab = 0,
	OrthoCad = 1
}

export enum PrintFormat {
	Vertical = 1,
	Horizontal = 2
}

export enum GenderType {
	Male = 0,
	Female = 1,
	Empty = ''
}

export enum BillOfWorkType {
	Missing_Waiting_Ortho_RxForm = -4,
	Inactive = -3,
	Cancelled = -2,
	Completed = -1,
	Scanning = 100,
	Order_Registration = 101,
	iTero_Modeling = 110,
	iTero_Modeling_RTM = 112,
	Redo_iTero_Modeling = 115,
	Ortho_Modeling_iCast = 120,
	Ortho_Modeling_iCast_RTM = 123,
	Redo_Ortho_Modeling_iCast = 125,
	Ortho_Modeling_iCast_127 = 127,
	Redo_Ortho_Modeling_iCast_Conventional = 128,
	Doctor_Download = 130,
	ReadyForDownload=131,
	Align_Export = 135,
	Manual_Align_Export = 136,
	Ortho_Modeling_iQ = 140,
	Ortho_Modeling_iQ_Upgrade = 141,
	Ortho_Modeling_iQ_RTM = 143,
	Redo_Ortho_Modeling_iQ = 145,
	Ortho_Modeling_iQ_Site_Scanning = 146,
	Ortho_Modeling_iQ_Conventional = 147,
	Redo_Ortho_Modeling_iQ_Conventional = 148,
	Ortho_Modeling_iQ_Conventional_Upgrade = 149,
	Doctor_Review = 150,
	Doctor_Review_iQ_Conventional = 155,
	Ortho_Modeling_VS_Revision = 160,
	Ortho_Modeling_VS_Revision_iQ_Conventional = 165,
	Model_Printing = 170,
	Sync_Process = 180,
	iQ_tray_Production = 190,
	On_Line_Registration = 200,
	Case_Registration = 210,
	Case_Shipment_to_Cadent = 215,
	Lab_Process = 220,
	Redo_Lab_Process = 225,
	iQ_Shipping_to_Dr = 230,
	Ortho_Modeling_VS = 240,
	Ortho_Modeling_VS_RTM = 243,
	Redo_Ortho_Modeling_VS = 245,
	Ortho_Modeling_VS_Conventional = 247,
	Redo_Ortho_Modeling_VS_Conventional = 248,
	Web_3D_File_Generation = 300,
	Export_File_Generation = 310,
	Send_To_3rd_Party = 320,
	Interpretation = 500,
	Interpretation2 = 505,
	LabReview = 510,
	iDX = 513,
	Third_Party_iDE = 516,
	iDE_Order = 517,
	Manual_iDE = 518,
	GCODE_Generation = 520,
	Manual_GCODE_Generation = 525,
	iDX_QC = 530,
	iDX_QC_Lab = 532,
	Milling = 540,
	Model_QC = 550,
	Shipping = 600,
	Ortho_Modeling = 700,
	Ortho_Setup = 720,
	Bracket_QC = 740
}


export enum StorageType {
	ACS = 0,
	OriginalFMS = 1
}

export enum EvxSoftwareOptions {
	ShouldEnableEvx= 2087,
	ShouldDisableNiriWebReviewTool= 2091,
	ShouldEnableIntraoralCameraTool= 2092
}

export enum SnapshotSoftwareOptions{
	ShouldDisableDrawingTool = 2093,
	ShouldDisableCaptureTool = 2094
}

export enum SoftwareOptionsForCompany {
	shouldDisablePatientManagement = 2118,
	shouldDisableHidePatientDetails = 2110

}


export enum ToothNumberingSystem {
	FDI = 0,
	ADA = 1,
	QUADRANT = 2
}

export enum ThirdPartyRxProviders {
	Glidewell = 1
}

export enum Origin {
	WebGroup = 0,
	Global = 1
}

export enum PatientType {
	Itero = 1,
	IDS = 2,
	Paired = 3
}

export enum ContactType {
	ExtStaff_WCM = 8,
	ExtStaff_Lab = 9,
	ExtStaff_ClinicalOffice = 10,
	Model_Printing = 12,
	IntStaff_ITOPs = 13,
	ExtStaff_OrthoCADDistributor = 14,
	ExtStaff_iTeroDistributor = 15,
	ExtStaff_Invisalign = 16,
	ExtStaff_ProductionCenterResource = 17,
	ExtStaff_MillingSitePersonnel = 18,
	Dentist = 19,
	Orthodontist = 20,
	Dental_Office_Personnel = 21,
	Ortho_Office_Personnel = 22,
	Cadent_Personnel = 23,
	ExtStaff_InvisalignOutcomeSimulatorUser = 24,
	Management_Company_Personnel = 25,
	Operational_Office_Personnel = 26,
	Clinical_Office_Personnl = 27
}

export enum Features {
	patientAppEnabledForMyItero = 1
}

export enum ScannerModel {
	element = 'element',
	evx = 'evx',
	other = 'other'
}
