import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import * as signalR from '@aspnet/signalr';
import { IMessage } from '../../interfaces/IMessage';
import { LoggerService } from '../logging/logger.service';

enum ConnectionState {
	Disconnected,
	Connecting,
	Connected,
	Disconnecting
}

@Injectable({
	providedIn: 'root'
})
export class SignalRService {

	private readonly channelName = 'EupClientMessageRecieved';
	private readonly connectionRestartingDelay = 5000;

	private hubConnection: signalR.HubConnection;
	private messageBroker$: Subject<IMessage> = new Subject<IMessage>();
	private connectionState: ConnectionState = ConnectionState.Disconnected;

	public constructor(private loggerService: LoggerService) {
	}

	public async initMessages(hubUrl: string, accessToken: string): Promise<any> {
		if (this.connectionState !== ConnectionState.Disconnected) {
			return;
		}

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(hubUrl, {
				accessTokenFactory: () => accessToken
			})
			.build();

		this.hubConnection.on(this.channelName, (message: IMessage) => {
			this.messageBroker$.next(message);
		});

		this.hubConnection.onclose(async () => {
			const previousState = this.connectionState;

			this.loggerService.trace(`Connection closed.`, { module: 'signalR.service' });
			this.connectionState = ConnectionState.Disconnected;

			if (previousState !== ConnectionState.Disconnecting) {
				await this.startConnection();
			}
		});

		await this.startConnection();
	}

	private async startConnection(): Promise<any> {
		this.connectionState = ConnectionState.Connecting;
		await this.startHub();
	}

	private async startHub(): Promise<any> {
		if (this.connectionState !== ConnectionState.Connecting) {
			return;
		}

		try {
			await this.hubConnection.start();
		} catch (_) {
			this.loggerService.warn(
				`Unexpected connection fail. Trying to reconnect in ${this.connectionRestartingDelay} ms...`,
				{ module: 'signalR.service' }
			);
			this.startHubDeferred(this.connectionRestartingDelay);

			return;
		}

		this.connectionState = ConnectionState.Connected;
		this.loggerService.debug(`Connection established.`, { module: 'signalR.service' });
	}

	private startHubDeferred(delayInMilliseconds: number) {
		setTimeout(async () => await this.startHub(), delayInMilliseconds);
	}

	public async closeConnection(): Promise<any> {
		if (!this.hubConnection) {
			return;
		}

		const isHubConnected = this.hubConnection.state === signalR.HubConnectionState.Connected;

		if (isHubConnected) {
			this.connectionState = ConnectionState.Disconnecting;
			await this.hubConnection.stop();
		} else {
			this.connectionState = ConnectionState.Disconnected;
		}
	}

	public registerToMessages(): Observable<IMessage> {
		return this.messageBroker$.asObservable();
	}
}

