﻿import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
	private readonly defaultTitle = 'My iTero';

	constructor(private title: Title) {}

	setTitle(newTitle: string): void {
		let newTitleCalculated: string;

		if (newTitle) {
			newTitleCalculated = `${newTitle} · ${this.defaultTitle}`;
		} else {
			newTitleCalculated = this.defaultTitle;
		}

		this.title.setTitle(newTitleCalculated);
	}
}
