﻿import { Injectable } from '@angular/core';

class StoragePolyfill implements Storage {
	[index: number]: string;
	length = 0;
	private data: any;

	constructor() {
		this.data = this.getData();
	}

	clear(): void {
		this.data = {};
		this.clearData();
	}

	getItem(key: string): any {
		return this.data[key] === undefined ? null : this.data[key];
	}

	key(index: number): string {
		let ctr = 0;
		for (let k in this.data) {
			if (this.data.hasOwnProperty(k)) {
				if (ctr === index) {
					return k;
				} else {
					ctr++;
				}
			}
		}
		return null;
	}

	removeItem(key: string): void {
		delete this.data[key];
		this.length--;
		this.setData(this.data);
	}

	setItem(key: string, value: string): void {
		this.data[key] = value + ''; // forces the value to a string
		this.length++;
		this.setData(this.data);
	}

	private createCookie(name: string, value: string, days: number): void {
		let date: Date, expires: string;

		if (days) {
			date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		} else {
			expires = '';
		}
		document.cookie = name + '=' + value + expires + '; path=/';
	}

	private readCookie(name: string): any {
		const nameEq: string = name + '=',
			ca = document.cookie.split(';');
		let i: number, c: string;

		for (i = 0; i < ca.length; i++) {
			c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length);
			}

			if (c.indexOf(nameEq) === 0) {
				return c.substring(nameEq.length, c.length);
			}
		}
		return null;
	}

	private setData(data: any): any {
		data = encodeURIComponent(JSON.stringify(data));
		this.createCookie('localStorage', data, 365);
	}

	private clearData(): void {
		this.createCookie('localStorage', '', 365);
	}

	private getData(): any {
		const data = this.readCookie('localStorage');
		return data ? JSON.parse(decodeURIComponent(data)) : {};
	}
}

@Injectable()
export class LocalStorageService {
	storage: Storage;

	constructor() {
		this.storage = this.hasNativeSupport() ? window.localStorage : this.getPolyfill();
	}

	private hasNativeSupport(): boolean {
		try {
			if (!window.localStorage) {
				return false;
			}
			window.localStorage.setItem('test_storage', '1');
			window.localStorage.removeItem('test_storage');
			return true;
		} catch (e) {
			return false;
		}
	}

	private getPolyfill(): Storage {
		return new StoragePolyfill();
	}
}
