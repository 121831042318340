import { startsWith } from 'lodash';
import { Directive, Input, ViewContainerRef, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { FeaturesToggleSettingsService } from '../service/featuresToggleSettings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
	selector: '[featureToggle]'
})
export class FeaturesToggleDirective implements OnDestroy {
	private onDestroy$: Subject<boolean>;
	private allow: boolean;

	public constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private featuresToggleSettingsService: FeaturesToggleSettingsService
	) {
		this.onDestroy$ = new Subject<boolean>();
		this.allow = false;
	}

	@Input()
	set featureToggle(description: string) {
		this.triggerConditionChange(this.allow);
		this.featuresToggleSettingsService
			.getFeaturesToggleSettings()
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				const isNegative: boolean = startsWith(description, '!');
				const _description: string = isNegative ? description.substr(1) : description;
				const result: boolean = this.featuresToggleSettingsService.isVisibleFeature(_description);
				this.allow = isNegative ? !result : result;
				this.triggerConditionChange(this.allow);
			});
	}

	private triggerConditionChange(condition: boolean) {
		if (condition) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
