import { EventEmitter } from '@angular/core';
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent() {
        this.checkBoxStateEmitter = new EventEmitter();
    }
    Object.defineProperty(CheckboxComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (value) {
            this._checked = value || false;
        },
        enumerable: true,
        configurable: true
    });
    CheckboxComponent.prototype.changed = function (value) {
        this.checkBoxStateEmitter.emit(value);
    };
    return CheckboxComponent;
}());
export { CheckboxComponent };
