import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { ExportPopupService as PopupService } from './popup.service';
import { GlobalSettingsService, GlobalSettings } from '../../core/globalSettings.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { ExportStatus } from '../enums';
import { Utils } from '../utils.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ExportRequestDto } from '../generalInterfaces';

@Component({
	selector: 'eup-popup',
	templateUrl: './popup.component.html',
	styles: [ './popup.component.scss' ]
})
export class PopupComponent implements OnInit, OnDestroy {
	private subscription: Subscription;

	constructor(
		private popupService: PopupService,
		private globalSettingsService: GlobalSettingsService,
		protected utils: Utils,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		const settings = this.globalSettingsService.get();

		const clientId: number = settings.contactId;
		let orderId: number;
		let orderDetailsId: number;
		let parallelWorkOrderId: number;

		this.activatedRoute.queryParams.forEach((routeParams: Params) => {
			const lowerCaseParams = this.utils.lowerCaseProps(routeParams);
			parallelWorkOrderId = +lowerCaseParams['parallelWorkOrderId'.toLocaleLowerCase()];
			orderId = +lowerCaseParams['orderId'.toLocaleLowerCase()];
			orderDetailsId = +lowerCaseParams['orderDetailsId'.toLocaleLowerCase()];
		});

		const requestData: ExportRequestDto = {
			clientId: clientId,
      parallelWorkOrderId: parallelWorkOrderId,
			orderId: orderId,
			orderDetailsId: orderDetailsId
		};

		const intervalDelay: number = 30000; // 30 seconds
		let timeObservable = Observable.timer(0, intervalDelay)
			.switchMap((x: number, y: number): Observable<ExportStatus> => {
				return this.popupService.getExportStatus(requestData);
			})
			.filter((res: ExportStatus) => {
				if (res === ExportStatus.Canceled || res === ExportStatus.Failed) {
					window.close();
				}
				return res === ExportStatus.Completed;
			});

		this.subscription = timeObservable.subscribe(() => {
			this.popupService.downloadStlFile(requestData);
			this.subscription.unsubscribe();
			window.close();
		});
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
	}
}
