﻿import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class NotificationService {
	showNotification: Subject<ModalContext> = new Subject<ModalContext>();
	showTemplateNotification: Subject<ModalTemplateContext> = new Subject<ModalTemplateContext>();
	hideNotification: Subject<DialogResult> = new Subject<DialogResult>();

	show(title: string, content: string, options?: ModalOptions): Observable<DialogResult> {
		this.showNotification.next({
			title,
			content,
			options
		});

		return this.hideNotification.asObservable();
	}

	showTemplate(template: Type<any>, title: string, data: any, options?: ModalOptions): Observable<DialogResult> {
		this.showTemplateNotification.next({
			template,
			title,
			data,
			options
		});

		return this.hideNotification.asObservable();
	}
}

export class ModalOptions {
	buttonCombination: ModalKeys;
	icon: ModalIcon;
}

export class ModalContext {
	title: string;
	content: string;
	options: ModalOptions;
}

export class ModalTemplateContext {
	template: Type<any>;
	title: string;
	data: any;
	options: ModalOptions;
}

export enum ModalKeys {
	Ok = 1,
	OkCancel = 2,
	YesNo = 4,
	YesNoCancel = 8
}

export enum ModalButton {
	Ok = 1,
	Cancel = 2,
	Yes = 3,
	No = 4
}

export enum ModalIcon {
	Info = 1,
	Error = 2,
	Question = 3
}

export class DialogResult {
	buttonPressed: ModalButton;
}

export interface INotificationComponent {
	data: any;
}
