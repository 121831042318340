import { EventEmitter } from '@angular/core';
var InputComponent = /** @class */ (function () {
    function InputComponent() {
        this.textChange = new EventEmitter();
    }
    Object.defineProperty(InputComponent.prototype, "text", {
        get: function () {
            return this.textValue;
        },
        set: function (val) {
            this.textValue = val;
            this.textChange.emit(this.textValue);
        },
        enumerable: true,
        configurable: true
    });
    return InputComponent;
}());
export { InputComponent };
