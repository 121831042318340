import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/generalInterfaces';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppConfigService = /** @class */ (function () {
    function AppConfigService(http) {
        this.http = http;
        this.appConfig = new AppConfig();
    }
    AppConfigService.prototype.loadAppConfig = function () {
        var _this = this;
        return this.http.get('/assets/config/appSettings.json')
            .toPromise()
            .then(function (data) {
            _this.appConfig.appSettings = data;
        });
    };
    Object.defineProperty(AppConfigService.prototype, "appSettings", {
        get: function () {
            if (!this.appConfig.appSettings) {
                throw Error('App Settings not loaded!');
            }
            return this.appConfig.appSettings;
        },
        enumerable: true,
        configurable: true
    });
    AppConfigService.ngInjectableDef = i0.defineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.inject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
