/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-page.component.ngfactory";
import * as i2 from "./error-page.component";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../core/globalSettings.service";
import * as i6 from "./pageNotFound.component";
import * as i7 from "../core/googleAnalytics.service";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "eup-error-page", [["statusCode", "404"]], null, null, null, i1.View_ErrorPage_0, i1.RenderType_ErrorPage)), i0.ɵdid(1, 2146304, null, 0, i2.ErrorPage, [i3.Router, i0.ElementRef, i4.TranslateService, i5.GlobalSettingsService], { statusCode: [0, "statusCode"], errorHeader: [1, "errorHeader"], errorDescription: [2, "errorDescription"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "404"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("Errors.Error_404_Title")), ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform("Errors.Error_404_Message")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i6.PageNotFoundComponent, [i7.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("ng-component", i6.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
