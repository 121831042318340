import { Injectable } from '@angular/core';
import { DesktopNotificationsService } from './desktopNotifications.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { AuthService } from 'app/services/authentication/auth.service';
import { of, Subscription, throwError } from 'rxjs';
import { filter, switchMap, take, catchError, retryWhen, skip } from 'rxjs/operators';
import { SettingsService } from '@shared/settings.service';
import { LoggerService } from '../logging/logger.service';
import { EupRoutesService } from '@core/eupRoutes.service';

@Injectable({
	providedIn: 'root'
})

export class DesktopNotificationsInitializerService {

	private trackLogInSubscription: Subscription;
	private trackLogOutSubscription: Subscription;

	constructor(private desktopNotificationsService: DesktopNotificationsService,
		private globalSettingsService: GlobalSettingsService,
		private authService: AuthService,
		private settingsService: SettingsService,
		private loggerService: LoggerService,
		private eupRoutesService: EupRoutesService) { }

	trackAuthentication(): void {
		this.trackLogIn();
		this.trackLogOut();
	}

	async startNotifications(): Promise<any> {
		const notificationAgentUrl = this.eupRoutesService.notificationAgentUrl;
		const token = this.globalSettingsService.get().jwtAccessToken;
		if (!notificationAgentUrl) {
			const message = 'notificationAgentUrl url is not specified in global settings';
			this.logErrorWithMessage(message);
			throw Error(message);
		}
		await this.desktopNotificationsService.stopNotifications();
		this.desktopNotificationsService.initializeNotifications(notificationAgentUrl, token);
	}

	async stopNotifications(): Promise<any> {
		await this.desktopNotificationsService.stopNotifications();
	}

	private trackLogIn(): void {
		if (this.trackLogInSubscription) {
			return;
		}

		this.trackLogInSubscription = this.authService.authStatus$
			.pipe(
				filter(status => status.isAuthenticated),
				switchMap(_ => {
					const settingsExist = !!this.globalSettingsService.get();
					return settingsExist ? of(undefined) : this.globalSettingsService.initializeCalled$.pipe(take(1));
				}),
				filter(_ => !!this.globalSettingsService.get().jwtAccessToken),
				switchMap(_ => {
					const companyId = this.globalSettingsService.getFirstCompany().id;
					return this.settingsService.getSettings(companyId, true, false);
				}),
				filter(settings => settings.userSettings.allowDesktopNotifications),
				catchError(error => {
					this.logErrorWithMessage('error in notification initializer: ', error);
					return throwError(error);
				}),
				retryWhen(_ => this.authService.authStatus$.pipe(skip(1)))
			).subscribe(
				async _ => this.startNotifications(),
				error => this.logErrorWithMessage('unhandled error in notification initializer: ', error)
			);
	}

	private trackLogOut(): void {
		if (this.trackLogOutSubscription) {
			return;
		}

		this.trackLogOutSubscription = this.authService.authStatus$
			.pipe(
				filter(status => !status.isAuthenticated && !status.isUnauthenticatedByTimeout)
			).subscribe(async _ => {
				await this.stopNotifications();
			});
	}

	private logErrorWithMessage = (message: string, error?: Error) =>
		this.loggerService.error(message, { error, module: 'DesktopNotificationsInitializerService' })
}
