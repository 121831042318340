import { Injectable, OnDestroy } from '@angular/core';
import { IDesktopNotification } from '../../interfaces/IDesktopNotification';
import { BehaviorSubject, from, Observable, Subject, of, interval, iif } from 'rxjs';
import { SignalRService } from '../SignalR/signalR.service';
import { map, tap, takeUntil, single, delayWhen, mergeMap, catchError } from 'rxjs/operators';
import { IMessage } from 'app/interfaces/IMessage';
import { LoggerService } from '../logging/logger.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class DesktopNotificationsService implements OnDestroy {

	isDesktopNotificationPermitted$ = new BehaviorSubject<boolean>(false);
	private isNotificationPermitted: boolean;
	private allNotifications: Notification[] = [];
	private stopNotifications$: Subject<void> = new Subject<void>();

	constructor(private signalRService: SignalRService,
		private loggerService: LoggerService,
		private translateService: TranslateService) {
		this.publishNotificationPermission();
	}

	closeNotification(noti: Notification): void {
		noti.close();
	}

	closeAllNotifications(): void {
		this.allNotifications.forEach((notificationItem: Notification) => this.closeNotification(notificationItem));
	}

	requestUserPermission(): Observable<boolean> {
		return from(this.checkIfPermissionIsPromise())
			.pipe(
				single(),
				tap(permission => console.log('permission requested, permission: ', permission)),
				map(permission => permission === 'granted'),
				tap(isPermitted => {
					this.isNotificationPermitted = isPermitted;
					this.isDesktopNotificationPermitted$.next(isPermitted);
					console.log('permission requested, isNotificationPermitted: ', isPermitted);
				})
			);
	}

	initializeNotifications(notificationHubUrl: string, jwtAccessToken: string): void {

		const notPermittedFlow$ = of({}).pipe(
			tap(_ => {
				this.loggerService.warn(
					'User did not permit notifications. Connection to signalR Service cannot be established',
					{ module: 'DesktopNotificationsService' }
				);
			})
		);

		const permittedFlow$ = of({}).pipe(
			tap(async _ => await this.signalRService.initMessages(notificationHubUrl, jwtAccessToken)),
			mergeMap(_ => this.signalRService.registerToMessages()),
			delayWhen(_ => iif(() => !!window['chrome'], of(undefined), interval(Math.floor((Math.random() * 10) + 1) * 10))),
			takeUntil(this.stopNotifications$),
			map(order => this.prepareDesktopNotification(order)),
			tap(notification => this.showNotification(notification))
		);

		this.requestUserPermission().pipe(
			mergeMap(isPermitted => iif(() => isPermitted, permittedFlow$, notPermittedFlow$)),
			catchError(error => {
				this.loggerService.error('error while processing notification', { module: 'DesktopNotificationsService', error: error });
				return of(error);
			})
		).subscribe();
	}

	async stopNotifications(): Promise<any> {
		this.stopNotifications$.next();
		await this.signalRService.closeConnection();
	}

	async ngOnDestroy() {
		await this.stopNotifications();
		this.stopNotifications$.complete();
	}

	private publishNotificationPermission() {
		this.isNotificationPermitted = Notification['permission'] === 'granted';
		this.isDesktopNotificationPermitted$.next(this.isNotificationPermitted);
	}

	private prepareDesktopNotification(order: IMessage): IDesktopNotification {

		this.loggerService.debug(`TraceId: ${order.traceId}, message received.`, { module: 'DesktopNotificationsService' });

		const translatedTitle = this.translateService.instant('Lab.notificationTitle');
		const translatedBody = (<string>this.translateService.instant('Lab.notificationsBody', { orderId: `${order.orderId}` }));
		return {
			title: translatedTitle,
			body: translatedBody,
			icon: '/assets/images/iteroLogo.png',
			redirectUrl: this.buildNotificationsHandlerUrl(order),
			tag: order.hashCode.toString()
		};
	}

	private buildNotificationsHandlerUrl(orderInfo: IMessage): string {
		// NOTE: HttpParams recieves object only with string fields.
		const orderInfoQueryObject = {
			order: JSON.stringify(orderInfo)
		};

		const queryParams = new HttpParams({
			fromObject: orderInfoQueryObject
		});

		return `/notifications/orders?${queryParams.toString()}`;
	}

	private showNotification(desktopNotification: IDesktopNotification): Notification {
		if (!this.isNotificationPermitted) {
			return;
		}
		const newDesktopNotification = new Notification(desktopNotification.title, {
			body: desktopNotification.body,
			icon: desktopNotification.icon,
			tag: desktopNotification.tag,
			renotify: true
		});

		const notificationClickHandler = (event: Event) => {
			window.open(desktopNotification.redirectUrl);
			newDesktopNotification.removeEventListener('click', notificationClickHandler);
		};

		newDesktopNotification.addEventListener('click', notificationClickHandler);

		this.allNotifications.push(newDesktopNotification);

		return newDesktopNotification;
	}

	private checkIfPermissionIsPromise(): Promise<any> {
		// Safari doesn't support notifications permission as a promise
		const requestNotiPermission = Notification.requestPermission();

		return (requestNotiPermission) ? requestNotiPermission : new Promise(resolve => Notification.requestPermission(perm => resolve(perm)));
	}
}
