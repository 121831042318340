﻿import {
	Injectable,
	Injector,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	ApplicationRef,
	ComponentRef
} from '@angular/core';

@Injectable()
export class DomService {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {}

	appendComponentToBody(component: any): ComponentRef<{}> {
		// 1. Create a component reference from the component
		const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

		// 2. Attach component to the appRef so that it's inside the ng component tree
		this.appRef.attachView(componentRef.hostView);

		// 3. Get DOM element from component
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

		// 4. Append DOM element to the body
		document.body.appendChild(domElem);

		return componentRef;
	}

	removeComponent(componentRef: ComponentRef<{}>) {
		// 1. Remove it from the component tree and from the DOM
		this.appRef.detachView(componentRef.hostView);
		componentRef.destroy();
	}
}
