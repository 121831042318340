import { EventEmitter } from '@angular/core';
var TextareaComponent = /** @class */ (function () {
    function TextareaComponent() {
        this.textChange = new EventEmitter();
    }
    Object.defineProperty(TextareaComponent.prototype, "text", {
        get: function () {
            return this.textareaValue;
        },
        set: function (val) {
            this.textareaValue = val;
            this.textChange.emit(this.textareaValue);
        },
        enumerable: true,
        configurable: true
    });
    return TextareaComponent;
}());
export { TextareaComponent };
