import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService } from '../settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { KeyValuePair } from '../../shared/generalInterfaces';
import { ExportPopupService } from '../../shared/popup/popup.service';
import { ActivatedRoute } from '@angular/router';
import { CreateRestorativeExportFileRequestDto } from '../../shared/generalInterfaces';
import { Subscription, combineLatest } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { ExportInfoDto } from '../popup/popup.service';
import { ExportRequest } from '../orthoCadExport/orthoCadExport.component';
import { DownloadNotificationService } from '../../shared/downloadNotification/downloadNotification.service';
import { DownloadFileInfo } from '../downloadNotification/fileInfo.model';
import {ISettingsData} from '../../interfaces/ISettingsData';
import { ILabCompanySettingsDto } from '../../interfaces/ILabCompanySettingsDto';
import { RoleTypeEnum, SoftwareOptionsForCompany } from '../enums';
import { SoftwareOptionsService } from '@core/softwareOptions.service';

@Component({
	selector: 'eup-restorativeExport',
	templateUrl: './restorativeExport.component.html',
	styleUrls: [ './restorativeExport.component.scss' ]
})
export class RestorativeExportModal {
	@ViewChild('restorativeExportModal') restorativeExportModal: ModalDirective;

	caseTypeId: number;
	orderId: number;
	orderDetailsId: number;
	hidePatientName: boolean;
	disablePatientDetailsBySWOpiont: boolean;

	restorativeExportFileRequest: CreateRestorativeExportFileRequestDto = new CreateRestorativeExportFileRequestDto();
	cadCamSystemTypeOptions: KeyValuePair[] = null;
	selectedCadCamSystemType: KeyValuePair = new KeyValuePair('', '');

  exportSubscription: Subscription;
  exportWoSubscription: Subscription;

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private activatedRoute: ActivatedRoute,
		private popupService: ExportPopupService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private eupRoutesService: EupRoutesService,
		private downloadNotificationService: DownloadNotificationService,
		private softwareOptionsService: SoftwareOptionsService
	) {}

	show(inputCaseTypeId: string, request: ExportRequest): void {
		this.disablePatientDetailsBySWOpiont = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldDisableHidePatientDetails, this.globalSettings.get().companySoftwareOptions);
		this.caseTypeId = +inputCaseTypeId;
		this.orderDetailsId = request.orderDetailsId;
		this.orderId = request.orderId;

		this.restorativeExportModal.show();

		this.settingsService.getSettings(this.globalSettings.get().selectedCompanyId, true, false).subscribe((settingsRes: ISettingsData) => {
			this.hidePatientName = settingsRes.userSettings.patientAnonymization;
			this.cadCamSystemTypeOptions = settingsRes.cadCamSystemTypes.filter((o) => +o.key > 0);
			this.selectedCadCamSystemType = new KeyValuePair('', '');
			if (settingsRes.userSettings.exportCadCamSystemType) {
				this.selectedCadCamSystemType = new KeyValuePair(
					settingsRes.userSettings.exportCadCamSystemType.toString(),
					settingsRes.userSettings.exportCadCamSystemType > 0
						? this.cadCamSystemTypeOptions.filter(
								(o) => o.key.toString() === settingsRes.userSettings.exportCadCamSystemType.toString()
							)[0].value
						: ''
				);
			}
		});

		this.googleAnalyticsService.hitEvent('Order', 'RestorativeExport');
	}

  export(): void {
    if (this.canExport()) {
      this.restorativeExportFileRequest.orderId = this.orderId;
      this.restorativeExportFileRequest.orderDetailsId = this.orderDetailsId;
      this.restorativeExportFileRequest.cadCamSystemTypeId = +this.selectedCadCamSystemType.key;

      let settings = this.globalSettings.get();
      const clientId = settings.contactId;

      let requestData: CreateRestorativeExportFileRequestDto = {
        clientId: clientId,
        orderId: this.restorativeExportFileRequest.orderId,
        orderDetailsId: this.restorativeExportFileRequest.orderDetailsId,
		cadCamSystemTypeId: this.restorativeExportFileRequest.cadCamSystemTypeId,
		patientAnonymization: this.disablePatientDetailsBySWOpiont ? false : this.hidePatientName
      };

			if (settings.roleType == RoleTypeEnum.Lab) {
				// check if download file ready by ideOrder work order
				this.exportWoSubscription = this.popupService
				.getRestorativeExportInfoByWorkOrder(requestData)
				.subscribe((res: ExportInfoDto) => {
					if (res && res.workOrderId) {
						const fileInfo: DownloadFileInfo = {
							parallelWorkOrderId: res.parallelWorkOrderId,
							workOrderId: res.workOrderId,
							orderId: requestData.orderId,
							orderDetailsId: requestData.orderDetailsId,
							downloaded: true
						};
						this.downloadNotificationService.downloadFile(fileInfo);
					} else {
						this.getRestorativeExportInfo(requestData, clientId);
					}
				});
			} else {
				this.getRestorativeExportInfo(requestData, clientId);
			}
      
      this.restorativeExportModal.hide();
    }
  }

  private getRestorativeExportInfo(requestData: CreateRestorativeExportFileRequestDto, clientId: number) {
    this.exportSubscription = this.popupService
      .getRestorativeExportInfo(requestData)
      .subscribe((res: ExportInfoDto) => {
        this.downloadNotificationService.addFile(res.parallelWorkOrderId, requestData);
      });
  }

	setField(fieldName: string, key: string, value: string): void {
		if (fieldName === 'ExportCadCamSystemType') {
			this.selectedCadCamSystemType.key = key;
			this.selectedCadCamSystemType.value = value;
		}
	}

	canExport(): boolean {
		return +this.selectedCadCamSystemType.key > 0;
	}

	onHidePatientNameChanged(newVal: boolean) {
		this.hidePatientName = newVal;
	}
}
