﻿import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
	selector: 'eup-patient-app',
	templateUrl: './patientApp.component.html',
	styleUrls: [ './patientApp.component.scss' ]
})
export class PatientAppModalComponent {
	patientAppUrl: SafeResourceUrl;

	constructor(
		public bsModalRef: BsModalRef
	) {

	}
}
