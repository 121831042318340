import { EupHttpHandler } from './eupHttpHandler.service';
import { LocalStorageService } from './localStorage.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { Consts } from '@shared/consts';
var EupRoutesService = /** @class */ (function () {
    function EupRoutesService(http, localStorageService, appConfigService) {
        var _this = this;
        this.http = http;
        this.localStorageService = localStorageService;
        this.appConfigService = appConfigService;
        this.TermsUrlObs = new BehaviorSubject({});
        this.storage = localStorageService.storage;
        if (this.routeCanBeUsed()) {
            var routingData = JSON.parse(this.storage[Consts.Storage.Routes]);
            this.initialize(routingData);
        }
        this.initHandle = function (force) {
            return Observable.from(_this.getRoutes(force).flatMap(function (res) { return _this.initStorage(res); }));
        };
    }
    EupRoutesService.prototype.getRoutes = function (forceDataRetrieve) {
        var _this = this;
        if (forceDataRetrieve === void 0) { forceDataRetrieve = false; }
        if (this.routeCanBeUsed(forceDataRetrieve)) {
            return new Observable(function (observer) {
                observer.next(JSON.parse(_this.storage[Consts.Storage.Routes]));
            });
        }
        var apiEndpoint = this.storage[Consts.Storage.RegionalApiEndpoint] ? this.storage[Consts.Storage.RegionalApiEndpoint] : this.appConfigService.appSettings.apiEndpoint;
        if (apiEndpoint.endsWith('/')) {
            apiEndpoint = apiEndpoint.slice(0, -1);
        }
        return this.http.get(apiEndpoint + "/" + EupRoutesService.apiPrefix + "settings/GetRoutingData");
    };
    EupRoutesService.prototype.getLocalizationSettings = function () {
        return this.http.get(this.appConfigService.appSettings.apiEndpoint + "/" + EupRoutesService.apiPrefix + "settings/getLocalizationSettings");
    };
    EupRoutesService.prototype.getFeaturesToggleSettings = function () {
        return this.http.get(this.appConfigService.appSettings.apiEndpoint + "/" + EupRoutesService.apiPrefix + "featuresToggleSettings/getFeaturesToggleSettings");
    };
    EupRoutesService.prototype.initStorage = function (res) {
        var _this = this;
        if (this.routeCanBeUsed()) {
            return new Observable(function (observer) {
                _this.initialize(res);
                observer.next({});
            });
        }
        return new Observable(function (observer) {
            _this.storage[Consts.Storage.Routes] = JSON.stringify(res);
            _this.storage[Consts.Storage.RegionalApiEndpoint] = _this.storage[Consts.Storage.RegionalApiEndpoint] ? _this.storage[Consts.Storage.RegionalApiEndpoint] : res.serverUrl;
            _this.initialize(res);
            observer.next({});
        });
    };
    EupRoutesService.prototype.getLoginData = function () {
        if (!this.login) {
            throw 'routing data not initialised!';
        }
        return this.login.loginData;
    };
    EupRoutesService.prototype.doAfterInit = function (observable, force) {
        if (force === void 0) { force = false; }
        return this.initHandle(force).flatMap(observable);
    };
    EupRoutesService.prototype.initialize = function (routingData) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        if (!!this.userSettings || !routingData) {
            return; // no data or init was already scheduled before
        }
        this.notificationAgentUrl = routingData.notificationAgentUrl;
        EupRoutesService.serverUrl = this.serverUrl = routingData.serverUrl;
        EupRoutesService.MATserverUrl = this.MATserverUrl = routingData.MATserverUrl;
        EupRoutesService.mvcUrl = this.mvcUrl = this.serverUrl + EupRoutesService.apiPrefix;
        EupRoutesService.rxUIUrl = this.rxUIUrl = routingData.rxUIUrl;
        this.AlignUrl = routingData.alignUrl;
        this.PrivacyNCookiesUrl = routingData.privacyAndCookiesUrl;
        this.TermsUrl = routingData.termsUrl;
        this.TermsUrlObs.next(this.TermsUrl);
        this.drawingToolUrl = routingData.drawingToolUrl;
        this.iTeroSiteUrl = routingData.iTeroSiteUrl;
        this.downloads = (_a = /** @class */ (function () {
                function DownloadRoute() {
                }
                return DownloadRoute;
            }()),
            _a.orthoCadSetupDownloadUrl = routingData.orthoCadSetupDownloadUrl,
            _a.orthoCadSetupDownloadUrl_acs = routingData.orthoCadSetupDownloadUrl_acs,
            _a.iTeroSetupDownloadUrl = routingData.iTeroSetupDownloadUrl,
            _a.iTeroSetupDownloadUrl_acs = routingData.iTeroSetupDownloadUrl_acs,
            _a.iOSimDownloadUrl = routingData.iOSimDownloadUrl,
            _a.iOSimDownloadUrl_acs = routingData.iOSimDownloadUrl_acs,
            _a.compatibilityTester = routingData.compatibilityTester,
            _a.RTHFirstTimeInstaller = routingData.RTHFirstTimeInstaller,
            _a.EvxFirstTimeInstaller = routingData.EvxFirstTimeInstaller,
            _a);
        this.home = (_b = /** @class */ (function () {
                function HomeRoute() {
                }
                return HomeRoute;
            }()),
            _b.url = EupRoutesService.mvcUrl + 'home/',
            _b.logout = _b.url + 'logout',
            _b);
        this.login = (_c = /** @class */ (function () {
                function LoginRoute() {
                }
                return LoginRoute;
            }()),
            _c.url = EupRoutesService.mvcUrl + 'login/',
            _c.loginData = _c.url + 'getLoginInfo',
            _c.browserLanguageCode = _c.url + 'browserLanguageCode',
            _c);
        this.thirdPartyLogin = (_d = /** @class */ (function () {
                function ThirdPartyLoginRoute() {
                }
                return ThirdPartyLoginRoute;
            }()),
            _d.url = EupRoutesService.mvcUrl + 'thirdPartyLogin/',
            _d.authorize = _d.url + 'authorize/',
            _d.setCompany = _d.url + 'setCompany/',
            _d);
        this.orders = (_e = /** @class */ (function () {
                function OrderRoute() {
                }
                return OrderRoute;
            }()),
            _e.url = EupRoutesService.mvcUrl + 'orders/',
            _e.filesUrl = EupRoutesService.mvcUrl + 'orderfiles/',
            _e.undeliveredUrl = _e.url + 'undelivered',
            _e.deliveredUrl = _e.url + 'delivered',
            _e.deleteRxUrl = _e.url + 'deleteRx',
            _e.isFailedOrderExistsUrl = _e.url + 'isFailedOrderExists',
            _e.changeCaseTypeOptionsUrl = _e.url + 'GetChangeCaseTypeOptions',
            _e.changeCaseTypeForOrderUrl = _e.url + 'ChangeCaseTypeForOrder',
            _e.getOrthodonticsExportInfo = _e.url + 'getOrthodonticsExportInfo',
            _e.getRestorativeExportInfo = _e.url + 'getRestorativeExportInfo',
            _e.getOrthodonticsExportInfoByWorkOrder = _e.url + 'getOrthodonticsExportInfoByWorkOrder',
            _e.getRestorativeExportInfoByWorkOrder = _e.url + 'getRestorativeExportInfoByWorkOrder',
            _e.getStlFile = _e.filesUrl + 'getSTLFile',
            _e.getStlFileByWorkOrder = _e.filesUrl + 'GetStlFileByWorkOrder',
            _e.getExportStatus = _e.url + 'getExportStatus',
            _e.getExportStatusBulk = _e.url + 'GetExportStatusBulk',
            _e.getExportInfoBulk = _e.url + 'getExportInfoBulk',
            _e.getExportInfoBulkByWorkOrder = _e.url + 'GetExportInfoBulkByWorkOrder',
            _e.getScreenshotDownloadLink = _e.filesUrl + 'GetEvxFile',
            _e.isEvxFileExists = _e.filesUrl + 'IsEvxFileExists',
            _e.getFileStorage = _e.filesUrl + 'GetFileStorage',
            _e.updatePrintedOrdersUrl = _e.url + 'updatePrintedOrders',
            _e.uploadCapture = _e.filesUrl + 'UploadCaptureFile',
            _e.setReadyForDownloadAsDownloadedForLab = _e.url + 'setReadyForDownloadAsDownloadedForLab',
            _e);
        this.lab = (_f = /** @class */ (function () {
                function LabRoute() {
                }
                return LabRoute;
            }()),
            _f.url = EupRoutesService.mvcUrl + 'lab/',
            _f.getOrdersUrl = _f.url + 'getOrders',
            _f.getOrdersTotalUrl = _f.url + 'getOrdersTotal',
            _f.getTotalOrdersAmountByState = _f.url + 'getTotalOrdersAmountByState',
            _f.logout = _f.url + 'logout',
            _f.proceedToMilling = _f.url + 'proceedToMilling',
            _f.proceedToModeling = _f.url + 'proceedToModeling',
            _f.backToScanning = _f.url + 'backToScanning',
            _f.getRescanInfo = _f.url + 'GetRescanInfo',
            _f.getOrderByIdUrl = _f.url + 'getOrderById',
            _f.buildITeroProtocolLink = _f.url + 'BuildITeroProtocolLink',
            _f.processSendToLab = _f.url + 'ProcessSendToLab',
            _f);
        this.rx = (_g = /** @class */ (function () {
                function RxRoute() {
                }
                return RxRoute;
            }()),
            _g.endpointForRx = EupRoutesService.mvcUrl + 'rx',
            _g.endpointForGlidewellRx = EupRoutesService.mvcUrl + 'ThirdPartyRx',
            _g.rxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/RxEditor.html',
            _g.webRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/WebRx.html',
            _g.printRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/printrx.html',
            _g.onlineOrthoViewerUrl = routingData.onlineOrthoViewerUrl,
            _g.onlineRestorativeViewerUrl = routingData.onlineRestorativeViewerUrl,
            _g.getAvailableCaseTypesForRxUrl = _g.endpointForRx + '/GetAvailableCaseTypes',
            _g.configurationUrl = _g.endpointForRx + '/Configuration',
            _g.saveUrl = _g.endpointForRx + '/Save',
            _g.getUserSettingsUrl = _g.endpointForRx + '/GetUserSettings',
            _g.rxContactConfigUrl = _g.endpointForRx + '/RxContactConfig',
            _g.getRxByIdUrl = _g.endpointForRx + '/GetRxById',
            _g.getRxByOrderIdUrl = _g.endpointForRx + '/GetRxByOrderId',
            _g.getPatientByUIDUrl = _g.endpointForRx + '/GetPatientByUID',
            _g.checkPatientExistByUrl = _g.endpointForRx + '/CheckPatientExistBy',
            _g);
        this.rxForm = (_h = /** @class */ (function () {
                function RxFormRoute() {
                }
                return RxFormRoute;
            }()),
            _h.endpointForRxForm = EupRoutesService.mvcUrl + 'rxForm',
            _h.saveOrderRxFormUrl = _h.endpointForRxForm + '/SaveOrderRxForm',
            _h);
        this.featuresToggleSettings = (_j = /** @class */ (function () {
                function FeaturesToggleSettingsRoute() {
                }
                return FeaturesToggleSettingsRoute;
            }()),
            _j.url = EupRoutesService.mvcUrl + 'featuresToggleSettings/',
            _j.getFeaturesToggleSettings = _j.url + 'getFeaturesToggleSettings/',
            _j.updateFeaturesToggleSettings = _j.url + 'updateFeaturesToggleSettings/',
            _j);
        this.messages = (_k = /** @class */ (function () {
                function MessageRoute() {
                }
                return MessageRoute;
            }()),
            _k.url = EupRoutesService.mvcUrl + 'messages/',
            _k.newMessagesCountUrl = _k.url + 'GetNewMessagesCount',
            _k.getMessagesListUrl = _k.url + 'GetMessagesList',
            _k.markAsReadUrl = _k.url + 'MarkAsRead',
            _k.markAsUnreadUrl = _k.url + 'MarkAsUnread',
            _k);
        this.patients = (_l = /** @class */ (function () {
                function PatientRoute() {
                }
                return PatientRoute;
            }()),
            _l.url = EupRoutesService.mvcUrl + 'patients/',
            _l.searchUrl = _l.url + 'search',
            _l.getPatientsUrl = function (patientTypes, forceCacheUpdate) {
                if (forceCacheUpdate === void 0) { forceCacheUpdate = false; }
                if (!patientTypes || !Array.isArray(patientTypes))
                    return '';
                var patientTypeQuery = patientTypes.reduce(function (query, type) {
                    return query + "patientTypes=" + type + "&";
                }, '');
                return _l.url + "?" + patientTypeQuery + "forceCacheUpdate=" + forceCacheUpdate;
            },
            _l);
        this.patientOrders = (_m = /** @class */ (function () {
                function PatientOrdersRoute() {
                }
                return PatientOrdersRoute;
            }()),
            _m.url = EupRoutesService.mvcUrl + 'patientsOrders/',
            _m.ordersUrl = _m.url + 'orders',
            _m.checkPatientDoesntExistAndUpdateUrl = _m.url + 'checkPatientDoesntExistAndUpdate',
            _m);
        this.logonAs = (_o = /** @class */ (function () {
                function LogonAsRoute() {
                }
                return LogonAsRoute;
            }()),
            _o.url = EupRoutesService.mvcUrl + 'logonAs/',
            _o.getContacts = _o.url + 'getContacts',
            _o.getDoctorsByCompanyId = _o.url + 'getDoctorsByCompanyId',
            _o);
        this.userSettings = (_p = /** @class */ (function () {
                function UserSettingsRoute() {
                }
                return UserSettingsRoute;
            }()),
            _p.url = EupRoutesService.mvcUrl + 'settings/',
            _p.getUrl = _p.url + 'getUserSettings',
            _p.setUrl = _p.url + 'setUserSettings',
            _p.localizationSettingsByRoleTypeUrl = _p.url + 'getLocalizationSettingsByRoleType',
            _p.setCredentials = _p.url + 'setSecurityInfo',
            _p.getVisibleUserAuthenticationDetails = _p.url +
                'GetVisibleUserAuthenticationDetails?userName=',
            _p.getAllSecurityQuestions = _p.url + 'getAllSecurityQuestions',
            _p.matPasswordPolicyPageUrl = EupRoutesService.MATserverUrl +
                'UnSecured/PasswordPolicyTouch.aspx?mode=EUP&Language={0}',
            _p.getCompanyRegionUrl = _p.url + 'getCompanyRegion',
            _p.getExportGeneralSettingsUrl = _p.url + 'getExportGeneralSettings',
            _p.setCompanySettingsUrl = _p.url + 'setLabCompanySettings',
            _p.getCompanySettingsUrl = _p.url + 'getLabCompanySettings',
            _p.areScannerSoftwareOptionsAvailableUrl = _p.url + 'areScannerSoftwareOptionsAvailable',
            _p.getUserIdsPairedStatusUrl = _p.url + 'isLoggedInDoctorIdsPaired',
            _p.getCompanySoftwareOptionIds = _p.url + 'getCompanySoftwareOptionIds?companyId=',
            _p.setDoctor = function (companyId, doctorId) { return _p.url + "setDoctor?companyId=" + companyId + "&doctorId=" + doctorId; },
            _p);
        this.activityReport = (_q = /** @class */ (function () {
                function ActivityReportRoute() {
                }
                return ActivityReportRoute;
            }()),
            _q.url = EupRoutesService.mvcUrl + 'activityReport/',
            _q.getUrl = _q.url + 'getActivities',
            _q.getAllActivities = _q.url + 'getAllActivities',
            _q);
        this.shared = (_r = /** @class */ (function () {
                function UserSettingsRoute() {
                }
                return UserSettingsRoute;
            }()),
            _r.url = EupRoutesService.mvcUrl + 'shared/',
            _r.getLanguages = _r.url + 'getLanguages',
            _r);
        this.viewer = (_s = /** @class */ (function () {
                function ViewerRoute() {
                }
                return ViewerRoute;
            }()),
            _s.url = EupRoutesService.mvcUrl + 'viewer/',
            _s.logViewerLoadingTime = _s.url + 'logViewerLoadingTime?milliseconds=',
            _s.canShowExport = _s.url + 'canShowExport',
            _s);
        this.orderInformation = (_t = /** @class */ (function () {
                function ViewerRoute() {
                }
                return ViewerRoute;
            }()),
            _t.url = EupRoutesService.mvcUrl + 'orderInformationApi/',
            _t.configuration = _t.url + 'OrderInformationConfiguration?companyId=',
            _t);
        this.orderConfiguration = (_u = /** @class */ (function () {
                function ViewerRoute() {
                }
                return ViewerRoute;
            }()),
            _u.getUrl = function (companyId) {
                return EupRoutesService.serverUrl + "rx/Configuration/" + companyId + "?isLabOrTechnician=false";
            },
            _u);
        this.accountManagement = (_v = /** @class */ (function () {
                function AccountManagementRoutes() {
                }
                return AccountManagementRoutes;
            }()),
            _v.getPracticeUsersUrl = function (companyId) { return EupRoutesService.serverUrl + "AccountManagement/companies/" + companyId + "/contacts"; },
            _v.updatePracticeUserUrl = function (companyId) { return EupRoutesService.serverUrl + "AccountManagement/companies/" + companyId + "/contacts"; },
            _v.setPrimaryContactUrl = function () { return EupRoutesService.serverUrl + "AccountManagement/SetPrimaryContact"; },
            _v.deleteContactUrl = function (companyId, contactId) { return EupRoutesService.serverUrl + "AccountManagement/companies/" + companyId + "/contacts/" + contactId; },
            _v.getCompanyInfoUrl = function (companyId) { return EupRoutesService.mvcUrl + "accountManagement/GetCompanyInfo?companyId=" + companyId; },
            _v);
    };
    EupRoutesService.prototype.routeCanBeUsed = function (forceDataRetrieve) {
        if (forceDataRetrieve === void 0) { forceDataRetrieve = false; }
        var routes = this.storage[Consts.Storage.Routes];
        var currentRegion = this.storage[Consts.Storage.RegionalApiEndpoint];
        var routesObj = routes ? JSON.parse(routes) : undefined;
        var regionChanged = routesObj && currentRegion && routesObj.serverUrl && currentRegion != routesObj.serverUrl;
        var res = !!routes && this.isValidJson(routes) && !forceDataRetrieve && !regionChanged;
        return res;
    };
    EupRoutesService.prototype.isValidJson = function (jsonText) {
        try {
            JSON.parse(jsonText);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    EupRoutesService.apiPrefix = 'mvc/';
    return EupRoutesService;
}());
export { EupRoutesService };
var RoutingData = /** @class */ (function () {
    function RoutingData() {
    }
    return RoutingData;
}());
export { RoutingData };
