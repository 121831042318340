﻿import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../core/googleAnalytics.service';

@Component({
	templateUrl: './pageNotFound.component.html'
})
export class PageNotFoundComponent implements OnInit {
	constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

	ngOnInit(): void {
		this.googleAnalyticsService.hitException('Navigation Error', true);
	}
}
