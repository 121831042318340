﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { GoogleAnalyticsService } from './googleAnalytics.service';

export enum StatusCode {
	Ok = 200,
	ContentCreated = 201,
	NoContent = 204,
	Redirect = 302,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	PageNotFount = 404,
	InternalServerError = 500
}

@Injectable()
export class EupHttpHandler {
	constructor(
		private httpService: HttpClient,
		private router: Router,
		private spinnerService: SpinnerService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {
	}

	post(
		url: string,
		body?: any,
		options?: any,
		navigateOnFailure: boolean = true,
		triggerSpinner: boolean = true
	): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService
			.post(url, body, { ...options, withCredentials: true })
			.do((res) => this.handleResponse(res, triggerSpinner, startingTime, url))
			.catch((e) => this.handleError.call(this, e, navigateOnFailure));
	}

	get(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService
			.get(url, { ...options, withCredentials: true })
			.do((res) => this.handleResponse(res, triggerSpinner, startingTime, url))
			.catch((e) => this.handleError.call(this, e, navigateOnFailure));
	}

	delete(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService
			.delete(url, { ...options, withCredentials: true })
			.do((res) => this.handleResponse(res, triggerSpinner, startingTime, url))
			.catch((e) => this.handleError.call(this, e, navigateOnFailure));
	}

	patch(
		url: string,
		body?: any,
		options?: any,
		navigateOnFailure: boolean = true,
		triggerSpinner: boolean = true
	): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService
			.patch(url, body, { ...options, withCredentials: true })
			.do((res) => this.handleResponse(res, triggerSpinner, startingTime, url))
			.catch((e) => this.handleError.call(this, e, navigateOnFailure));
	}

	put(
		url: string,
		body?: any,
		options?: any
	): Observable<any> {
		const startingTime = new Date().getTime();

		return this.httpService
			.put(url, body, { ...options, withCredentials: true })
			.do((res) => this.handleResponse(res, false, startingTime, url))
			.catch((e) => this.handleError.call(this, e, false));
	}

	private handleResponse(res: any, triggerSpinner: boolean, startingTime: number, url: string) {
		if (triggerSpinner) {
			this.spinnerService.stop();
		}

		// calculate timing
		if (startingTime && url) {
			const timeElapsed = new Date().getTime() - startingTime;
			this.googleAnalyticsService.hitActionTiming(this.router.url, url, timeElapsed);
		}

		if (res && res.headers) {
			const redirectToUrl = res.headers.get('RedirectRoute');
			let externalUrl = res.headers.get('ExternalRedirect');
			if (redirectToUrl) {
				this.router.navigate([redirectToUrl.toLowerCase()]);
			}
			if (externalUrl) {
				window.location.href = externalUrl;
			}
		}
	}

	handleError(err: any, navigateOnFailure: boolean): Observable<any> {
		this.spinnerService.stop();
		const status = err.status;

		this.googleAnalyticsService.hitException(err.statusText + ' @ ' + err.url, true);

		if (navigateOnFailure) {
			switch (status) {
				case StatusCode.BadRequest:
					this.router.navigate(['/badrequest']);
					break;
				case StatusCode.Unauthorized:
					// if we are coming from login page do nothing
					if (this.router.url.indexOf('login') === -1) {
						const currentUrl = this.router.url;
						const params = this.router.parseUrl(currentUrl).queryParams;
						const returnUrlParams =
							params['returnUrl'] !== undefined
								? { queryParams: params }
								: { queryParams: { returnUrl: currentUrl } };
						// this.globalSettingsService.clear();

						this.router.navigate(['/login'], returnUrlParams);
					}
					break;
				case StatusCode.Forbidden:
					this.router.navigate(['/forbidden']);
					break;
				case StatusCode.PageNotFount:
					this.router.navigate(['/pagenotfound']);
					break;
				case StatusCode.Redirect:
					this.router.navigate([err.headers.get('RedirectRoute').toLowerCase()]);
					break;
				case StatusCode.InternalServerError:
				default:
					// In future these errors will be handled by the errorHandler service and we'll remove this line
					this.router.navigate(['/internalservererror']);
					break;
			}
		}
		return Observable.throw(err);
	}
}
