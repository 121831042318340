import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializerService extends DefaultUrlSerializer {

	// NOTE: notifications handling and rx-viewer modules requires case-sensitive urls in order of data deserialization.
	readonly exceptionUrls = [
		'/notifications/',
		'/rx/viewer/',
		'/login'
	];

	parse(url: string): UrlTree {
		if (this.exceptionUrls.some(exceptionUrl => url.toLocaleLowerCase().startsWith(exceptionUrl))) {
			return super.parse(url);
		} else {
			return super.parse(url.toLowerCase());
		}
	}
}
