﻿import { Injectable } from '@angular/core';
import { EupRoutesService } from '../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class MessagesService {
	private headers = new HttpHeaders({
		'Content-Type': 'application/json'
	});

	constructor(private http: EupHttpHandler, private eupRoutesService: EupRoutesService) {}

	getAllMessages(filterText: string = ''): Observable<Message[]> {
		return this.http.get(this.eupRoutesService.messages.getMessagesListUrl).map((res: Message[]) => {
			const arr = res;
			arr.forEach((msg) => {
				msg.publishDate = new Date(msg.publishDate);
				msg.dateCreated = new Date(msg.dateCreated);
				msg.expirationDate = new Date(msg.expirationDate);
			});
			return arr;
		});
	}

	getMessagesCount(): Observable<number> {
		return this.http.get(this.eupRoutesService.messages.newMessagesCountUrl, undefined, true, false);
	}

	setMessageViewState(messageId: number, isViewed: boolean): Observable<any> {
		const url = isViewed
			? this.eupRoutesService.messages.markAsReadUrl
			: this.eupRoutesService.messages.markAsUnreadUrl;
		return this.http.post(url, { messageId }, { headers: this.headers }, true, false);
	}
}

export class Message {
	id: number;
	title: string;
	content: string;
	plainText: string;
	shortContent: string;
	messageType: number;
	publishDate: Date;
	dateCreated: Date;
	expirationDate: Date;
	isViewed: boolean;
}
