import { Injectable, OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { IRemoteConfig } from '@itero/timber/dist/interfaces/config.remote';
import { ILogParameters } from '@itero/timber/dist/interfaces/logParameters'
import { tap, takeUntil, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { Subject } from 'rxjs';
import { loggerConfigRemote } from './config/logger.config';
import { AppConfigService } from '../appConfig/appConfigService';
import { LogParameters } from './interfaces/log.parameters';
import { AuthService } from '../authentication/auth.service';

@Injectable({ providedIn: 'root' })
export class LoggerService implements OnDestroy {

	private timber: Timber;
	private onDestroy$ = new Subject<boolean>();

	constructor(private appConfigService: AppConfigService,
				private globalSettingsService: GlobalSettingsService,
				private authService: AuthService) {
		this.createLogger();
		this.trackUserChanges();
		this.trackCompanyChanges();
		this.trackLogOut();
	}

	trace(message: string, parameters: LogParameters): void { this.timber.trace(message, parameters as ILogParameters); }

	debug(message: string, parameters: LogParameters): void { this.timber.debug(message, parameters as ILogParameters); }

	info(message: string, parameters: LogParameters): void { this.timber.info(message, parameters  as ILogParameters); }

	warn(message: string, parameters: LogParameters): void { this.timber.warn(message, parameters  as ILogParameters); }

	error(message: string, parameters: LogParameters): void { this.timber.error(message, parameters as ILogParameters); }

	fatal(message: string, parameters: LogParameters): void { this.timber.fatal(message, parameters as ILogParameters); }

	ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	private createLogger(): void {
		const globalSettings = this.globalSettingsService.get();

		const config: IRemoteConfig = {
			...loggerConfigRemote as IRemoteConfig,
			url: this.appConfigService.appSettings.loggingEndpoint,
			userId: globalSettings ? globalSettings.contactId : undefined,
			companyId: globalSettings ? globalSettings.selectedCompanyId : undefined
		};
		this.timber = new Timber(config);
		console.log('Logger created with config:', config);
	}

	private trackUserChanges(): void {
		this.globalSettingsService.contactIdChanged$
			.pipe(
				takeUntil(this.onDestroy$),
				tap(contactId => this.timber.setConfig({ userId: contactId })),
				tap(contactId => this.info(`User has changed, contactId: ${contactId}`, { module: 'LoggerService' }))
			).subscribe();
	}

	private trackCompanyChanges(): void {
		this.globalSettingsService.contextChanged
			.pipe(
				takeUntil(this.onDestroy$),
				map(context => context.companyId),
				filter(companyId => !!companyId),
				distinctUntilChanged(),
				tap(companyId => this.timber.setConfig({ companyId: companyId })),
				tap(companyId => this.info(`Company has changed, companyId: ${companyId}`, { module: 'LoggerService' }))
			).subscribe();
	}

	private trackLogOut(): void {
		this.authService.authStatus$
			.pipe(
				takeUntil(this.onDestroy$),
				filter(v => !v.isAuthenticated),
				tap(() => this.timber.setConfig({ userId: undefined })),
				tap(() => this.timber.setConfig({ companyId: undefined })),
				tap(() => this.info(`User logged out`, { module: 'LoggerService' }))
			).subscribe();
	}
}
