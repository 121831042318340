import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Order, OrdersService } from '../../doctors/orders/orders.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { StorageType, BillOfWorkType } from '../enums';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { IteroLabService } from '../../shared/iteroLab.service';

@Component({
	selector: 'windows-app-button',
	templateUrl: './windowsAppButton.component.html',
	styleUrls: ['./windowsAppButton.component.scss'],
	providers: [IteroLabService]
})

export class WindowsAppButtonComponent implements OnInit {

	// Mandatory
	@Input() public application: string;
	@Input() public order: Order;

	// Non-mandatory
	@Input() public title: string;
	@Input() public user: string = "doctor";
	@Input() public windowsOnly: boolean = true;
	@Input() public modalAllowed = true;

	@ViewChild(DownloadLinkModal) downloadLinkModal: DownloadLinkModal;


	@Output() openModal: EventEmitter<any> = new EventEmitter();

	public orthoCadSetupDownloadUrl: string;
	public iTeroSetupDownloadUrl: string;
	public iOSimDownloadUrl: string;
	public orthoCadSetupDownloadUrl_acs: string;
	public iTeroSetupDownloadUrl_acs: string;
	public iOSimDownloadUrl_acs: string;


	constructor(
		private eupRoutesService: EupRoutesService,
		private orderService: OrdersService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private translateService: TranslateService,
		public globalSettingsService: GlobalSettingsService,
		private iteroLabService: IteroLabService) {
	}

	ngOnInit(): void {
		this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
		this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
		this.iOSimDownloadUrl = this.eupRoutesService.downloads.iOSimDownloadUrl;
		this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
		this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;
		this.iOSimDownloadUrl_acs = this.eupRoutesService.downloads.iOSimDownloadUrl_acs;

		if (!this.title) {
			this.title = this.translateService.instant(this.nameMap[this.application]);
		}
	}

	public get available(): boolean {
		return this.visibilityMap[this.user][this.application];
	}

	public onClick(): void {
		this.orderService.getFileStorage(this.order.id).subscribe((storage: StorageType) => {

			let appProtocolLink = this.linkMap[this.application];
			const downloadLink = this.installationLinksMap[this.application][storage];
			const name = this.nameMap[this.application];
			this.googleAnalyticsService.hitEvent('Order', name);
			const title = this.translateService.instant(name);

		

			if (this.iteroLabService.isNeedToSendToLab(this.order)) {

				this.iteroLabService.prepareOpenApp(this.order.id, this.order.lastDetailsId).subscribe((url) => {

					this.openApp(title, downloadLink, url);

					this.orderService.updateLabOrderById(
						this.order.id.toString(),
						this.globalSettingsService.get().selectedDoctorId.toString(),
						this.globalSettingsService.get().selectedCompanyId.toString(),
						((order: Order) => {
							// optimistic UI issue
							order.canReturnToDoctor = false;
						}));
				});

			}
			else {
				this.openApp(title, downloadLink, appProtocolLink);
			}
		});
	}


	private openApp(title: string, downloadLink: string, appProtocolLink: string, ): void {
		this.orderService.startProtocol(appProtocolLink, true);
		if (this.modalAllowed) {
			this.downloadLinkModal.show(title, downloadLink);
		} else {
			this.openModal.emit({ title, downloadLink });
		}
	}


	public get customWidth() {
		return this.widthMap[this.application];
	}

	private get visibilityMap() {
		return {
			'doctor': {
				'iosim': this.order.outcomeSimulatorEnabled,
				'iosim_pa': this.order.progressAssessmentEnabled,
				'iosim_ca': this.order.iGoCaseAssessmentEnabled,
				'iosim_go': this.order.iGoOutcomeSimulatorEnabled,
				'itero': this.order.canOpenFile && !this.order.isOrthoOrder,
				'orthocad': this.order.canOpenFile && this.order.isOrthoOrder,
				'export': this.order.canExportFile && !this.order.isOrthoOrder
			},
			'lab': {
				'orthocad': this.order.canOpenWithOrthoCad,
				'itero': this.order.canOpenWithItero
			}
		}
	}
	private get linkMap() {
		return {
			'iosim': this.order.outcomeSimulatorLink,
			'iosim_pa': this.order.progressAssessmentLink,
			'iosim_ca': this.order.iGoCaseAssessmentLink,
			'iosim_go': this.order.iGoOutcomeSimulatorLink,
			'itero': this.order.openFileLink,
			'orthocad': this.order.openFileLink,
			'export': this.order.exportFileLink
		}
	}

	private get nameMap() {
		return {
			'iosim': 'Orders.InvisalignOutcomeSimulator',
			'iosim_pa': 'Orders.InvisalignProgressAssessment',
			'iosim_ca': 'Orders.InvisalignGoCaseAssessment',
			'iosim_go': 'Orders.InvisalignGoOutcomeSimulator',
			'itero': 'Orders.OpenWithiTero',
			'orthocad': 'Orders.OpenWithOrthoCAD',
			'export': 'Orders.ExportWithiTero'
		}
	}

	private get installationLinksMap() {
		const iosimLinks = {
			[StorageType.ACS]: this.iOSimDownloadUrl_acs,
			[StorageType.OriginalFMS]: this.iOSimDownloadUrl,
		};

		const iTeroLinks = {
			[StorageType.ACS]: this.iTeroSetupDownloadUrl_acs,
			[StorageType.OriginalFMS]: this.iTeroSetupDownloadUrl,
		};

		return {
			'iosim': iosimLinks,
			'iosim_pa': iosimLinks,
			'iosim_ca': iosimLinks,
			'iosim_go': iosimLinks,
			'itero': iTeroLinks,
			'orthocad': {
				[StorageType.ACS]: this.orthoCadSetupDownloadUrl_acs,
				[StorageType.OriginalFMS]: this.orthoCadSetupDownloadUrl,
			},
			'export': iTeroLinks
		};
	}

	private get widthMap() {
		return {
			'iosim': 'extended',
			'iosim_pa': 'extended',
			'iosim_ca': 'extended',
			'iosim_go': 'extended',
			'itero': undefined,
			'orthocad': undefined,
			'export': undefined
		};
	}
}