import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { FeatureToggleSettings } from '../featuresToggleSettings.model';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';

@Injectable()
export class FeaturesToggleSettingsService {
  public featuresToggleSettings: FeatureToggleSettings[];

  constructor(private http: EupHttpHandler,
              private eupRoutesService: EupRoutesService) {
  }

	getFeaturesToggleSettings(isUpdate = false): Observable<FeatureToggleSettings[]> {
    if (!isUpdate && this.featuresToggleSettings) {
      return Observable.of(this.featuresToggleSettings);
    }
    return this.eupRoutesService.getFeaturesToggleSettings().pipe(
              map((featuresToggleSettings: FeatureToggleSettings[]) => {
                this.featuresToggleSettings = featuresToggleSettings;
                return featuresToggleSettings;
              })
            );
  }

  updateFeaturesToggleSettings(featuresToggleSettings: FeatureToggleSettings[]): Observable<FeatureToggleSettings> {
    return this.http.post(this.eupRoutesService.featuresToggleSettings.updateFeaturesToggleSettings, featuresToggleSettings).pipe(
      map((featureToggleSettings: FeatureToggleSettings) => {
        this.getFeaturesToggleSettings(true).subscribe();
        return featureToggleSettings;
      })
    );
  }

  isVisibleFeature(featureId: string): boolean {
    if (!this.featuresToggleSettings){
      throw new Error("State for `featuresToggleSettings` has not been set. Please call this method on featuresToggleSettingsService.getFeaturesToggleSettings subscription")
    }
    
    const feature = this.featuresToggleSettings.filter((feature: FeatureToggleSettings) => {
      return feature.id === featureId;
    })[0];

    const defaultValue = true;
    return feature ? feature.isActive : defaultValue;
  }
}
