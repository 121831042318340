import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../core/eupRoutes.service';
import { EvxSoftwareOptions, SnapshotSoftwareOptions } from '../shared/enums';


@Injectable({
	providedIn: 'root'
})
export class SoftwareOptionsService {
	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
	) { }

	isSoftwareOptionEnabled$(softwareOptionParams): Observable<Array<Object>> {
		return this.http
			.get(this.eupRoutesService.userSettings.areScannerSoftwareOptionsAvailableUrl,
				{ params: softwareOptionParams, withCredentials: true },
				false,
				false);
	}

	hasEvx(companyId): Observable<boolean> {
		return this.getSoftwareOptions(companyId, EvxSoftwareOptions).map((res) => {
			if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
				return res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool] ? false : true;
			} else {
				return false;
			}
		});
	}

	getEvxOrIOCSoftwareOptions(companyId): Observable<EvxOrIOCResponse> {
		return this.getSoftwareOptions(companyId, EvxSoftwareOptions).map((res) => {
			const responseObject = new EvxOrIOCResponse();
			if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
				responseObject.enableNiriReviewTool = res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool.toString()] ? false : true;
			}
			responseObject.enableIntraoralCameraTool = res[EvxSoftwareOptions.ShouldEnableIntraoralCameraTool.toString()];
			return responseObject;
		});
	}

	getSnapshotSoftwareOptions(companyId): Observable<SnapshotResponse> {
		return this.getSoftwareOptions(companyId, SnapshotSoftwareOptions).map((res) => {
			const responseObject = new SnapshotResponse(
				res[SnapshotSoftwareOptions.ShouldDisableCaptureTool.toString()],
				res[SnapshotSoftwareOptions.ShouldDisableDrawingTool.toString()]
			);
			return responseObject;
		});
	}

	private getSoftwareOptions(companyId, softwareOptions) {
		let params = new HttpParams();
		for (let softwareOption in softwareOptions) {
			if (!isNaN(Number(softwareOption))) {
				params = params.append('softwareOptions', softwareOption);
			}
		}
		params = params.append('companyId', companyId);
		return this.isSoftwareOptionEnabled$(params);
	}

	isSoftwareOptionExists(softwareOptionId: number, softwareOptions: number[]): boolean {
		return softwareOptions && softwareOptions.some(so => so === softwareOptionId);
	}

	getCompanySoftwareOptions(selectedCompanyId): Observable<number[]> {
		return this.http.get(this.eupRoutesService.userSettings.getCompanySoftwareOptionIds + selectedCompanyId).map((res) => {
			return res as number[];
		});
	}
}

export class EvxOrIOCResponse {
	enableNiriReviewTool = false;
	enableIntraoralCameraTool = false;
}

export class SnapshotResponse {
	constructor(public shouldDisableCapture = false,
				public shouldDisableDrawingTool = false) {
	}
}
