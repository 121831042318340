﻿import { Component, Input } from '@angular/core';
import { Sorting, PagedQueryParameters } from '../generalInterfaces';
import { SortEventEmitterService } from './sortEventEmitter.service';

@Component({
	selector: 'eup-sortable-th',
	templateUrl: './sortableHeaderColumn.component.html',
	styleUrls: [ './sortableHeaderColumn.component.scss' ]
})
export class SortableHeaderColumn {
	@Input() title: string;
	@Input() name: string;
	@Input() active = false;
	@Input() sortingMode = Sorting.Descending;

	constructor(private emitter: SortEventEmitterService) {}

	get isAscending(): boolean {
		// sortingMode converted to integer because when binding sortingMode from
		// html makes sortingMode to be string
		return +this.sortingMode === Sorting.Ascending;
	}

	sortWasClicked(event: Event): void {
		this.sortingMode = this.sortingMode === Sorting.Descending ? Sorting.Ascending : Sorting.Descending;
		const sortAndFilter = new PagedQueryParameters();
		sortAndFilter.sort = this.sortingMode;
		sortAndFilter.sortField = this.name;
		this.active = true;
		this.emitter.clickSort(sortAndFilter);
		event.stopPropagation();
		event.preventDefault();
	}
}
