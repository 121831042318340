import { Routes } from '@angular/router';
import { ForbbidenComponent } from './errors/forbidden.component';
import { PageNotFoundComponent } from './errors/pageNotFound.component';
import { InternalServerErrorComponent } from './errors/internalServerError.component';
import { BadRequestComponent } from './errors/badRequest.component';
import { PasswordPolicyGuard } from './core/passwordPolicy/passwordPolicyGuard.service';
import { RolesGuard } from './core/roles/rolesGuard.service';
import { RoleTypeEnum } from './shared/enums';
import { AuthGuard } from './core/auth.guard';
import { RootGuard } from './core/root.guard';
import { FeaturesToggleSettingsGuard } from './featuresToggleSettings/featuresToggleSettingsGuard.service';
var ɵ0 = {
    expectedRoles: [RoleTypeEnum.Doctor]
}, ɵ1 = {
    expectedRoles: [
        RoleTypeEnum.Doctor,
        RoleTypeEnum.Personnel,
        RoleTypeEnum.Distributor,
        RoleTypeEnum.ManagementCompany
    ]
}, ɵ2 = {
    expectedRoles: [RoleTypeEnum.Personnel]
}, ɵ3 = {
    expectedRoles: [
        RoleTypeEnum.Doctor,
        RoleTypeEnum.Personnel,
        RoleTypeEnum.Distributor,
        RoleTypeEnum.ManagementCompany,
        RoleTypeEnum.Lab
    ]
}, ɵ4 = {
    expectedRoles: [RoleTypeEnum.Distributor]
}, ɵ5 = {
    expectedRoles: [RoleTypeEnum.ManagementCompany]
}, ɵ6 = {
    expectedRoles: [RoleTypeEnum.Lab]
}, ɵ7 = { title: '403' }, ɵ8 = { title: '404' }, ɵ9 = { title: '500' }, ɵ10 = { title: '400' }, ɵ11 = { title: '404' };
var routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                canActivate: [RootGuard],
                children: []
            },
            {
                path: 'doctors',
                loadChildren: './doctors/doctors.module#DoctorsModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ0
            },
            {
                path: 'messages',
                loadChildren: './messages/messages.module#MessagesModule',
                canActivate: [PasswordPolicyGuard]
            },
            {
                path: 'notifications',
                loadChildren: './notifications/notifications.module#NotificationsModule'
            },
            {
                path: 'activityreport',
                loadChildren: './activityReport/activityReport.module#ActivityReportModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ1
            },
            {
                path: "evx-downloads",
                loadChildren: "./evxDownloadsAndResources/evxDownloadsAndResources.module#EvxDownloadsAndResourcesModule"
            },
            {
                path: 'rx',
                loadChildren: './rx/rx.module#RxModule',
                canActivate: [PasswordPolicyGuard]
            },
            {
                path: 'personnels',
                loadChildren: './personnels/personnels.module#PersonnelsModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ2
            },
            {
                path: 'features',
                loadChildren: './featuresToggleSettings/featuresToggleSettings.module#FeaturesToggleSettingsModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                canLoad: [FeaturesToggleSettingsGuard],
                data: ɵ3
            },
            {
                path: 'distributors',
                loadChildren: './distributors/distributors.module#DistributorsModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ4
            },
            {
                path: 'managementcompanies',
                loadChildren: './managementCompanies/managementCompanies.module#ManagementCompaniesModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ5
            },
            {
                path: 'labs',
                loadChildren: './labs/labs.module#LabsModule',
                canActivate: [PasswordPolicyGuard, RolesGuard],
                data: ɵ6
            }
        ]
    },
    {
        path: 'thirdpartylogin',
        loadChildren: './thirdPartyLogin/thirdPartyLogin.module#ThirdPartyLoginModule'
    },
    {
        path: 'logonas',
        loadChildren: './logonAs/logonAs.module#LogonAsModule'
    },
    {
        path: 'downloads',
        loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
    },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'forbidden', component: ForbbidenComponent, pathMatch: 'full', data: ɵ7 },
    { path: 'pagenotfound', component: PageNotFoundComponent, pathMatch: 'full', data: ɵ8 },
    { path: 'internalservererror', component: InternalServerErrorComponent, pathMatch: 'full', data: ɵ9 },
    { path: 'badrequest', component: BadRequestComponent, pathMatch: 'full', data: ɵ10 },
    { path: '**', component: PageNotFoundComponent, data: ɵ11 }
];
var AppRoutes = /** @class */ (function () {
    function AppRoutes() {
    }
    return AppRoutes;
}());
export { AppRoutes };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
