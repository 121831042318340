import { EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
var ItemAdderComponent = /** @class */ (function () {
    function ItemAdderComponent() {
        this.valueDebouncer = new Subject();
        this.pFieldNumber = 0;
        this.minValueAllowed = 0;
        this.debouncePeriod = 0;
        this.emittedNumber = new EventEmitter();
    }
    Object.defineProperty(ItemAdderComponent.prototype, "disabled", {
        get: function () {
            return this.pDisabled;
        },
        set: function (disabledStatus) {
            this.pDisabled = disabledStatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemAdderComponent.prototype, "fieldNumber", {
        get: function () {
            return this.pFieldNumber;
        },
        set: function (num) {
            if (this.pFieldNumber === num) {
                return;
            } // This is so that the value isn't fired if it's the same
            this.pFieldNumber = (num) ? num : 0;
            this.valueDebouncer.next(this.pFieldNumber);
        },
        enumerable: true,
        configurable: true
    });
    ItemAdderComponent.prototype.checkMinValue = function (num) {
        this.fieldNumber = (num < this.minValueAllowed) ? this.minValueAllowed : num;
    };
    ItemAdderComponent.prototype.decreaseNumber = function () {
        this.checkMinValue(this.fieldNumber - 1);
    };
    ItemAdderComponent.prototype.increaseNumber = function () {
        this.fieldNumber++;
    };
    ItemAdderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.valueDebouncer
            .pipe(debounceTime(this.debouncePeriod))
            .subscribe(function (num) { return _this.emittedNumber.emit(num); });
    };
    return ItemAdderComponent;
}());
export { ItemAdderComponent };
