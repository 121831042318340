import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, AppSettings } from '../../shared/generalInterfaces';

@Injectable({
    providedIn: 'root'
  })
  export class AppConfigService {
  
    private appConfig: AppConfig = new AppConfig();
  
    constructor(private http: HttpClient) { }
  
    loadAppConfig(): Promise<void> {
      return this.http.get('/assets/config/appSettings.json')
        .toPromise()
        .then((data: AppSettings) => {
          this.appConfig.appSettings = data;
        });
    }
  
    get appSettings(): AppSettings {
      if (!this.appConfig.appSettings) {
        throw Error('App Settings not loaded!');
      }
  
      return this.appConfig.appSettings;
    }
  }