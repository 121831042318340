﻿import { Pipe, PipeTransform } from '@angular/core';

import { Sorting } from './generalInterfaces';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
	transform(array: any[], orderField: string, orderType: Sorting): string[] {
		const ASC = 1;
		const DESC = -1;
		let order: number = orderType === Sorting.Ascending ? ASC : DESC;
		if (array !== undefined) {
			array.sort((a: any, b: any) => {
				let comparedA = a[orderField];
				let comparedB = b[orderField];

				if (!comparedA && !comparedB) {
					return 0;
				} else if (comparedA && !comparedB) {
					return 1 * order;
				} else if (!comparedA && comparedB) {
					return -1 * order;
				}

				if (typeof a[orderField] === 'string') {
					comparedA = String(comparedA).toLowerCase();
					comparedB = String(comparedB).toLowerCase();
					return comparedA.localeCompare(comparedB) * order;
				}

				if (!isNaN(Date.parse(comparedA)) && !isNaN(Date.parse(comparedB))) {
					comparedA = Date.parse(comparedA);
					comparedB = Date.parse(comparedB);
				}

				if (comparedA < comparedB) {
					return -1 * order;
				} else if (comparedA > comparedB) {
					return 1 * order;
				} else {
					return 0;
				}
			});
		}
		return array;
	}
}
