import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SettingsModal } from './settings.component';
import { RxSettingsModal } from './rx/rxSettings.component';
import { LocalizationSettingsModal } from './localization/localizationSettings.component';
import { LoginSettingsModal } from './login/loginSettings.component';
import { SettingsService } from '../shared/settings.service';
import { ScannerSettingsModal } from './scanner/scannerSettings.component';
import { ExportSettingsModal } from './export/exportSettings.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ToggleButtonComponent } from '../shared/toggleButton/toggleButton.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ModalModule,
		BsDropdownModule,
		TranslateModule,
		RouterModule
	],
	declarations: [
		SettingsModal,
		RxSettingsModal,
		LocalizationSettingsModal,
		LoginSettingsModal,
		ScannerSettingsModal,
		ExportSettingsModal,
		ToggleButtonComponent
	],
	exports: [
		SettingsModal,
		RxSettingsModal,
		LocalizationSettingsModal,
		LoginSettingsModal,
		ScannerSettingsModal,
		ExportSettingsModal
	],
	providers: [ SettingsService ],
	entryComponents: [ LoginSettingsModal ]
})
export class SettingsModule {}
