import { Features } from '../app/shared/enums';
import { IScannerVersionsPerFeature } from '../app/shared/generalInterfaces';
export const addOrEditPatientPath = '/patient';
export const searchPatientPath = '/patient-search';

export const PracticeUserRoles = {
    ADMIN:"Admin",
    VISIBLE_TO_ALL_USERS:"VisibleToAllUsers",
    PRIMARY_CONTACT:"PrimaryContact"
}
export const featuresLimitedToMinScannerVersion: { [id: number]: IScannerVersionsPerFeature} = {
    [Features.patientAppEnabledForMyItero] : {element: '1.11.0.0', evx: '2.6.0.0'}
};

export const nonRedirectableRoutes = ['login', 'forbidden', 'pagenotfound', 'internalservererror', 'badrequest'];
