﻿import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { Language } from './generalInterfaces';

@Injectable()
export class SharedAnonymousService {
	constructor(private http: EupHttpHandler, private eupRoutesService: EupRoutesService) {}

	getLanguages(): Observable<any> {
		return this.http.get(this.eupRoutesService.shared.getLanguages).map((res: any) => {
			return {
				languages: res.languages.map((o: any) => {
					return <Language>{ code: o.code, localizedName: o.localizedName, name: o.name };
				})
			};
		});
	}
}
