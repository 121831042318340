﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Utils } from './utils.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { SharedAnonymousService } from './shared.anonymous.service';
import { FooterComponent } from './footer/footer.component';

@NgModule({
	imports: [ ModalModule, BsDropdownModule ],
	declarations: [ FooterComponent ],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ModalModule,
		BsDropdownModule,
		BsDatepickerModule,
		TranslateModule,
		FooterComponent
	],
	providers: [ Utils, SharedAnonymousService ]
})
export // This module exports all modules which do not requrie authentication
class SharedAnonymousModule {}
