import { EventEmitter } from '@angular/core';
var RadioButtonComponent = /** @class */ (function () {
    function RadioButtonComponent() {
        this._selectedValue = '';
        this.radioButtonStateEmitter = new EventEmitter();
    }
    Object.defineProperty(RadioButtonComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (value) {
            this._checked = value;
            this.radioButtonStateEmitter.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    return RadioButtonComponent;
}());
export { RadioButtonComponent };
