import { CreateOrthodonticsExportFileRequestDto } from '../generalInterfaces';

export interface DownloadFileInfo {
  parallelWorkOrderId: number;
  orderId: number;
  orderDetailsId: number;
  workOrderId?: number;
  downloaded: boolean;
}

export interface FileInfo extends DownloadFileInfo
{
	progress: number;
	creationTime: number;
	requestData: CreateOrthodonticsExportFileRequestDto;
  status: FileInfoStatus;
}

export enum FileInfoStatus {
	NoStatus = -1,
	Created = 1,
	Released = 2,
	Active = 3,
	Completed = 4,
	Failed = 5,
	Canceled = 6
}
