import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
	selector: 'eup-text-send',
	templateUrl: './textSend.component.html',
	styleUrls: ['./textSend.component.scss'],
	providers: []
})
export class TextSendComponent implements OnInit {
	public fieldText: string;
	public disallowedChars = '!@#$£%^&*)(_\'';

	@Input()
	public initialMessage: string;

	@Output()
	public textToSend: EventEmitter<string> = new EventEmitter();

	@ViewChild('editableField')
	private editableFieldElem: ElementRef;

	constructor() {
	}

	public setText(evt) {
		this.fieldText = evt.currentTarget.textContent;
	}

	public sendText() {
		this.textToSend.emit(this.fieldText);
	}

	ngOnInit() {
		this.fieldText = (this.initialMessage) ? this.initialMessage : this.fieldText;
		this.editableFieldElem.nativeElement.focus();
	}

}
