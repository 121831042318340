import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { BehaviorSubject } from 'rxjs';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../core/eupHttpHandler.service";
import * as i2 from "../../core/eupRoutes.service";
import * as i3 from "../../core/globalSettings.service";
import * as i4 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(http, eupRoutesService, globalSettingsService, router) {
        this.http = http;
        this.eupRoutesService = eupRoutesService;
        this.globalSettingsService = globalSettingsService;
        this.router = router;
        this.authStatus$ = new BehaviorSubject({ isAuthenticated: this.isAuthenticated() });
    }
    AuthService.prototype.login = function (user) {
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var auth = function () {
            return _this.http
                .post(_this.eupRoutesService.login.url, { username: user.username, password: user.password }, { headers: headers, observe: 'response' })
                .map(function (res) {
                if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
                    _this.authStatus$.next({ isAuthenticated: true });
                    return res.body;
                }
                else {
                    // in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
                    return res.json();
                }
            });
        };
        return this.eupRoutesService.doAfterInit(auth);
    };
    AuthService.prototype.impersonate = function (user) {
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var auth = function () { return _this.http.post(_this.eupRoutesService.logonAs.url, { contactId: user.contactId, businessPartnerId: user.businessPartnerId }, { headers: headers, observe: 'response' })
            .map(function (res) {
            if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
                _this.authStatus$.next({ isAuthenticated: true });
                return res.body;
            }
            else {
                // in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
                return res.json();
            }
        }); };
        return this.eupRoutesService.doAfterInit(auth);
    };
    AuthService.prototype.getContactWithBusinessPartners = function (userName) {
        var params = new HttpParams().set('contactName', userName);
        return this.http.get(this.eupRoutesService.logonAs.getContacts, { params: params }, null, false);
    };
    AuthService.prototype.getDetails = function () {
        var _this = this;
        var getLogin = function () { return _this.http.get(_this.eupRoutesService.getLoginData(), undefined, undefined, false); };
        return this.eupRoutesService.doAfterInit(getLogin, true);
    };
    AuthService.prototype.isAuthenticated = function () {
        var settings = this.globalSettingsService.get();
        return !!settings;
    };
    AuthService.prototype.logout = function (params) {
        if (params === void 0) { params = {}; }
        this.router.navigate(['/login'], params.queryParams);
        this.globalSettingsService.clear();
        this.authStatus$.next({ isAuthenticated: false, isUnauthenticatedByTimeout: params.byTimeout });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.EupHttpHandler), i0.inject(i2.EupRoutesService), i0.inject(i3.GlobalSettingsService), i0.inject(i4.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
var UserCredentials = /** @class */ (function () {
    function UserCredentials(username, password) {
        this.username = username;
        this.password = password;
    }
    return UserCredentials;
}());
export { UserCredentials };
var ImpersonateUserCredentials = /** @class */ (function () {
    function ImpersonateUserCredentials(contactId, businessPartnerId) {
        this.businessPartnerId = businessPartnerId;
        this.contactId = contactId;
    }
    return ImpersonateUserCredentials;
}());
export { ImpersonateUserCredentials };
var LoginDetails = /** @class */ (function () {
    function LoginDetails() {
    }
    return LoginDetails;
}());
export { LoginDetails };
