﻿import { HttpParams } from '@angular/common/http';
import {
	ExportTypeFormatEnum,
	ExportDataFormatEnum,
	FileTypeParallelEnum,
	ExcelExtensionsEnum,
	LabCasesState
} from './enums';
import { Observable } from 'rxjs';
import {IKeyValue} from '../interfaces/IKeyValue';

export enum Sorting {
	Ascending = 0,
	Descending = 1
}

export class PagedQueryParameters {
	pageNumber: number;
	pageSize: number;
	filter: string;
	sortField: string;
	companyId: number;
	doctorId: number;
	isNiriFileExist:string;
    sort: Sorting;
    existingTotal: number;
	defaultTotalValue: number;
	constructor() {
		this.existingTotal = this.defaultTotalValue;
	}

	createSearchParams(): HttpParams {
		let params = new HttpParams();

		if (this.pageNumber) {
			params = params.set('PageNumber', this.pageNumber.toString());
		}

		if (this.pageSize) {
			params = params.set('PageSize', this.pageSize.toString());
		}

		if (this.sort) {
			params = params.set('Sort', this.sort.toString());
		}

		if (this.sortField) {
			params = params.set('SortField', this.sortField);
		}

		if (this.filter) {
			params = params.set('Filter', this.filter);
		}

		if (this.companyId) {
			params = params.set('CompanyId', this.companyId.toString());
		}

		if (this.doctorId) {
			params = params.set('DoctorId', this.doctorId.toString());
		}
		if (this.isNiriFileExist) {
			params = params.set('isNiriFileExist', this.isNiriFileExist.toString());
        }
        if (!isNaN(this.existingTotal)) {
            params = params.set('existingTotal', this.existingTotal.toString());
		}
		return params;
	}
}


export class ContactQueryParameters extends PagedQueryParameters {
	isAdmin: boolean;
	isPrimaryContact: boolean;
	isVisibleToAllOrders: boolean;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams()

		if (this.isAdmin) {
			params = params.set('isAdministrator', this.isAdmin.toString());
		}
		
		if (this.isPrimaryContact) {
			params = params.set('isPrimaryContact', this.isPrimaryContact.toString());
		}

		if (this.isVisibleToAllOrders) {
			params = params.set('isVisibleToAllOrders', this.isVisibleToAllOrders.toString());
		}
	
		return params;
	}
}

export class LabOrdersPagedQueryParameters extends PagedQueryParameters {
	filterMode: LabCasesState;
	dateFormat: string;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams();
		if (this.filterMode) {
			params = params.set('filterMode', this.filterMode.toString());
		}
		if (this.dateFormat) {
			params = params.set('dateFormat', this.dateFormat.toString());
		}
		return params;
	}
}

export class ActivityReportPagedQueryParameters extends PagedQueryParameters {
	fromDate: Date;
	toDate: Date;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams();
		if (this.fromDate) {
			params = params.set('from', this.fromDate.toUTCString());
		}
		if (this.companyId) {
			params = params.set('to', this.toDate.toUTCString());
		}
		return params;
	}
}

export class PagedQueryResult {
	results: any[];
	pageSize: number;
	totalItemCount: number;
	totalPageCount: number;
}

export interface ITableService {
	requestPage: (query: PagedQueryParameters) => Observable<PagedQueryResult>;
	getRows: () => any[];
}

export class RxRunMode {
	static View = 'View';
	static Edit = 'Edit';
}

export class Contact {
	contactId: number;
	contactName: string;
}

export class ContactWithBusinessPartners {
	contactId: number;
	contactName: string;

	businessPartners: Company[];
}

export class Company {
	id: number;
	name: string;
	currentUserHasFullOrdersVisibility: boolean;
	highestScannerVersion: string;
	scannerDetails: ScannerDetails[];
	contacts: Contact[];
	region: string;
	handlingEupUrl: string;
}

export class Language {
	id: number;
	code: string;
	localizedName: string;
	name: string;
	isSupportedForLab: boolean;
	isSupportedForDoctor: boolean;
}

export class UserLanguage{
	code: string;
    description: string;
    englishName: string;
}

export class LocaleData {
	languages: Language[];
	dateFormats: string[];
}

export class ScannerDetails {
	description: string;
	softwareVersion: string;
	baseSerialNumber: string;
	wandSerial: string;
	companyId: number;
	iOsimVersion: string;
}

export class KeyValuePair implements IKeyValue {
	constructor(public key: string, public value: any) {}
}

export class ExportRequestDto {
	clientId: number;
	orderId: number;
	orderDetailsId: number;
	parallelWorkOrderId: number;
	workOrderId?: number;
}

export class CreateOrthodonticsExportFileRequestDto {
	clientId: number;
	orderId: number;
	orderDetailsId: number;
	typeFormat: ExportTypeFormatEnum;
	fileFormat: ExportDataFormatEnum;
	fileType: FileTypeParallelEnum;
	patientAnonymization: boolean;
}

export class CreateRestorativeExportFileRequestDto {
	clientId: number;
	orderId: number;
	orderDetailsId: number;
	cadCamSystemTypeId: number;
	patientAnonymization: boolean;
}

export class CreateExportFilesRequestDto {
	orthodonticsExportRequest?: {
		dtos: CreateOrthodonticsExportFileRequestDto[];
	};
	restorativeExportRequest?: {
		dtos: CreateRestorativeExportFileRequestDto[];
	};
}

export class PasswordPolicyConfig {
	showPasswordPolicyNotification: boolean;
	mustChangePasswordLastDate: string;
	passwordPolicyNotificationApproved = false;
	mustChangePasswordNow: boolean;
	isTemporaryPassword: boolean;
}

export class ExcelExportOptions {
	data: any[];
	fileName: string;
	extension: ExcelExtensionsEnum;
	sheetName: string;
	/** The keys are the proprties' names of the object that is being exported to excel */
	/** The values can be any strings represent the headers of the excel file or keys for headers' translations */
	headers: KeyValuePair[];
}

export class ChangeCaseTypeEmitData {
	orderId: number;
	caseTypeId: number;
	caseTypeDescription: string;
}

export class UpdatePatientData {
	doctorId: number;
	patientGuid: string;
	firstName: string;
	lastName: string;
	chartNumber: string;
	companyId: number;
}

export class PatientDto {
	id: string;
	fullName: string;
	firstName: string;
	lastName: string;
	mi: string;
	dateOfBirth: string;
	gender: string;
	zipCode: string;
	chartNumber: string;
	alignPatientId: string;
	uid: string;
	lastScanDate: Date;
	type: number;
}

export interface IScannerVersionsPerFeature {
	element: string;
	evx: string;
}

export interface ApiError {
	status: StatusCode;
    error: string;
    headers: any;
}

export enum StatusCode {
	Ok = 200,
	ContentCreated = 201,
	NoContent = 204,
	Redirect = 302,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	PageNotFount = 404,
	InternalServerError = 500
}

export enum RxiFrameEvents {
	GetAvailableCaseTypes = 1,
	RenewSession = 2,
	SaveOrderRxForm = 3,
	SaveRx = 4,
	LoadConfiguration = 5,
	LoadContactConfiguration = 6,
	LoadUserSettings = 7,
	GetRxByOrderId = 8,
	GetRxById = 9,
	GetPatientByUid = 10,
	CheckPatientExistBy = 11,
	FlushInstrumentationLogs = 12,
	EupHomeClick = 13,
	RxSaveEventToiFrameParentNavigateBack = 14,
	RxSaveEventToiFrameParentCanceled = 15,
	RxLoadCompleted = 16,
	GetScannerPatientsByDoctor = 17,
	IsAltaEnabled = 18,
	GetScannerDoctorIdsAddresses = 19,
	GetIdsPatientsForCurrentDoctor = 20,
	GetOrderInformationConfiguration = 21,
	GetOrderToSaveAndProceed = 22,
	GetOrderToSaveAndRescan = 23,
	GetOrderToSaveAndProceedToModeling = 24,
	GetLabNotes = 25,
    SetLabNote = 26,
	ReloadWithoutUserConfirmation = 27,
	PatientAppRedirect = 106
}

export enum PatientIframeEvents {
	PatientAppGeneral = 100,
	PatientAppAdd = 101,
	PatientAppSelectedPatient = 102,
	PatientAppUpdatePatient = 103,
	PatientAppSelectedPatientConflict = 104,
	PatientAppRedirect = 106
}

export class ExportSettings {
	constructor(
		public exportTypeIrecord: ExportTypeFormatEnum = 0,
		public dataFormat: ExportDataFormatEnum = 0,
		public fileType: FileTypeParallelEnum = 0,
		public cadCamSystemTypeId: number = 0
	) {}
}

export class AppConfig {
	constructor(
		public appSettings: AppSettings = new AppSettings()
	) {}
}

export class AppSettings {
	constructor(
		public apiEndpoint: string = 'http://localhost:54386',
		public loggingEndpoint: string = 'http://dxqtbjezmi9b4.cloudfront.net/empty.png',
		public patientAppEndpoint: string =  'http://localhost:3000',
		public isProduction: string = "false"
	) {}
}

