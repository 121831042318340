﻿import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective {
	constructor(private el: ElementRef) {}

	@Output() clickOutside = new EventEmitter();

	@HostListener('document:click', [ '$event' ])
	onClick(targetElement: Event) {
		const clickedInside = this.el.nativeElement.contains(targetElement.target);

		if (!clickedInside) {
			this.clickOutside.emit(null);
		}
	}
}
