﻿import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SpinnerService {
	// spinner will be displayed only after waiting for 'delayTime' milliseconds
	readonly delayTime = 200;
	private status = new Subject<boolean>();
	onActiveChanged = this.status.asObservable().debounceTime(this.delayTime);
	counter = 0;

	private setActive() {
		this.status.next(this.counter > 0);
	}

	start(onRoute = false): void {
		if (onRoute) {
			this.counter = 0;
		}
		this.counter++;
		this.setActive();
	}

	stop(): void {
		if (--this.counter < 0) {
			this.counter = 0;
		}
		this.setActive();
	}
}
