﻿import { Component, Output, EventEmitter } from '@angular/core';
import { DownloadNotificationService } from './downloadNotification.service';
import { Observable } from 'rxjs';
import { FileInfo } from './fileInfo.model';

@Component({
	selector: 'eup-download-notification',
	templateUrl: './downloadNotification.component.html',
	styleUrls: [ './downloadNotification.component.scss' ]
})
export class DownloadNotification {
	files$: Observable<FileInfo[]>;
	listFlag = false;
	@Output() open: EventEmitter<any> = new EventEmitter();

	constructor(private notification: DownloadNotificationService) {
		this.files$ = notification.getObservable();
	}

	calcProgress(files: FileInfo[]): number {
		const length = files.length;
		const arr = files.map((file) => file.progress);
		const sum = arr.reduce((previous, current) => (current += previous), 0);
		return sum / length;
	}

	onToggle() {
		this.listFlag = !this.listFlag;
		if (this.listFlag === true) {
			this.open.emit(null);
		}
	}

	onClose() {
		this.listFlag = false;
	}
}
