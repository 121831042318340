var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Subject } from 'rxjs';
import * as signalR from '@aspnet/signalr';
import { LoggerService } from '../logging/logger.service';
import * as i0 from "@angular/core";
import * as i1 from "../logging/logger.service";
var ConnectionState;
(function (ConnectionState) {
    ConnectionState[ConnectionState["Disconnected"] = 0] = "Disconnected";
    ConnectionState[ConnectionState["Connecting"] = 1] = "Connecting";
    ConnectionState[ConnectionState["Connected"] = 2] = "Connected";
    ConnectionState[ConnectionState["Disconnecting"] = 3] = "Disconnecting";
})(ConnectionState || (ConnectionState = {}));
var SignalRService = /** @class */ (function () {
    function SignalRService(loggerService) {
        this.loggerService = loggerService;
        this.channelName = 'EupClientMessageRecieved';
        this.connectionRestartingDelay = 5000;
        this.messageBroker$ = new Subject();
        this.connectionState = ConnectionState.Disconnected;
    }
    SignalRService.prototype.initMessages = function (hubUrl, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.connectionState !== ConnectionState.Disconnected) {
                            return [2 /*return*/];
                        }
                        this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl(hubUrl, {
                            accessTokenFactory: function () { return accessToken; }
                        })
                            .build();
                        this.hubConnection.on(this.channelName, function (message) {
                            _this.messageBroker$.next(message);
                        });
                        this.hubConnection.onclose(function () { return __awaiter(_this, void 0, void 0, function () {
                            var previousState;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        previousState = this.connectionState;
                                        this.loggerService.trace("Connection closed.", { module: 'signalR.service' });
                                        this.connectionState = ConnectionState.Disconnected;
                                        if (!(previousState !== ConnectionState.Disconnecting)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.startConnection()];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, this.startConnection()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SignalRService.prototype.startConnection = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.connectionState = ConnectionState.Connecting;
                        return [4 /*yield*/, this.startHub()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SignalRService.prototype.startHub = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.connectionState !== ConnectionState.Connecting) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.hubConnection.start()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _1 = _a.sent();
                        this.loggerService.warn("Unexpected connection fail. Trying to reconnect in " + this.connectionRestartingDelay + " ms...", { module: 'signalR.service' });
                        this.startHubDeferred(this.connectionRestartingDelay);
                        return [2 /*return*/];
                    case 4:
                        this.connectionState = ConnectionState.Connected;
                        this.loggerService.debug("Connection established.", { module: 'signalR.service' });
                        return [2 /*return*/];
                }
            });
        });
    };
    SignalRService.prototype.startHubDeferred = function (delayInMilliseconds) {
        var _this = this;
        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.startHub()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); }, delayInMilliseconds);
    };
    SignalRService.prototype.closeConnection = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isHubConnected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.hubConnection) {
                            return [2 /*return*/];
                        }
                        isHubConnected = this.hubConnection.state === signalR.HubConnectionState.Connected;
                        if (!isHubConnected) return [3 /*break*/, 2];
                        this.connectionState = ConnectionState.Disconnecting;
                        return [4 /*yield*/, this.hubConnection.stop()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.connectionState = ConnectionState.Disconnected;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SignalRService.prototype.registerToMessages = function () {
        return this.messageBroker$.asObservable();
    };
    SignalRService.ngInjectableDef = i0.defineInjectable({ factory: function SignalRService_Factory() { return new SignalRService(i0.inject(i1.LoggerService)); }, token: SignalRService, providedIn: "root" });
    return SignalRService;
}());
export { SignalRService };
