import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'eup-generic-dropdown2',
	templateUrl: './dropdown2.component.html',
	styleUrls: [ './dropdown2.component.scss' ]
})
export class Dropdown2 implements OnChanges {
	@Input() label: string;
	
	@Input() selectedValue?: any;
	@Input() isRemoveEmptyItems?= true;
	@Input() parentForm: FormGroup;
	@Input() formControlName: string;
	@Input() name: string
	@Output() onSelect?: EventEmitter<any> = new EventEmitter();
	selected: any;

	private itemsList: any[];

	@Input()
	set items(listOfItems: any[]) {
		this.itemsList = listOfItems;
		this.selected = this.itemsList[0];
		this.realSelectBoxValue = this.selected.value;
	}

	get items() {
		return this.itemsList;
	}


	public realSelectBoxValue: string ;


	onSelected(item) {
		this.selected = item;
		this.onSelect.emit(item);
		this.realSelectBoxValue = item.value;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.items !== undefined &&
			changes.items.currentValue !== undefined &&
			changes.items.currentValue !== null
		) {
			if (this.isRemoveEmptyItems) {
				changes.items.currentValue = changes.items.currentValue.filter((i) => i.value !== null);
			}
		}

		if (
			changes.selectedValue !== undefined &&
			changes.selectedValue.currentValue !== undefined &&
			changes.selectedValue.currentValue !== null
		) {
			if (this.items !== undefined && changes.selectedValue.currentValue !== 0) {
				const found = this.items.find((i) => i.key === changes.selectedValue.currentValue);
				this.selected = found !== undefined ? found : this.selected;
			}
		}
	}
}
