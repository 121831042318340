﻿import { Injectable } from '@angular/core';
import { EupHttpHandler } from './eupHttpHandler.service';
import { LocalStorageService } from './localStorage.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { LocaleData } from '@shared/generalInterfaces';
import { FeatureToggleSettings } from '../featuresToggleSettings/featuresToggleSettings.model';
import { Consts } from '@shared/consts';
import { PatientType } from '@shared/enums';

@Injectable()
export class EupRoutesService {
	private static apiPrefix:string = 'mvc/';
	private static mvcUrl: string
	private static MATserverUrl: string;
	private static serverUrl: string;
	private static rxUIUrl: string;
	private storage: Storage;

	public mvcUrl: any;
	public serverUrl: any;
	public MATserverUrl: any;
	public notificationAgentUrl: string;
	public iTeroSiteUrl: any;
	public rxUIUrl: any;

	public downloads: any;
	public home: any;
	public login: any;
	public orders: any;
	public lab: any;
	public rx: any;
	public rxForm: any;
	public messages: any;
	public patients: any;
	public accountManagement: any;
	public patientOrders: any;
	public logonAs: any;
	public userSettings: any;
	public featuresToggleSettings: any;
	public companySettings: any;
	public activityReport: any;
	public thirdPartyLogin: any;
	public shared: any;
	public viewer: any;
	public orderInformation: any;
	public orderConfiguration: any;
	public AlignUrl: string;
	public PrivacyNCookiesUrl: string;
	public TermsUrl: string;
	public TermsUrlObs = new BehaviorSubject({});
	private initHandle: (force: boolean) => Observable<any>;
	public drawingToolUrl: string;

	constructor(
		private http: EupHttpHandler,
		private localStorageService: LocalStorageService,
		private appConfigService: AppConfigService
	) {
		this.storage = localStorageService.storage;
		if (this.routeCanBeUsed()) {
			const routingData = JSON.parse(this.storage[Consts.Storage.Routes]) as RoutingData;
			this.initialize(routingData);
		}

		this.initHandle = (force) => 
			Observable.from(this.getRoutes(force).flatMap((res: any) => this.initStorage(res)));
	}

	getRoutes(forceDataRetrieve: boolean = false): Observable<RoutingData> {
		if (this.routeCanBeUsed(forceDataRetrieve)) {
			return new Observable<RoutingData>((observer) => {
				observer.next(JSON.parse(this.storage[Consts.Storage.Routes]) as RoutingData);
			});
		}

		let apiEndpoint = this.storage[Consts.Storage.RegionalApiEndpoint] ? this.storage[Consts.Storage.RegionalApiEndpoint] : this.appConfigService.appSettings.apiEndpoint;
		if (apiEndpoint.endsWith('/')) {
			apiEndpoint = apiEndpoint.slice(0, -1);
		}
		return this.http.get(`${apiEndpoint}/${EupRoutesService.apiPrefix}settings/GetRoutingData`);
	}

	getLocalizationSettings(): Observable<LocaleData> {
		return this.http.get(`${this.appConfigService.appSettings.apiEndpoint}/${EupRoutesService.apiPrefix}settings/getLocalizationSettings`);
	}
	
	getFeaturesToggleSettings(): Observable<FeatureToggleSettings[]> {
		return this.http.get(`${this.appConfigService.appSettings.apiEndpoint}/${EupRoutesService.apiPrefix}featuresToggleSettings/getFeaturesToggleSettings`);
	}
	
	initStorage(res: RoutingData): Observable<Object> {
		if (this.routeCanBeUsed()) {
			return new Observable((observer) => {
				this.initialize(res);
				observer.next({});
			});
		}

		return new Observable((observer) => {
			this.storage[Consts.Storage.Routes] = JSON.stringify(res);
			this.storage[Consts.Storage.RegionalApiEndpoint] = this.storage[Consts.Storage.RegionalApiEndpoint] ? this.storage[Consts.Storage.RegionalApiEndpoint] : res.serverUrl;
			this.initialize(res);
			observer.next({});
		});
	}

	getLoginData(): string {
		if (!this.login) {
			throw 'routing data not initialised!';
		}

		return this.login.loginData;
	}

	doAfterInit<T>(observable: () => Observable<T>, force: boolean = false): Observable<T> {
		return this.initHandle(force).flatMap(observable);
	}

	initialize(routingData: RoutingData) {
		if (!!this.userSettings || !routingData) {
			return; // no data or init was already scheduled before
		}

		this.notificationAgentUrl = routingData.notificationAgentUrl;
		EupRoutesService.serverUrl = this.serverUrl = routingData.serverUrl;
		EupRoutesService.MATserverUrl = this.MATserverUrl = routingData.MATserverUrl;
		EupRoutesService.mvcUrl = this.mvcUrl = this.serverUrl + EupRoutesService.apiPrefix;
		EupRoutesService.rxUIUrl = this.rxUIUrl = routingData.rxUIUrl;

		this.AlignUrl = routingData.alignUrl;
		this.PrivacyNCookiesUrl = routingData.privacyAndCookiesUrl;
		this.TermsUrl = routingData.termsUrl;
		this.TermsUrlObs.next(this.TermsUrl);
		this.drawingToolUrl = routingData.drawingToolUrl;

		this.iTeroSiteUrl = routingData.iTeroSiteUrl;

		this.downloads = class DownloadRoute {
			static orthoCadSetupDownloadUrl = routingData.orthoCadSetupDownloadUrl;
			static orthoCadSetupDownloadUrl_acs = routingData.orthoCadSetupDownloadUrl_acs;
			static iTeroSetupDownloadUrl = routingData.iTeroSetupDownloadUrl;
			static iTeroSetupDownloadUrl_acs = routingData.iTeroSetupDownloadUrl_acs;
			static iOSimDownloadUrl = routingData.iOSimDownloadUrl;
			static iOSimDownloadUrl_acs = routingData.iOSimDownloadUrl_acs;
			static compatibilityTester = routingData.compatibilityTester;
			static RTHFirstTimeInstaller = routingData.RTHFirstTimeInstaller;
			static EvxFirstTimeInstaller = routingData.EvxFirstTimeInstaller;
		};

		this.home = class HomeRoute {
			static url = EupRoutesService.mvcUrl + 'home/';
			static logout = HomeRoute.url + 'logout';
		};

		this.login = class LoginRoute {
			static url = EupRoutesService.mvcUrl + 'login/';
			static loginData = LoginRoute.url + 'getLoginInfo';
			static browserLanguageCode = LoginRoute.url + 'browserLanguageCode';
		};

		this.thirdPartyLogin = class ThirdPartyLoginRoute {
			static url = EupRoutesService.mvcUrl + 'thirdPartyLogin/';
			static authorize = ThirdPartyLoginRoute.url + 'authorize/';
			static setCompany = ThirdPartyLoginRoute.url + 'setCompany/';
		};

		this.orders = class OrderRoute {
			static url = EupRoutesService.mvcUrl + 'orders/';
			static filesUrl = EupRoutesService.mvcUrl  +  'orderfiles/';
			static undeliveredUrl = OrderRoute.url + 'undelivered';
			static deliveredUrl = OrderRoute.url + 'delivered';
			static deleteRxUrl = OrderRoute.url + 'deleteRx';
			static isFailedOrderExistsUrl = OrderRoute.url + 'isFailedOrderExists';
			static changeCaseTypeOptionsUrl = OrderRoute.url + 'GetChangeCaseTypeOptions';
			static changeCaseTypeForOrderUrl = OrderRoute.url + 'ChangeCaseTypeForOrder';
			static getOrthodonticsExportInfo = OrderRoute.url + 'getOrthodonticsExportInfo';
			static getRestorativeExportInfo = OrderRoute.url + 'getRestorativeExportInfo';
			static getOrthodonticsExportInfoByWorkOrder = OrderRoute.url + 'getOrthodonticsExportInfoByWorkOrder';
			static getRestorativeExportInfoByWorkOrder = OrderRoute.url + 'getRestorativeExportInfoByWorkOrder';
			static getStlFile = OrderRoute.filesUrl + 'getSTLFile';
			static getStlFileByWorkOrder = OrderRoute.filesUrl + 'GetStlFileByWorkOrder';
			static getExportStatus = OrderRoute.url + 'getExportStatus';
			static getExportStatusBulk = OrderRoute.url + 'GetExportStatusBulk';
			static getExportInfoBulk = OrderRoute.url + 'getExportInfoBulk';
			static getExportInfoBulkByWorkOrder = OrderRoute.url + 'GetExportInfoBulkByWorkOrder';
			static getScreenshotDownloadLink = OrderRoute.filesUrl + 'GetEvxFile';
			static isEvxFileExists = OrderRoute.filesUrl + 'IsEvxFileExists';
			static getFileStorage = OrderRoute.filesUrl + 'GetFileStorage';
			static updatePrintedOrdersUrl = OrderRoute.url + 'updatePrintedOrders';
			static uploadCapture = OrderRoute.filesUrl + 'UploadCaptureFile';
			static setReadyForDownloadAsDownloadedForLab = OrderRoute.url + 'setReadyForDownloadAsDownloadedForLab';
		};

		this.lab = class LabRoute {
			static url = EupRoutesService.mvcUrl + 'lab/';
			static getOrdersUrl = LabRoute.url + 'getOrders';
			static getOrdersTotalUrl = LabRoute.url + 'getOrdersTotal';
			static getTotalOrdersAmountByState = LabRoute.url + 'getTotalOrdersAmountByState';
			static logout = LabRoute.url + 'logout';
			static proceedToMilling = LabRoute.url + 'proceedToMilling';
			static proceedToModeling = LabRoute.url + 'proceedToModeling';	
			static backToScanning = LabRoute.url + 'backToScanning';
			static getRescanInfo = LabRoute.url + 'GetRescanInfo';
			static getOrderByIdUrl = LabRoute.url + 'getOrderById';
			static buildITeroProtocolLink = LabRoute.url + 'BuildITeroProtocolLink';
			static processSendToLab = LabRoute.url + 'ProcessSendToLab';
		};

		this.rx = class RxRoute {
			static endpointForRx = EupRoutesService.mvcUrl + 'rx';
			static endpointForGlidewellRx = EupRoutesService.mvcUrl + 'ThirdPartyRx';
			static rxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/RxEditor.html';
			static webRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/WebRx.html';
			static printRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/printrx.html';
			static onlineOrthoViewerUrl = routingData.onlineOrthoViewerUrl;
			static onlineRestorativeViewerUrl = routingData.onlineRestorativeViewerUrl;
			static getAvailableCaseTypesForRxUrl = RxRoute.endpointForRx + '/GetAvailableCaseTypes';
			static configurationUrl = RxRoute.endpointForRx + '/Configuration';
			static saveUrl = RxRoute.endpointForRx + '/Save';
			static getUserSettingsUrl = RxRoute.endpointForRx + '/GetUserSettings';
			static rxContactConfigUrl = RxRoute.endpointForRx + '/RxContactConfig';
			static getRxByIdUrl = RxRoute.endpointForRx + '/GetRxById';
			static getRxByOrderIdUrl = RxRoute.endpointForRx + '/GetRxByOrderId';
			static getPatientByUIDUrl = RxRoute.endpointForRx + '/GetPatientByUID';
			static checkPatientExistByUrl = RxRoute.endpointForRx + '/CheckPatientExistBy';
		};

		this.rxForm = class RxFormRoute {
			static endpointForRxForm = EupRoutesService.mvcUrl + 'rxForm';
			static saveOrderRxFormUrl = RxFormRoute.endpointForRxForm + '/SaveOrderRxForm';
		};

		this.featuresToggleSettings = class FeaturesToggleSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'featuresToggleSettings/';
			static getFeaturesToggleSettings = FeaturesToggleSettingsRoute.url + 'getFeaturesToggleSettings/';
			static updateFeaturesToggleSettings = FeaturesToggleSettingsRoute.url + 'updateFeaturesToggleSettings/';
		};

		this.messages = class MessageRoute {
			static url = EupRoutesService.mvcUrl + 'messages/';
			static newMessagesCountUrl = MessageRoute.url + 'GetNewMessagesCount';
			static getMessagesListUrl = MessageRoute.url + 'GetMessagesList';
			static markAsReadUrl = MessageRoute.url + 'MarkAsRead';
			static markAsUnreadUrl = MessageRoute.url + 'MarkAsUnread';
		};

		this.patients = class PatientRoute {
			static url = EupRoutesService.mvcUrl + 'patients/';
			static searchUrl = PatientRoute.url + 'search';

			static getPatientsUrl = (patientTypes: PatientType[], forceCacheUpdate:boolean = false)=>{
				if (!patientTypes || !Array.isArray(patientTypes)) return '';
				const patientTypeQuery = patientTypes.reduce((query,type) => {
					return `${query}patientTypes=${type}&`;
				},'');
				return `${PatientRoute.url}?${patientTypeQuery}forceCacheUpdate=${forceCacheUpdate}`;
			};
		};

		this.patientOrders = class PatientOrdersRoute {
			static url = EupRoutesService.mvcUrl + 'patientsOrders/';
			static ordersUrl = PatientOrdersRoute.url + 'orders';
			static checkPatientDoesntExistAndUpdateUrl = PatientOrdersRoute.url + 'checkPatientDoesntExistAndUpdate';
		};

		this.logonAs = class LogonAsRoute {
			static url = EupRoutesService.mvcUrl + 'logonAs/';
			static getContacts = LogonAsRoute.url + 'getContacts';
			static getDoctorsByCompanyId = LogonAsRoute.url + 'getDoctorsByCompanyId';
		};

		this.userSettings = class UserSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'settings/';
			static getUrl = UserSettingsRoute.url + 'getUserSettings';
			static setUrl = UserSettingsRoute.url + 'setUserSettings';
			static localizationSettingsByRoleTypeUrl = UserSettingsRoute.url + 'getLocalizationSettingsByRoleType';
			static setCredentials = UserSettingsRoute.url + 'setSecurityInfo';
			static getVisibleUserAuthenticationDetails = UserSettingsRoute.url +
				 'GetVisibleUserAuthenticationDetails?userName=';
			static getAllSecurityQuestions = UserSettingsRoute.url + 'getAllSecurityQuestions';
			static matPasswordPolicyPageUrl = EupRoutesService.MATserverUrl +
				 'UnSecured/PasswordPolicyTouch.aspx?mode=EUP&Language={0}';
			static getCompanyRegionUrl = UserSettingsRoute.url + 'getCompanyRegion';
			static getExportGeneralSettingsUrl = UserSettingsRoute.url + 'getExportGeneralSettings';
			static setCompanySettingsUrl = UserSettingsRoute.url + 'setLabCompanySettings';
			static getCompanySettingsUrl = UserSettingsRoute.url + 'getLabCompanySettings';
			static areScannerSoftwareOptionsAvailableUrl = UserSettingsRoute.url + 'areScannerSoftwareOptionsAvailable';
			static getUserIdsPairedStatusUrl = UserSettingsRoute.url + 'isLoggedInDoctorIdsPaired';
			static getCompanySoftwareOptionIds = UserSettingsRoute.url + 'getCompanySoftwareOptionIds?companyId=';
			static setDoctor = (companyId: number, doctorId: number) => `${UserSettingsRoute.url}setDoctor?companyId=${companyId}&doctorId=${doctorId}`;
		};

		this.activityReport = class ActivityReportRoute {
			static url = EupRoutesService.mvcUrl + 'activityReport/';
			static getUrl = ActivityReportRoute.url + 'getActivities';
			static getAllActivities = ActivityReportRoute.url + 'getAllActivities';
		};

		this.shared = class UserSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'shared/';
			static getLanguages = UserSettingsRoute.url + 'getLanguages';
		};

		this.viewer = class ViewerRoute {
			static url = EupRoutesService.mvcUrl + 'viewer/';
			static logViewerLoadingTime = ViewerRoute.url + 'logViewerLoadingTime?milliseconds=';
			static canShowExport = ViewerRoute.url + 'canShowExport';
		};

		this.orderInformation = class ViewerRoute {
			static url = EupRoutesService.mvcUrl + 'orderInformationApi/';
			static configuration = ViewerRoute.url + 'OrderInformationConfiguration?companyId=';
		};

		this.orderConfiguration = class ViewerRoute {
			static getUrl = (companyId: string) =>
				`${EupRoutesService.serverUrl}rx/Configuration/${companyId}?isLabOrTechnician=false`;
		};
		this.accountManagement = class AccountManagementRoutes{
			static getPracticeUsersUrl = (companyId: string) => `${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts`;
			static updatePracticeUserUrl = (companyId: string) => `${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts`;
			static setPrimaryContactUrl = () => `${EupRoutesService.serverUrl}AccountManagement/SetPrimaryContact`;
			static deleteContactUrl = (companyId: string, contactId: string) => `${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts/${contactId}`;
			static getCompanyInfoUrl = (companyId: number) => `${EupRoutesService.mvcUrl}accountManagement/GetCompanyInfo?companyId=${companyId}`;
		}
	}

	private routeCanBeUsed(forceDataRetrieve: boolean = false): boolean {
		const routes = this.storage[Consts.Storage.Routes];
		const currentRegion = this.storage[Consts.Storage.RegionalApiEndpoint];
		const routesObj = routes ? JSON.parse(routes) as RoutingData : undefined;
		const regionChanged = routesObj && currentRegion && routesObj.serverUrl && currentRegion != routesObj.serverUrl;

		const res = !!routes && this.isValidJson(routes) && !forceDataRetrieve && !regionChanged;

		return res;
	}

	private isValidJson(jsonText: string): boolean {
		try {
			JSON.parse(jsonText);
		} catch (e) {
			return false;
		}
		return true;
	}
}

export class RoutingData {
	serverUrl: string;
	MATserverUrl: string;
	iTeroSiteUrl: string;
	orthoCadSetupDownloadUrl: string;
	iTeroSetupDownloadUrl: string;
	iOSimDownloadUrl: string;
	compatibilityTester: string;
	RTHFirstTimeInstaller: string;
	EvxFirstTimeInstaller: string;
	onlineOrthoViewerUrl: string;
	onlineRestorativeViewerUrl: string;
	rxUIUrl: string;
	alignUrl: string;
	privacyAndCookiesUrl: string;
	termsUrl: string;
	orthoCadSetupDownloadUrl_acs: string;
	iTeroSetupDownloadUrl_acs: string;
	iOSimDownloadUrl_acs: string;
	drawingToolUrl: string;
	notificationAgentUrl: string;
}
