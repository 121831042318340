﻿import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
/* tslint:disable-next-line */
import jstz from 'jstz';
import 'moment-timezone';
import { ScannerDetails } from './generalInterfaces';
import { Features, ScannerModel } from './enums';

@Injectable()
export class Utils {

	constructor(
		private translateService: TranslateService
	) { }

	public clearObservablesSubscriptions(obj: any): void {
		for (const propertyName in obj) {
			if (obj.hasOwnProperty(propertyName) && obj[propertyName] instanceof Subscription) {
				(obj[propertyName] as Subscription).unsubscribe();
			}
		}
	}

	public lowerCaseProps(obj: any) {
		const parsedObj: any = {};
		Object.keys(obj).forEach((k) => {
			parsedObj[k.toLowerCase()] = obj[k];
		});

		return parsedObj;
	}

	public formatString(source: string, ...params: any[]): string {
		return source.replace(/{(\d+)}/g, (match, num) => {
			return typeof params[num] !== 'undefined' ? params[num] : match;
		});
    }
	
	public existValueInEnum(type: any, value: any): boolean {
		return Object.keys(type).filter(k => isNaN(Number(k))).filter(k => type[k] === value).length > 0;
	}

    public translateKeyValueList(source, keyValueList) {
	    return keyValueList.map(i => ({ ...i, value: this.translateService.instant(`${source}.${i.key}`) }));
	}

	public compareVersions(version1: string, version2: string): number {
		if (!version1 || !version2){
			return -1;
		}
		const version1Parts = version1.split('.');
		const version2Parts = version2.split('.');
		const length = Math.min(version1Parts.length, version2Parts.length);

		for (let index = 0; index < length; index++) {
			const diff = parseInt(version1Parts[index], 10) - parseInt(version2Parts[index], 10);

			if (diff !== 0) {
				return diff;
			}
		}

		return version1.length - version2.length;
	}

	public getScannerModelBySwVersion(scanner: ScannerDetails): ScannerModel {
		if (!scanner.softwareVersion) {
			return null;
		}
		const softwareVersionMajor = +scanner.softwareVersion.charAt(0);
		let scannerModel = null;
		
		if (softwareVersionMajor === 1) {
			scannerModel = ScannerModel.element;
		} else if (softwareVersionMajor === 2) {
			scannerModel = ScannerModel.evx;
		} else {
			scannerModel = ScannerModel.other;
		}

		return scannerModel;
	}

	public isInternetExplorer(){
		return /*@cc_on!@*/false || !!document['documentMode'];
	}
}

export function formatDate(value: string, overrideFormat: string, settingsDateFormat: string): string {
	const defaultFormat = 'MM/DD/YYYY';
	const timezone = jstz.determine();

	const momentObject: any = moment(value);
	if (momentObject.isValid()) {
		let format = overrideFormat;
		if (!overrideFormat) {
			format = settingsDateFormat;
			if (!format) {
				format = defaultFormat;
			}
		}
		return momentObject.tz(timezone.name()).format(format);
	} else {
		return '';
	}	
}
