﻿// ngx toastr docs: https://www.npmjs.com/package/ngx-toastr
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EupToastService {

	constructor(private toastr: ToastrService) { }

	info(content: string, headline: string, options?) {
		this.toastr.info(content, headline, { ...options, positionClass: 'toast-bottom-left' });
    }

    error(content: string, headline: string, options?) {
        this.toastr.error(content, headline, { ...options, positionClass: 'toast-bottom-left' });
    }

}
