import { startsWith } from 'lodash';
import { ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { FeaturesToggleSettingsService } from '../service/featuresToggleSettings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var FeaturesToggleDirective = /** @class */ (function () {
    function FeaturesToggleDirective(templateRef, viewContainer, featuresToggleSettingsService) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.featuresToggleSettingsService = featuresToggleSettingsService;
        this.onDestroy$ = new Subject();
        this.allow = false;
    }
    Object.defineProperty(FeaturesToggleDirective.prototype, "featureToggle", {
        set: function (description) {
            var _this = this;
            this.triggerConditionChange(this.allow);
            this.featuresToggleSettingsService
                .getFeaturesToggleSettings()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(function () {
                var isNegative = startsWith(description, '!');
                var _description = isNegative ? description.substr(1) : description;
                var result = _this.featuresToggleSettingsService.isVisibleFeature(_description);
                _this.allow = isNegative ? !result : result;
                _this.triggerConditionChange(_this.allow);
            });
        },
        enumerable: true,
        configurable: true
    });
    FeaturesToggleDirective.prototype.triggerConditionChange = function (condition) {
        if (condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear();
        }
    };
    FeaturesToggleDirective.prototype.ngOnDestroy = function () {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    };
    return FeaturesToggleDirective;
}());
export { FeaturesToggleDirective };
