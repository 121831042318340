﻿import { Component, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ChangeCaseTypeService } from './changeCaseType.service';
import { KeyValuePair, ChangeCaseTypeEmitData } from '../generalInterfaces';
import { Utils } from '../utils.service';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
	selector: 'eup-changeCaseType',
	templateUrl: './changeCaseType.component.html',
	styleUrls: [ './changeCaseType.component.scss' ]
})
export class ChangeCaseTypeModal implements OnDestroy {
	@ViewChild('changeCaseTypeModal') modalContent: ModalDirective;
	availableCaseTypesListOptions: KeyValuePair[] = null;
	selectedCaseTypeOption = new KeyValuePair('', '');
	orderId: number;
	currentCaseTypeId: number;
	changeCaseTypeForOrderSubscription: Subscription;
	hideModal = new Subject<boolean>();
	@Output() change = new EventEmitter<ChangeCaseTypeEmitData>();

	constructor(private changeCaseTypeService: ChangeCaseTypeService, private utils: Utils) {}

	show(orderId: number, caseTypeId: number): Observable<boolean> {
		this.orderId = orderId;
		this.currentCaseTypeId = caseTypeId;
		this.changeCaseTypeService.getChangeCaseTypeOptions(this.orderId).subscribe((options: KeyValuePair[]) => {
			this.availableCaseTypesListOptions = options;
			this.selectedCaseTypeOption = options.filter((x) => +x.key === caseTypeId)[0];
			this.modalContent.show();
		});
		return this.hideModal.asObservable();
	}

	hide(shouldUpdate: boolean) {
		this.resetModel();
		this.hideModal.next(shouldUpdate);
		this.modalContent.hide();
	}

	getOptionByCaseTypeId(caseTypeId: number): KeyValuePair {
		return this.availableCaseTypesListOptions.filter((x) => +x.key === caseTypeId)[0];
	}

	updateSelectedCaseType(caseTypeId: number) {
		this.selectedCaseTypeOption = this.getOptionByCaseTypeId(caseTypeId);
	}

	changeCaseType() {
		if (this.currentCaseTypeId !== +this.selectedCaseTypeOption.key) {
			this.changeCaseTypeForOrderSubscription = this.changeCaseTypeService
				.changeCaseTypeForOrder(this.orderId, +this.selectedCaseTypeOption.key)
				.subscribe(() => {
					this.currentCaseTypeId = +this.selectedCaseTypeOption.key;
					this.change.emit({
						orderId: this.orderId,
						caseTypeDescription: this.getOptionByCaseTypeId(+this.selectedCaseTypeOption.key).value,
						caseTypeId: this.currentCaseTypeId
					} as ChangeCaseTypeEmitData);

					this.hide(true);
				});
		} else {
			this.hide(false);
		}
	}

	ngOnDestroy(): void {
		this.resetModel();
	}

	resetModel(): void {
		this.utils.clearObservablesSubscriptions(this);
		this.orderId = undefined;
		this.currentCaseTypeId = undefined;
		this.selectedCaseTypeOption = new KeyValuePair('', '');
		this.availableCaseTypesListOptions = undefined;
	}
}
