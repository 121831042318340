import { LogLevel, AppenderType } from '@itero/timber/dist/enums';
import { IConfig } from '@itero/timber/dist/interfaces/config';
import { IRemoteConfig } from '@itero/timber/dist/interfaces/config.remote';

export const loggerConfig: IConfig = {
	appId: 'myitero.com',
	minLogLevel: LogLevel.Debug,
	appenderTypes: [ AppenderType.Console ]
};

export const loggerConfigRemote: IRemoteConfig = {
	appId: 'myitero.com',
	minLogLevel: LogLevel.Debug,
	appenderTypes: [ AppenderType.Remote, AppenderType.ConsoleNative ],
	url: '',
	requiredFields: ['appId', 'module']
};
