import { Appearance } from '../shared/enums';
import { BehaviorSubject } from 'rxjs';
export var DEFAULT_THEME = Appearance.Dark;
var AppearanceService = /** @class */ (function () {
    function AppearanceService() {
        this.currTheme = DEFAULT_THEME;
        this.appearanceChangeSource = new BehaviorSubject(this.currTheme);
        this.appearanceChanged$ = this.appearanceChangeSource.asObservable().distinctUntilChanged();
    }
    Object.defineProperty(AppearanceService.prototype, "theme", {
        get: function () {
            return this.currTheme;
        },
        set: function (theme) {
            this.currTheme = theme;
            this.appearanceChangeSource.next(this.currTheme);
        },
        enumerable: true,
        configurable: true
    });
    AppearanceService.prototype.toTheme = function (theme) {
        this.theme = theme;
    };
    AppearanceService.prototype.toLightTheme = function () {
        this.theme = Appearance.Light;
    };
    AppearanceService.prototype.toDarkTheme = function () {
        this.theme = Appearance.Dark;
    };
    return AppearanceService;
}());
export { AppearanceService };
