import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PropertyBindingType } from '@angular/compiler';

@Component({
	selector: 'eup-generic-radioButton',
	templateUrl: './radioButton.component.html',
	styleUrls: ['./radioButton.component.scss']
})

export class RadioButtonComponent {
	private _checked: boolean;
	private _selectedValue = '';

	@Output()
	public radioButtonStateEmitter: EventEmitter<boolean> = new EventEmitter();
	@Input()
	public disabled: boolean;

	@Input()
	public itemLabel: string;

	@Input()
	public itemName: string;

	@Input()
	public value: any;

	@Input()
	public set checked(value: boolean) {
		this._checked = value;
		this.radioButtonStateEmitter.emit(value);
	}

	public get checked(): boolean {
		return this._checked;
	}
}
