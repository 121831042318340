var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NotificationService, ModalKeys, ModalIcon } from '../shared/notification/notification.service';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { TranslateService } from '@ngx-translate/core';
var DownloadFileService = /** @class */ (function () {
    function DownloadFileService(notificationService, httpService, translateService) {
        this.notificationService = notificationService;
        this.httpService = httpService;
        this.translateService = translateService;
    }
    DownloadFileService.prototype.getFile = function (url, options, fileName, triggerSpinner) {
        var _this = this;
        if (triggerSpinner === void 0) { triggerSpinner = true; }
        this.fileName = fileName;
        return this.httpService.get(url, __assign({}, options, { responseType: 'arraybuffer', withCredentials: true }), false, triggerSpinner)
            .map(function (response) { return _this.zipFileResponse(response); })
            .catch(function (err) { return _this.handleError(err); });
    };
    DownloadFileService.prototype.handleError = function (err) {
        this.notificationService.show(this.translateService.instant('Errors.Error_download_file_title'), this.translateService.instant('Errors.Error_download_file_body'), { buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error });
        return Observable.throw(err);
    };
    DownloadFileService.prototype.zipFileResponse = function (response) {
        this.saveAsZipFile(response, this.fileName);
        return response;
    };
    DownloadFileService.prototype.saveAsZipFile = function (buffer, fileName) {
        var data = new Blob([buffer], {
            type: 'application/x-zip-compressedapplication/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip'
        });
        FileSaver.saveAs(data, fileName + '.zip');
    };
    return DownloadFileService;
}());
export { DownloadFileService };
