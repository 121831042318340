var _a;
import { Features } from '../app/shared/enums';
export var addOrEditPatientPath = '/patient';
export var searchPatientPath = '/patient-search';
export var PracticeUserRoles = {
    ADMIN: "Admin",
    VISIBLE_TO_ALL_USERS: "VisibleToAllUsers",
    PRIMARY_CONTACT: "PrimaryContact"
};
export var featuresLimitedToMinScannerVersion = (_a = {},
    _a[Features.patientAppEnabledForMyItero] = { element: '1.11.0.0', evx: '2.6.0.0' },
    _a);
export var nonRedirectableRoutes = ['login', 'forbidden', 'pagenotfound', 'internalservererror', 'badrequest'];
