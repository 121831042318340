var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { tap, takeUntil, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { Subject } from 'rxjs';
import { loggerConfigRemote } from './config/logger.config';
import { AppConfigService } from '../appConfig/appConfigService';
import { AuthService } from '../authentication/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../appConfig/appConfigService";
import * as i2 from "../../core/globalSettings.service";
import * as i3 from "../authentication/auth.service";
var LoggerService = /** @class */ (function () {
    function LoggerService(appConfigService, globalSettingsService, authService) {
        this.appConfigService = appConfigService;
        this.globalSettingsService = globalSettingsService;
        this.authService = authService;
        this.onDestroy$ = new Subject();
        this.createLogger();
        this.trackUserChanges();
        this.trackCompanyChanges();
        this.trackLogOut();
    }
    LoggerService.prototype.trace = function (message, parameters) { this.timber.trace(message, parameters); };
    LoggerService.prototype.debug = function (message, parameters) { this.timber.debug(message, parameters); };
    LoggerService.prototype.info = function (message, parameters) { this.timber.info(message, parameters); };
    LoggerService.prototype.warn = function (message, parameters) { this.timber.warn(message, parameters); };
    LoggerService.prototype.error = function (message, parameters) { this.timber.error(message, parameters); };
    LoggerService.prototype.fatal = function (message, parameters) { this.timber.fatal(message, parameters); };
    LoggerService.prototype.ngOnDestroy = function () {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    };
    LoggerService.prototype.createLogger = function () {
        var globalSettings = this.globalSettingsService.get();
        var config = __assign({}, loggerConfigRemote, { url: this.appConfigService.appSettings.loggingEndpoint, userId: globalSettings ? globalSettings.contactId : undefined, companyId: globalSettings ? globalSettings.selectedCompanyId : undefined });
        this.timber = new Timber(config);
        console.log('Logger created with config:', config);
    };
    LoggerService.prototype.trackUserChanges = function () {
        var _this = this;
        this.globalSettingsService.contactIdChanged$
            .pipe(takeUntil(this.onDestroy$), tap(function (contactId) { return _this.timber.setConfig({ userId: contactId }); }), tap(function (contactId) { return _this.info("User has changed, contactId: " + contactId, { module: 'LoggerService' }); })).subscribe();
    };
    LoggerService.prototype.trackCompanyChanges = function () {
        var _this = this;
        this.globalSettingsService.contextChanged
            .pipe(takeUntil(this.onDestroy$), map(function (context) { return context.companyId; }), filter(function (companyId) { return !!companyId; }), distinctUntilChanged(), tap(function (companyId) { return _this.timber.setConfig({ companyId: companyId }); }), tap(function (companyId) { return _this.info("Company has changed, companyId: " + companyId, { module: 'LoggerService' }); })).subscribe();
    };
    LoggerService.prototype.trackLogOut = function () {
        var _this = this;
        this.authService.authStatus$
            .pipe(takeUntil(this.onDestroy$), filter(function (v) { return !v.isAuthenticated; }), tap(function () { return _this.timber.setConfig({ userId: undefined }); }), tap(function () { return _this.timber.setConfig({ companyId: undefined }); }), tap(function () { return _this.info("User logged out", { module: 'LoggerService' }); })).subscribe();
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.inject(i1.AppConfigService), i0.inject(i2.GlobalSettingsService), i0.inject(i3.AuthService)); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
