var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { GoogleAnalyticsService } from './googleAnalytics.service';
export var StatusCode;
(function (StatusCode) {
    StatusCode[StatusCode["Ok"] = 200] = "Ok";
    StatusCode[StatusCode["ContentCreated"] = 201] = "ContentCreated";
    StatusCode[StatusCode["NoContent"] = 204] = "NoContent";
    StatusCode[StatusCode["Redirect"] = 302] = "Redirect";
    StatusCode[StatusCode["BadRequest"] = 400] = "BadRequest";
    StatusCode[StatusCode["Unauthorized"] = 401] = "Unauthorized";
    StatusCode[StatusCode["Forbidden"] = 403] = "Forbidden";
    StatusCode[StatusCode["PageNotFount"] = 404] = "PageNotFount";
    StatusCode[StatusCode["InternalServerError"] = 500] = "InternalServerError";
})(StatusCode || (StatusCode = {}));
var EupHttpHandler = /** @class */ (function () {
    function EupHttpHandler(httpService, router, spinnerService, googleAnalyticsService) {
        this.httpService = httpService;
        this.router = router;
        this.spinnerService = spinnerService;
        this.googleAnalyticsService = googleAnalyticsService;
    }
    EupHttpHandler.prototype.post = function (url, body, options, navigateOnFailure, triggerSpinner) {
        var _this = this;
        if (navigateOnFailure === void 0) { navigateOnFailure = true; }
        if (triggerSpinner === void 0) { triggerSpinner = true; }
        if (triggerSpinner) {
            this.spinnerService.start();
        }
        var startingTime = new Date().getTime();
        return this.httpService
            .post(url, body, __assign({}, options, { withCredentials: true }))
            .do(function (res) { return _this.handleResponse(res, triggerSpinner, startingTime, url); })
            .catch(function (e) { return _this.handleError.call(_this, e, navigateOnFailure); });
    };
    EupHttpHandler.prototype.get = function (url, options, navigateOnFailure, triggerSpinner) {
        var _this = this;
        if (navigateOnFailure === void 0) { navigateOnFailure = true; }
        if (triggerSpinner === void 0) { triggerSpinner = true; }
        if (triggerSpinner) {
            this.spinnerService.start();
        }
        var startingTime = new Date().getTime();
        return this.httpService
            .get(url, __assign({}, options, { withCredentials: true }))
            .do(function (res) { return _this.handleResponse(res, triggerSpinner, startingTime, url); })
            .catch(function (e) { return _this.handleError.call(_this, e, navigateOnFailure); });
    };
    EupHttpHandler.prototype.delete = function (url, options, navigateOnFailure, triggerSpinner) {
        var _this = this;
        if (navigateOnFailure === void 0) { navigateOnFailure = true; }
        if (triggerSpinner === void 0) { triggerSpinner = true; }
        if (triggerSpinner) {
            this.spinnerService.start();
        }
        var startingTime = new Date().getTime();
        return this.httpService
            .delete(url, __assign({}, options, { withCredentials: true }))
            .do(function (res) { return _this.handleResponse(res, triggerSpinner, startingTime, url); })
            .catch(function (e) { return _this.handleError.call(_this, e, navigateOnFailure); });
    };
    EupHttpHandler.prototype.patch = function (url, body, options, navigateOnFailure, triggerSpinner) {
        var _this = this;
        if (navigateOnFailure === void 0) { navigateOnFailure = true; }
        if (triggerSpinner === void 0) { triggerSpinner = true; }
        if (triggerSpinner) {
            this.spinnerService.start();
        }
        var startingTime = new Date().getTime();
        return this.httpService
            .patch(url, body, __assign({}, options, { withCredentials: true }))
            .do(function (res) { return _this.handleResponse(res, triggerSpinner, startingTime, url); })
            .catch(function (e) { return _this.handleError.call(_this, e, navigateOnFailure); });
    };
    EupHttpHandler.prototype.put = function (url, body, options) {
        var _this = this;
        var startingTime = new Date().getTime();
        return this.httpService
            .put(url, body, __assign({}, options, { withCredentials: true }))
            .do(function (res) { return _this.handleResponse(res, false, startingTime, url); })
            .catch(function (e) { return _this.handleError.call(_this, e, false); });
    };
    EupHttpHandler.prototype.handleResponse = function (res, triggerSpinner, startingTime, url) {
        if (triggerSpinner) {
            this.spinnerService.stop();
        }
        // calculate timing
        if (startingTime && url) {
            var timeElapsed = new Date().getTime() - startingTime;
            this.googleAnalyticsService.hitActionTiming(this.router.url, url, timeElapsed);
        }
        if (res && res.headers) {
            var redirectToUrl = res.headers.get('RedirectRoute');
            var externalUrl = res.headers.get('ExternalRedirect');
            if (redirectToUrl) {
                this.router.navigate([redirectToUrl.toLowerCase()]);
            }
            if (externalUrl) {
                window.location.href = externalUrl;
            }
        }
    };
    EupHttpHandler.prototype.handleError = function (err, navigateOnFailure) {
        this.spinnerService.stop();
        var status = err.status;
        this.googleAnalyticsService.hitException(err.statusText + ' @ ' + err.url, true);
        if (navigateOnFailure) {
            switch (status) {
                case StatusCode.BadRequest:
                    this.router.navigate(['/badrequest']);
                    break;
                case StatusCode.Unauthorized:
                    // if we are coming from login page do nothing
                    if (this.router.url.indexOf('login') === -1) {
                        var currentUrl = this.router.url;
                        var params = this.router.parseUrl(currentUrl).queryParams;
                        var returnUrlParams = params['returnUrl'] !== undefined
                            ? { queryParams: params }
                            : { queryParams: { returnUrl: currentUrl } };
                        // this.globalSettingsService.clear();
                        this.router.navigate(['/login'], returnUrlParams);
                    }
                    break;
                case StatusCode.Forbidden:
                    this.router.navigate(['/forbidden']);
                    break;
                case StatusCode.PageNotFount:
                    this.router.navigate(['/pagenotfound']);
                    break;
                case StatusCode.Redirect:
                    this.router.navigate([err.headers.get('RedirectRoute').toLowerCase()]);
                    break;
                case StatusCode.InternalServerError:
                default:
                    // In future these errors will be handled by the errorHandler service and we'll remove this line
                    this.router.navigate(['/internalservererror']);
                    break;
            }
        }
        return Observable.throw(err);
    };
    return EupHttpHandler;
}());
export { EupHttpHandler };
