import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Injectable()
export class EupObserver {
	private subject = new Subject();

	public subscribe(observer): Subscription {
		return this.subject.subscribe(observer);
	}

	public emit(value): void {
		this.subject.next(value);
	}
}
