var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { FileInfoStatus } from './fileInfo.model';
import { NanobarService } from '../../core/nanobar.service';
import { RxService } from '../../rx/rx.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { RoleTypeEnum } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
var DownloadNotificationService = /** @class */ (function () {
    function DownloadNotificationService(http, eupRoutesService, nanobarService, rxService, globalSettings, eupObserver) {
        this.http = http;
        this.eupRoutesService = eupRoutesService;
        this.nanobarService = nanobarService;
        this.rxService = rxService;
        this.globalSettings = globalSettings;
        this.eupObserver = eupObserver;
        this.files = [];
        this.interval = 5 * 1000;
    }
    DownloadNotificationService.prototype.getObservable = function () {
        if (this.observable1$ === undefined) {
            this.observable1$ = this.createObservable1();
            this.subscription = this.createObservable2().subscribe();
        }
        return this.observable1$;
    };
    DownloadNotificationService.prototype.createObservable1 = function () {
        return Observable.of(this.files);
    };
    DownloadNotificationService.prototype.createObservable2 = function () {
        var _this = this;
        return Observable.timer(0, this.interval)
            .flatMap(function () {
            var parallelWorkOrderIds = _this.getParallelWorkOrderIds();
            // Check if no need to send request to server
            if (parallelWorkOrderIds.length === 0) {
                return Observable.of([]);
            }
            var payload = { parallelWorkOrderIds: parallelWorkOrderIds };
            return _this.http.post(_this.eupRoutesService.orders.getExportStatusBulk, payload, undefined, false, false);
        })
            .map(function (files) {
            var result = files.reduce(function (acc, file) {
                var _a;
                return (__assign({}, acc, (_a = {}, _a[file.parallelWorkOrderId] = file, _a)));
            }, {});
            // update the files
            _this.files.forEach(function (file, index, array) {
                var id = file.parallelWorkOrderId;
                array[index] = __assign({}, array[index], result[id]);
            });
            // remove only one file which is ready in givin time
            var idx = _this.files.findIndex(function (file) { return file.status === FileInfoStatus.Completed && file.downloaded === false; });
            if (idx > -1) {
                _this.downloadFile(_this.files[idx]);
            }
            return _this.files;
        });
    };
    DownloadNotificationService.prototype.getParallelWorkOrderIds = function () {
        var result = this.files.filter(function (file) { return file.status !== FileInfoStatus.Failed && file.status !== FileInfoStatus.Canceled; });
        return result.map(function (file) { return file.parallelWorkOrderId; });
    };
    DownloadNotificationService.prototype.downloadFile = function (file) {
        var _this = this;
        this.nanobarService.start();
        var url = file.workOrderId && file.workOrderId > 0
            ? this.eupRoutesService.orders.getStlFileByWorkOrder
            : this.eupRoutesService.orders.getStlFile;
        var parallelWorkOrderId = file.parallelWorkOrderId, orderId = file.orderId, orderDetailsId = file.orderDetailsId;
        file.downloaded = true;
        var queryParams = file.workOrderId && file.workOrderId > 0
            ? url + "?workOrderId=" + file.workOrderId + "&orderId=" + orderId + "&orderDetailsId=" + orderDetailsId
            : url + "?parallelWorkOrderId=" + parallelWorkOrderId + "&orderId=" + orderId + "&orderDetailsId=" + orderDetailsId;
        this.downloadFileFromUrl(queryParams);
        this.nanobarService.stop();
        if (!file.workOrderId) {
            var index = this.files.findIndex(function (f) { return f.parallelWorkOrderId === file.parallelWorkOrderId; });
            this.files.splice(index, 1);
        }
        var settings = this.globalSettings.get();
        if (settings.roleType === RoleTypeEnum.Lab) {
            this.rxService.setReadyForDownloadAsDownloadedForLab(file.orderId).subscribe(function () {
                _this.eupObserver.emit({
                    action: AT.DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED,
                    payload: null
                });
            });
        }
    };
    DownloadNotificationService.prototype.downloadFileFromUrl = function (url) {
        var iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.display = 'none';
        iframe.tabIndex = -1;
        document.body.appendChild(iframe);
        setTimeout(function () { document.body.removeChild(iframe); }, 100000);
    };
    DownloadNotificationService.prototype.addFile = function (parallelWorkOrderId, order) {
        var req = {
            parallelWorkOrderId: parallelWorkOrderId,
            orderId: order.orderId,
            orderDetailsId: 0,
            progress: 0,
            creationTime: Date.now(),
            downloaded: false,
            requestData: order,
            status: FileInfoStatus.Active
        };
        var idx = this.files.findIndex(function (file) { return file.parallelWorkOrderId === parallelWorkOrderId; });
        if (idx === -1) {
            this.files.push(req);
        }
    };
    DownloadNotificationService.prototype.removeFile = function (orderId) {
        var index = this.files.findIndex(function (file) { return file.orderId === orderId; });
        this.files.splice(index, 1);
    };
    DownloadNotificationService.prototype.clear = function () {
        this.observable1$ = undefined;
        this.subscription.unsubscribe();
        this.files.splice(0, this.files.length);
    };
    return DownloadNotificationService;
}());
export { DownloadNotificationService };
