import { Compiler } from '@angular/core';
import { Router, NavigationStart, NavigationError, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { TitleService } from './title.service';
import { GoogleAnalyticsService } from './googleAnalytics.service';
import { AppearanceService, DEFAULT_THEME } from './appearance.service';
var RouterInterceptorService = /** @class */ (function () {
    function RouterInterceptorService(router, compiler, spinner, titleService, activatedRoute, googleAnalyticsService, appearanceService) {
        this.router = router;
        this.compiler = compiler;
        this.spinner = spinner;
        this.titleService = titleService;
        this.activatedRoute = activatedRoute;
        this.googleAnalyticsService = googleAnalyticsService;
        this.appearanceService = appearanceService;
    }
    Object.defineProperty(RouterInterceptorService.prototype, "previousRoute", {
        get: function () {
            return this.prevUrl;
        },
        enumerable: true,
        configurable: true
    });
    RouterInterceptorService.prototype.initialize = function () {
        var _this = this;
        this.router.events.subscribe(function (val) {
            if (val instanceof NavigationStart) {
                _this.handleNavigationStart(val);
            }
            if (val instanceof NavigationEnd) {
                _this.handleNavigationEnd(val);
            }
            if (val instanceof NavigationError) {
                _this.handleNavigationError(val);
            }
        });
    };
    RouterInterceptorService.prototype.handleNavigationStart = function (event) {
        this.prevUrl = this.router.url;
        this.navigationStartTime = new Date().getTime();
        this.spinner.start(true);
    };
    RouterInterceptorService.prototype.handleNavigationEnd = function (event) {
        var _this = this;
        this.spinner.stop();
        this.googleAnalyticsService.hitPageView(event.urlAfterRedirects);
        // calculate timing
        if (this.navigationStartTime) {
            var timeElapsed = new Date().getTime() - this.navigationStartTime;
            this.googleAnalyticsService.hitPageTiming(event.urlAfterRedirects, timeElapsed);
        }
        var route = this.activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }
        route.data.subscribe(function (data) {
            if (!data['skipTitle']) {
                _this.titleService.setTitle(data['title']);
            }
            var theme = data['theme'] || DEFAULT_THEME;
            _this.appearanceService.toTheme(theme);
        });
    };
    // if any error happens during changing of routes (session expired / exception)
    // then redirect to login page - angular2 doesn't supply the http status of the response
    // so we can't act accordingly
    RouterInterceptorService.prototype.handleNavigationError = function (event) {
        this.spinner.stop();
        // angular cache failed component requests so we have to clear the cache
        // otherwise next time angular will request a component that failed to load
        // will throw exception without even making a request to the server
        this.compiler.clearCache();
        // error in login page - reload current page (angular2 doesn't support reloading components yet)
        if (event.url === '/home' && this.prevUrl === '/login') {
            window.location.reload();
        }
        else {
            // any other page
            this.router.navigate(['/login']);
            // *** Error handling code to restore ***
            // TODO restore these lines (and delete above line) when ERROR handling is re-implemented
            // this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            //     this.router.navigate([this.prevUrl]);
            //     throw(`Cannot navigate to ${event.url} - Unexpected Error`);
            // });
            // ***
        }
    };
    return RouterInterceptorService;
}());
export { RouterInterceptorService };
