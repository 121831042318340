import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EupHttpHandler } from './eupHttpHandler.service';
import { EupRoutesService } from './eupRoutes.service';
import { GlobalSettingsService } from './globalSettings.service';
import { LocalStorageService } from './localStorage.service';
import { SpinnerService } from './spinner/spinner.service';
import { RouterInterceptorService } from './routerInterceptor.service';
import { GoogleAnalyticsService } from './googleAnalytics.service';
import { PlatformDetectorService } from './platformDetector.service';
import { TitleService } from './title.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppearanceService } from './appearance.service';
import { PasswordPolicyGuard } from './passwordPolicy/passwordPolicyGuard.service';
import { PasswordPolicyPopupComponent } from './passwordPolicy/passwordPolicyPopup.component';
import { SharedModule } from '../shared/shared.module';
import { DomService } from './dom.service';
import { ExcelService } from './excel.service';
import { RolesGuard } from './roles/rolesGuard.service';
import { AuthGuard } from './auth.guard';
import { RootGuard } from './root.guard';
import { EupObserver } from './eupObserver.service';

import * as translation from '../../translationVersion.json';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/translations/', `.${translation.translationVersion}.json`);
}

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [ HttpClient ]
			}
		})
	],
	declarations: [ SpinnerComponent, PasswordPolicyPopupComponent ],
	providers: [
		EupHttpHandler,
		EupRoutesService,
		GlobalSettingsService,
		LocalStorageService,
		SpinnerService,
		RouterInterceptorService,
		PlatformDetectorService,
		TitleService,
		GoogleAnalyticsService,
		PasswordPolicyGuard,
		DomService,
		ExcelService,
		RolesGuard,
		AppearanceService,
		AuthGuard,
		RootGuard,
		EupObserver
	],
	exports: [ SpinnerComponent ],
	entryComponents: [ PasswordPolicyPopupComponent ]
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}
