import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { HttpParams } from '@angular/common/http';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { MessagesService } from '../../messages/messages.service';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { LabCasesState, WindowsApplicationProtocol } from '../enums';
import { RxService } from '../../rx/rx.service';
import { OrdersService, Order } from '../../doctors/orders/orders.service';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { WindowApplicationLinkData } from '../../shared/windowsApplicationLinkData';
import { TranslateService } from '@ngx-translate/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { LabExportModal } from '../labExport/labExport.component';
import { StorageType } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { IteroLabService } from '../../shared/iteroLab.service';
import { AccessibilityService } from '../accessibility.service';
import { Consts } from '@shared/consts';
import { AuthService } from 'app/services/authentication/auth.service';
import { LocalStorageService } from '@core/localStorage.service';
var LabStickyHeaderComponent = /** @class */ (function () {
    function LabStickyHeaderComponent(router, http, globalSettingsService, utils, routerInterceptor, eupRoutesService, route, messagesService, downloadNotificationService, rxService, orderService, translateService, eupObserver, iteroLabService, accessibilityService, authService, localStorageService) {
        var _this = this;
        this.router = router;
        this.http = http;
        this.globalSettingsService = globalSettingsService;
        this.utils = utils;
        this.routerInterceptor = routerInterceptor;
        this.eupRoutesService = eupRoutesService;
        this.route = route;
        this.messagesService = messagesService;
        this.downloadNotificationService = downloadNotificationService;
        this.rxService = rxService;
        this.orderService = orderService;
        this.translateService = translateService;
        this.eupObserver = eupObserver;
        this.iteroLabService = iteroLabService;
        this.accessibilityService = accessibilityService;
        this.authService = authService;
        this.messagesCount = 0;
        this.backImage = 'backButton46';
        this.labCasesState = undefined;
        this.isLogoutOpen = false;
        this.isLinksMenuOpen = false;
        this.disableDownloadScan = false;
        this.disableActionButtons = true;
        this.lastBowId = null;
        this.lastDetailsId = null;
        this.faExternalLinkAlt = faExternalLinkAlt;
        this.titleText = '';
        this.image = LabStickyHeaderComponent.homeImage;
        this.backLink = LabStickyHeaderComponent.homeLink;
        this.backToLastPage = null;
        this.showAvatarContainer = 'true';
        this.showLinksContainer = 'true';
        this.showActionButtons = 'false';
        this.disableSiteNavigation = 'false';
        this.isBackLinkAbsoluteUrl = 'false';
        this.shouldAddRoleToBackLink = 'true';
        this.onPrintClicked = function () {
            _this.rxService.printOrdersRx(_this.orderId.toString()).subscribe();
        };
        this.onOpenWithiTeroLabClick = function () {
            if (_this.iteroLabService.isNeedToSendToLab(_this.shortOrderData)) {
                _this.iteroLabService.prepareOpenApp(_this.orderId, _this.lastDetailsId).subscribe(function (url) {
                    _this.onWindowsAppClick(url);
                    _this.orderService.updateLabOrderById(_this.orderId.toString(), _this.globalSettingsService.get().selectedDoctorId.toString(), _this.globalSettingsService.get().selectedCompanyId.toString(), function (order) {
                        // optimistic UI issue
                        order.canReturnToDoctor = false;
                    });
                });
            }
            else {
                _this.onWindowsAppClick();
            }
        };
        this.onExportClick = function () {
            var orderId = _this.orderId + '';
            var doctorId = _this.globalSettingsService.get().selectedDoctorId + '';
            var companyId = _this.globalSettingsService.get().selectedCompanyId + '';
            var params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
            _this.http.get(_this.eupRoutesService.lab.getOrderByIdUrl, { params: params }).subscribe(function (order) {
                _this.labExportModal.show([order]);
            });
        };
        this.onPrintUrlChange();
        this.orderUpdatedEventSubscription = this.orderService.OrderUpdated.subscribe(function (order) {
            _this.updateMembersByOrder(order);
        });
        this.storage = localStorageService.storage;
    }
    LabStickyHeaderComponent.prototype.onPrintUrlChange = function () {
        var _this = this;
        this.rxService.printUrlObservable.subscribe(function (printUrl) {
            _this.printUrl = printUrl;
        });
    };
    Object.defineProperty(LabStickyHeaderComponent.prototype, "windowsApplicationText", {
        get: function () {
            var _this = this;
            this.route.queryParams.forEach(function (queryParams) {
                if (queryParams['winappdata']) {
                    _this.isOrthoCase = queryParams['isOrtho'] === 'true';
                }
            });
            return this.isOrthoCase
                ? this.translateService.instant('Lab.OrthoCAD')
                : this.translateService.instant('Lab.iTeroLab');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabStickyHeaderComponent.prototype, "username", {
        get: function () {
            return (this.settings) ? this.settings.username : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabStickyHeaderComponent.prototype, "disableSiteNavigationFunc", {
        get: function () {
            return this.disableSiteNavigation === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabStickyHeaderComponent.prototype, "isBackLinkAbsoluteUrlFunc", {
        get: function () {
            return this.isBackLinkAbsoluteUrl === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabStickyHeaderComponent.prototype, "shouldAddRoleToBackLinkFunc", {
        get: function () {
            return this.shouldAddRoleToBackLink === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LabStickyHeaderComponent.prototype, "settings", {
        get: function () {
            return this.globalSettingsService.get();
        },
        enumerable: true,
        configurable: true
    });
    LabStickyHeaderComponent.prototype.toggleLogoutMenu = function () {
        this.isLogoutOpen = !this.isLogoutOpen;
    };
    LabStickyHeaderComponent.prototype.hideLogoutMenu = function () {
        this.isLogoutOpen = false;
    };
    LabStickyHeaderComponent.prototype.toggleLinksMenu = function () {
        this.isLinksMenuOpen = !this.isLinksMenuOpen;
    };
    LabStickyHeaderComponent.prototype.hideLinksMenu = function () {
        this.isLinksMenuOpen = false;
    };
    LabStickyHeaderComponent.prototype.isIteroLab = function () {
        return !this.isOrthoCase;
    };
    LabStickyHeaderComponent.prototype.ngOnDestroy = function () {
        this.utils.clearObservablesSubscriptions(this);
        this.eupObserverSubscription.unsubscribe();
    };
    LabStickyHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
        this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
        this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
        this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;
        this.printUrl = null;
        this.rolePath = this.globalSettingsService.rolePath();
        if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
            this.backLink = this.rolePath + '/' + this.backLink;
        }
        this.contextChangedSubscription = this.globalSettingsService.contextChanged.subscribe(function (params) {
            if (!params.companyId || !params.doctorId) {
                return;
            }
        });
        // as currently supported only 1 company per personnel
        this.redirectIfRequired(this.settings.companies[0]);
        // when this property is set to true - override image and back link
        // according to previous url
        if (this.backToLastPage === 'true') {
            var previousRoute = this.routerInterceptor.previousRoute;
            if (previousRoute === '/') {
                previousRoute = previousRoute + this.backLink;
            }
            // if no previous url exists (e.g. user entered url in a new tab)
            // or the previous url is home so back button should link to home page
            // or user was redirected to a page from straight from login
            if (!previousRoute || previousRoute === '/login') {
                this.setDefaultLinkAndImage();
            }
            else {
                // a previous url exists and it is not the home page so back button should link
                // to the last page that the user was at
                this.backLink = previousRoute.slice(1);
                this.image = this.backImage;
            }
        }
        else if (this.backToLastPage === 'false') {
            this.setDefaultLinkAndImage();
        }
        this.route.params.forEach(function (routeParams) {
            _this.labCasesState = routeParams['labCasesState'];
        });
        this.route.queryParams.forEach(function (queryParams) {
            if (queryParams['winappdata']) {
                var isOrtho_1 = queryParams['isOrtho'] === 'true', name_1 = isOrtho_1
                    ? _this.translateService.instant('Orders.OpenWithOrthoCAD')
                    : _this.translateService.instant('Orders.OpenWithiTero');
                _this.calculateDownloadLink(isOrtho_1, queryParams['orderId']).subscribe(function (downloadLink) {
                    _this.isOrthoCase = isOrtho_1;
                    _this.winAppData = new WindowApplicationLinkData(queryParams['winappdata'], name_1, downloadLink, queryParams['isOrtho'] === 'true'
                        ? WindowsApplicationProtocol.IteroLab
                        : WindowsApplicationProtocol.OrthoCad);
                });
            }
            if (_this.orderService.orderObservable$ !== undefined) {
                _this.orderService.orderObservable$.subscribe(function (order) {
                    _this.updateMembersByOrder(order);
                });
            }
        });
        this.messagesService.getMessagesCount().subscribe(function (res) {
            _this.messagesCount = res;
        });
        var observer = {
            next: function (value) {
                switch (true) {
                    case value.action === AT.WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING:
                    case value.action === AT.RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS:
                        _this.disableDownloadScan = true;
                        break;
                    default:
                        break;
                }
            }
        };
        this.eupObserverSubscription = this.eupObserver.subscribe(observer);
    };
    LabStickyHeaderComponent.prototype.redirectIfRequired = function (company) {
        var _this = this;
        if (!company.handlingEupUrl) {
            return;
        }
        if (company.handlingEupUrl.endsWith('/')) {
            company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
        }
        var myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
        var companyMyIteroBffUrl = new URL(company.handlingEupUrl);
        var currentPath = this.accessibilityService.getPathName();
        var myiteroComUrl = new URL(this.accessibilityService.getLocation().origin + "/login?returnUrl=" + currentPath);
        var allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
        if (allowRedirection) {
            this.http.post(this.eupRoutesService.lab.logout).subscribe(function () {
                _this.downloadNotificationService.clear();
                _this.globalSettingsService.clear();
                _this.storage[Consts.Storage.RegionalApiEndpoint] = companyMyIteroBffUrl.origin + "/";
                _this.accessibilityService.redirectTo(myiteroComUrl.href);
            });
            return;
        }
    };
    LabStickyHeaderComponent.prototype.calculateDownloadLink = function (isOrtho, orderId) {
        var _a;
        var oldLink = isOrtho ? this.orthoCadSetupDownloadUrl : this.iTeroSetupDownloadUrl;
        var newLink = isOrtho ? this.orthoCadSetupDownloadUrl_acs : this.iTeroSetupDownloadUrl_acs;
        if (!orderId || +orderId <= 0) {
            return Observable.of(oldLink);
        }
        var typesMap = (_a = {},
            _a[StorageType.ACS] = newLink,
            _a[StorageType.OriginalFMS] = oldLink,
            _a);
        return this.orderService.getFileStorage(+orderId).map(function (storage) { return typesMap[storage]; });
    };
    LabStickyHeaderComponent.prototype.logout = function () {
        var _this = this;
        this.http.post(this.eupRoutesService.lab.logout).subscribe(function () {
            _this.downloadNotificationService.clear();
            _this.authService.logout();
        });
    };
    LabStickyHeaderComponent.prototype.updateMembersByOrder = function (order) {
        if (order && this.showActionButtons) {
            this.disableActionButtons = false;
            this.disableDownloadScan = !order.canExportFile;
            this.lastBowId = order.lastBowId;
            this.lastDetailsId = order.lastDetailsId;
        }
    };
    LabStickyHeaderComponent.prototype.onImageLinkClick = function () {
        var _this = this;
        if (this.uponHomeLinkClickCallback && this.homeLinkClickEvent) {
            if (!this.homeLinkClickSubscription) {
                this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe(function (res) {
                    if (_this.labCasesState) {
                        if (_this.backLink.toLowerCase().indexOf('home') !== -1 &&
                            _this.backLink.toLowerCase().indexOf('q=') !== -1) {
                            _this.router.navigateByUrl("/" + _this.backLink.toLowerCase());
                        }
                        else {
                            if (_this.backLink.toLowerCase().indexOf('home') !== -1 &&
                                _this.backLink.toLowerCase().indexOf('labcasesstate') !== -1) {
                                _this.backLink = _this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
                            }
                            _this.router.navigate(["/" + _this.backLink.toLowerCase()], {
                                queryParams: { labCasesState: _this.labCasesState }
                            });
                        }
                    }
                    else {
                        _this.router.navigateByUrl("/" + _this.backLink.toLowerCase());
                    }
                });
            }
            this.uponHomeLinkClickCallback();
        }
        else {
            if (this.isBackLinkAbsoluteUrlFunc) {
                window.location.href = this.backLink;
            }
            else {
                if (this.labCasesState) {
                    this.router.navigate(["/" + this.backLink.toLowerCase()], {
                        queryParams: { labCasesState: this.labCasesState }
                    });
                }
                else {
                    this.router.navigateByUrl("/" + this.backLink.toLowerCase());
                }
            }
        }
    };
    LabStickyHeaderComponent.prototype.onWindowsAppClick = function (link) {
        if (link === void 0) { link = null; }
        this.orderService.startProtocol(link ? link : this.winAppData.link, true);
        this.downloadLinkModal.show(this.winAppData.name, this.winAppData.downloadLink);
    };
    LabStickyHeaderComponent.prototype.setDefaultLinkAndImage = function () {
        this.backLink = this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
        this.image = LabStickyHeaderComponent.homeImage;
    };
    LabStickyHeaderComponent.homeImage = 'home46';
    LabStickyHeaderComponent.homeLink = 'home';
    return LabStickyHeaderComponent;
}());
export { LabStickyHeaderComponent };
