/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./patientApp.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./patientApp.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_PatientAppModalComponent = [i0.styles];
var RenderType_PatientAppModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PatientAppModalComponent, data: {} });
export { RenderType_PatientAppModalComponent as RenderType_PatientAppModalComponent };
export function View_PatientAppModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["class", "windowClass"], ["frameBorder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.patientAppUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_PatientAppModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eup-patient-app", [], null, null, null, View_PatientAppModalComponent_0, RenderType_PatientAppModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.PatientAppModalComponent, [i3.BsModalRef], null, null)], null, null); }
var PatientAppModalComponentNgFactory = i1.ɵccf("eup-patient-app", i2.PatientAppModalComponent, View_PatientAppModalComponent_Host_0, {}, {}, []);
export { PatientAppModalComponentNgFactory as PatientAppModalComponentNgFactory };
