var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { BehaviorSubject, from, Subject, of, interval, iif } from 'rxjs';
import { SignalRService } from '../SignalR/signalR.service';
import { map, tap, takeUntil, single, delayWhen, mergeMap, catchError } from 'rxjs/operators';
import { LoggerService } from '../logging/logger.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "../SignalR/signalR.service";
import * as i2 from "../logging/logger.service";
import * as i3 from "@ngx-translate/core";
var DesktopNotificationsService = /** @class */ (function () {
    function DesktopNotificationsService(signalRService, loggerService, translateService) {
        this.signalRService = signalRService;
        this.loggerService = loggerService;
        this.translateService = translateService;
        this.isDesktopNotificationPermitted$ = new BehaviorSubject(false);
        this.allNotifications = [];
        this.stopNotifications$ = new Subject();
        this.publishNotificationPermission();
    }
    DesktopNotificationsService.prototype.closeNotification = function (noti) {
        noti.close();
    };
    DesktopNotificationsService.prototype.closeAllNotifications = function () {
        var _this = this;
        this.allNotifications.forEach(function (notificationItem) { return _this.closeNotification(notificationItem); });
    };
    DesktopNotificationsService.prototype.requestUserPermission = function () {
        var _this = this;
        return from(this.checkIfPermissionIsPromise())
            .pipe(single(), tap(function (permission) { return console.log('permission requested, permission: ', permission); }), map(function (permission) { return permission === 'granted'; }), tap(function (isPermitted) {
            _this.isNotificationPermitted = isPermitted;
            _this.isDesktopNotificationPermitted$.next(isPermitted);
            console.log('permission requested, isNotificationPermitted: ', isPermitted);
        }));
    };
    DesktopNotificationsService.prototype.initializeNotifications = function (notificationHubUrl, jwtAccessToken) {
        var _this = this;
        var notPermittedFlow$ = of({}).pipe(tap(function (_) {
            _this.loggerService.warn('User did not permit notifications. Connection to signalR Service cannot be established', { module: 'DesktopNotificationsService' });
        }));
        var permittedFlow$ = of({}).pipe(tap(function (_) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.signalRService.initMessages(notificationHubUrl, jwtAccessToken)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); }), mergeMap(function (_) { return _this.signalRService.registerToMessages(); }), delayWhen(function (_) { return iif(function () { return !!window['chrome']; }, of(undefined), interval(Math.floor((Math.random() * 10) + 1) * 10)); }), takeUntil(this.stopNotifications$), map(function (order) { return _this.prepareDesktopNotification(order); }), tap(function (notification) { return _this.showNotification(notification); }));
        this.requestUserPermission().pipe(mergeMap(function (isPermitted) { return iif(function () { return isPermitted; }, permittedFlow$, notPermittedFlow$); }), catchError(function (error) {
            _this.loggerService.error('error while processing notification', { module: 'DesktopNotificationsService', error: error });
            return of(error);
        })).subscribe();
    };
    DesktopNotificationsService.prototype.stopNotifications = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.stopNotifications$.next();
                        return [4 /*yield*/, this.signalRService.closeConnection()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesktopNotificationsService.prototype.ngOnDestroy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.stopNotifications()];
                    case 1:
                        _a.sent();
                        this.stopNotifications$.complete();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesktopNotificationsService.prototype.publishNotificationPermission = function () {
        this.isNotificationPermitted = Notification['permission'] === 'granted';
        this.isDesktopNotificationPermitted$.next(this.isNotificationPermitted);
    };
    DesktopNotificationsService.prototype.prepareDesktopNotification = function (order) {
        this.loggerService.debug("TraceId: " + order.traceId + ", message received.", { module: 'DesktopNotificationsService' });
        var translatedTitle = this.translateService.instant('Lab.notificationTitle');
        var translatedBody = this.translateService.instant('Lab.notificationsBody', { orderId: "" + order.orderId });
        return {
            title: translatedTitle,
            body: translatedBody,
            icon: '/assets/images/iteroLogo.png',
            redirectUrl: this.buildNotificationsHandlerUrl(order),
            tag: order.hashCode.toString()
        };
    };
    DesktopNotificationsService.prototype.buildNotificationsHandlerUrl = function (orderInfo) {
        // NOTE: HttpParams recieves object only with string fields.
        var orderInfoQueryObject = {
            order: JSON.stringify(orderInfo)
        };
        var queryParams = new HttpParams({
            fromObject: orderInfoQueryObject
        });
        return "/notifications/orders?" + queryParams.toString();
    };
    DesktopNotificationsService.prototype.showNotification = function (desktopNotification) {
        if (!this.isNotificationPermitted) {
            return;
        }
        var newDesktopNotification = new Notification(desktopNotification.title, {
            body: desktopNotification.body,
            icon: desktopNotification.icon,
            tag: desktopNotification.tag,
            renotify: true
        });
        var notificationClickHandler = function (event) {
            window.open(desktopNotification.redirectUrl);
            newDesktopNotification.removeEventListener('click', notificationClickHandler);
        };
        newDesktopNotification.addEventListener('click', notificationClickHandler);
        this.allNotifications.push(newDesktopNotification);
        return newDesktopNotification;
    };
    DesktopNotificationsService.prototype.checkIfPermissionIsPromise = function () {
        // Safari doesn't support notifications permission as a promise
        var requestNotiPermission = Notification.requestPermission();
        return (requestNotiPermission) ? requestNotiPermission : new Promise(function (resolve) { return Notification.requestPermission(function (perm) { return resolve(perm); }); });
    };
    DesktopNotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function DesktopNotificationsService_Factory() { return new DesktopNotificationsService(i0.inject(i1.SignalRService), i0.inject(i2.LoggerService), i0.inject(i3.TranslateService)); }, token: DesktopNotificationsService, providedIn: "root" });
    return DesktopNotificationsService;
}());
export { DesktopNotificationsService };
