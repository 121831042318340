import { Component, Output, EventEmitter } from '@angular/core';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { Observable } from 'rxjs';
import { FileInfo, FileInfoStatus } from '../downloadNotification/fileInfo.model';

@Component({
	selector: 'eup-export-downloads-progress-list',
	templateUrl: './exportDownloadsProgressList.component.html',
	styleUrls: [ './exportDownloadsProgressList.component.scss' ]
})
export class ExportDownloadsProgressList {
	Status = FileInfoStatus;
	files$: Observable<FileInfo[]>;
	@Output() close: EventEmitter<any> = new EventEmitter();

	constructor(private notification: DownloadNotificationService) {
		this.files$ = notification.getObservable();
	}

	cancel(orderId: number) {
		this.notification.removeFile(orderId);
	}

	onClose() {
		this.close.emit();
	}

	floor(num: number) {
		return Math.floor(num);
	}
}
