﻿import { NgModule } from '@angular/core';
import { SettingsModule } from '../settings/settings.module';
import { SharedAnonymousModule } from './shared.anonymous.module';
import { TrimPipe } from './trim.pipe';
import { EupDatePipe } from './eupDate.pipe';
import { ReceivedDatePipe } from './receivedDate.pipe';
import { EupDefaultText } from './eupDefaultText.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { TableComponent } from './table/table.component';
import { SortableHeaderColumn } from './sortableHeaderColumn/sortableHeaderColumn.component';
import { DownloadLinkModal } from './downloadLinkModal/downloadLinkModal.component';
import { ChangeCaseTypeModal } from './changeCaseType/changeCaseType.component';
import { ChangeCaseTypeService } from './changeCaseType/changeCaseType.service';
import { ClickOutsideDirective } from './clickOutside.directive';
import { StickyHeaderComponent } from './stickyHeader/stickyHeader.component';
import { LabStickyHeaderComponent } from './labStickyHeader/labStickyHeader.component';
import { PopupComponent } from './popup/popup.component';
import { ExportPopupService as PopupService } from './popup/popup.service';
import { OrthoCadExportModal } from './orthoCadExport/orthoCadExport.component';
import { RestorativeExportModal } from './restorativeExport/restorativeExport.component';
import { TableFocusElementDirective } from './tableFocusElement.directive';
import { ShowButtonsRowDirective } from './showButtonsRow.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccessibilityService } from './accessibility.service';
import { MessagesService } from '../messages/messages.service';
import { DownloadNotification } from './downloadNotification/downloadNotification.component';
import { ExportDownloadsProgressList } from './exportDownloadsProgressList/exportDownloadsProgressList.component';
import { HighlightTextPipe } from './highlightText.pipe';
import { ItemAdderComponent } from './genericUI/itemAdder/itemAdder.component';
import { DieOrdersComponent } from './dieOrders/dieOrders.component';
import { TextareaComponent } from './genericUI/textarea/textarea.component';
import { InputComponent } from './genericUI/input/input.component';
import { DropdownComponent } from './genericUI/dropdown/dropdown.component';
import { Dropdown2 } from './genericUI/dropdown2/dropdown2.component';
import { CheckboxComponent } from './genericUI/checkbox/checkbox.component';
import { RadioButtonComponent } from './genericUI/radioButton/radioButton.component';
import { EupButtonComponent } from './genericUI/eupButton/eupButton.component';
import { IteroNoteComponent } from './iteroNote/iteroNote.component';
import { IteroOrdersNotesComponent } from './iteroOrdersNotes/iteroOrdersNotes.component';
import { TextSendComponent } from './genericUI/textSend/textSend.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LabExportModal } from './labExport/labExport.component';
import { WindowsAppButtonComponent } from './windowsAppButton/windowsAppButton.component';
import { PatientAppService } from './PatientApp/patientApp.service';
import { PatientAppModalComponent } from './PatientApp/patientAppModal/patientApp.component';
import { TimerDirective } from './timer/timer.directive';
import { InactivityTimerDirective } from './inactivity-timer/inactivity-timer.directive';
import { ActivityMonitorDirective } from './inactivity-timer/activity-monitor.directive';
import { RestrictInputDirective } from './restrictInput/restrictInput.directive';
import { FeaturesToggleDirective } from '../featuresToggleSettings/directive/featuresToggle.directive';

const sharedComponents = [
	OrderByPipe,
	TrimPipe,
	TableComponent,
	SortableHeaderColumn,
	DownloadLinkModal,
	LabExportModal,
	EupDatePipe,
	EupDefaultText,
	ReceivedDatePipe,
	ChangeCaseTypeModal,
	PopupComponent,
	TableFocusElementDirective,
	ShowButtonsRowDirective,
	OrthoCadExportModal,
	ClickOutsideDirective,
	StickyHeaderComponent,
	LabStickyHeaderComponent,
	ItemAdderComponent,
	DieOrdersComponent,
	RestorativeExportModal,
	SafeHtmlPipe,
	DownloadNotification,
	ExportDownloadsProgressList,
	HighlightTextPipe,
	DropdownComponent,
	Dropdown2,
	CheckboxComponent,
	InputComponent,
	RadioButtonComponent,
	TextareaComponent,
	EupButtonComponent,
	IteroNoteComponent,
	IteroOrdersNotesComponent,
	TextSendComponent,
	LabExportModal,
	WindowsAppButtonComponent,
	TimerDirective,
	InactivityTimerDirective,
	ActivityMonitorDirective,
	PatientAppModalComponent,
	RestrictInputDirective,
	FeaturesToggleDirective
];

@NgModule({
	imports: [SharedAnonymousModule, SettingsModule, InfiniteScrollModule, FontAwesomeModule],
	declarations: [...sharedComponents],
	exports: [SharedAnonymousModule, ...sharedComponents],
	providers: [ChangeCaseTypeService, PopupService, AccessibilityService, MessagesService, PatientAppService],
	entryComponents: [PatientAppModalComponent]
})
export class SharedModule {}
