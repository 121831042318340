/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passwordPolicyPopup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./passwordPolicyPopup.component";
import * as i5 from "../dom.service";
import * as i6 from "../../shared/notification/notification.component";
import * as i7 from "../eupRoutes.service";
var styles_PasswordPolicyPopupComponent = [i0.styles];
var RenderType_PasswordPolicyPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordPolicyPopupComponent, data: {} });
export { RenderType_PasswordPolicyPopupComponent as RenderType_PasswordPolicyPopupComponent };
function View_PasswordPolicyPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PasswordPolicy.AlertPopup_Part1_Must_Change")); _ck(_v, 1, 0, currVal_0); }); }
function View_PasswordPolicyPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " ", "", ". "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showLoginSettingsModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(12, null, [" ", " ", " ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PasswordPolicy.AlertPopup_Part1")); var currVal_1 = _co.expirationDate; var currVal_2 = i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 5).transform("PasswordPolicy.AlertPopup_Part2_Extension")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("PasswordPolicy.Alert_Popup_Click_Here")); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("PasswordPolicy.AlertPopup_Part2")); _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("PasswordPolicy.AlertPopup_Part3")); var currVal_6 = _co.expirationDate; var currVal_7 = i1.ɵunv(_v, 12, 2, i1.ɵnov(_v, 14).transform("PasswordPolicy.AlertPopup_Part4")); _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("PasswordPolicy.AlertPopup_Part5")); _ck(_v, 16, 0, currVal_8); }); }
export function View_PasswordPolicyPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordPolicyPopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordPolicyPopupComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.needsImmediatePasswordChange; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.needsImmediatePasswordChange; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PasswordPolicyPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eup-password-policy-popup", [], null, null, null, View_PasswordPolicyPopupComponent_0, RenderType_PasswordPolicyPopupComponent)), i1.ɵdid(1, 1097728, null, 0, i4.PasswordPolicyPopupComponent, [i5.DomService, i6.NotificationComponent, i7.EupRoutesService], null, null)], null, null); }
var PasswordPolicyPopupComponentNgFactory = i1.ɵccf("eup-password-policy-popup", i4.PasswordPolicyPopupComponent, View_PasswordPolicyPopupComponent_Host_0, { data: "data" }, {}, []);
export { PasswordPolicyPopupComponentNgFactory as PasswordPolicyPopupComponentNgFactory };
