var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
/* tslint:disable-next-line */
import jstz from 'jstz';
import 'moment-timezone';
import { ScannerModel } from './enums';
var Utils = /** @class */ (function () {
    function Utils(translateService) {
        this.translateService = translateService;
    }
    Utils.prototype.clearObservablesSubscriptions = function (obj) {
        for (var propertyName in obj) {
            if (obj.hasOwnProperty(propertyName) && obj[propertyName] instanceof Subscription) {
                obj[propertyName].unsubscribe();
            }
        }
    };
    Utils.prototype.lowerCaseProps = function (obj) {
        var parsedObj = {};
        Object.keys(obj).forEach(function (k) {
            parsedObj[k.toLowerCase()] = obj[k];
        });
        return parsedObj;
    };
    Utils.prototype.formatString = function (source) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        return source.replace(/{(\d+)}/g, function (match, num) {
            return typeof params[num] !== 'undefined' ? params[num] : match;
        });
    };
    Utils.prototype.existValueInEnum = function (type, value) {
        return Object.keys(type).filter(function (k) { return isNaN(Number(k)); }).filter(function (k) { return type[k] === value; }).length > 0;
    };
    Utils.prototype.translateKeyValueList = function (source, keyValueList) {
        var _this = this;
        return keyValueList.map(function (i) { return (__assign({}, i, { value: _this.translateService.instant(source + "." + i.key) })); });
    };
    Utils.prototype.compareVersions = function (version1, version2) {
        if (!version1 || !version2) {
            return -1;
        }
        var version1Parts = version1.split('.');
        var version2Parts = version2.split('.');
        var length = Math.min(version1Parts.length, version2Parts.length);
        for (var index = 0; index < length; index++) {
            var diff = parseInt(version1Parts[index], 10) - parseInt(version2Parts[index], 10);
            if (diff !== 0) {
                return diff;
            }
        }
        return version1.length - version2.length;
    };
    Utils.prototype.getScannerModelBySwVersion = function (scanner) {
        if (!scanner.softwareVersion) {
            return null;
        }
        var softwareVersionMajor = +scanner.softwareVersion.charAt(0);
        var scannerModel = null;
        if (softwareVersionMajor === 1) {
            scannerModel = ScannerModel.element;
        }
        else if (softwareVersionMajor === 2) {
            scannerModel = ScannerModel.evx;
        }
        else {
            scannerModel = ScannerModel.other;
        }
        return scannerModel;
    };
    Utils.prototype.isInternetExplorer = function () {
        return /*@cc_on!@*/ false || !!document['documentMode'];
    };
    return Utils;
}());
export { Utils };
export function formatDate(value, overrideFormat, settingsDateFormat) {
    var defaultFormat = 'MM/DD/YYYY';
    var timezone = jstz.determine();
    var momentObject = moment(value);
    if (momentObject.isValid()) {
        var format = overrideFormat;
        if (!overrideFormat) {
            format = settingsDateFormat;
            if (!format) {
                format = defaultFormat;
            }
        }
        return momentObject.tz(timezone.name()).format(format);
    }
    else {
        return '';
    }
}
