﻿/** This service is used as a wrapper to easily mock invocations inside global objects and some prototypes.*/
export class AccessibilityService {
	redirectTo(href: string): void {
		window.location.replace(href);
	}

	getLocation(): Location {
		return window.location;
	}

	getPathName(): string {
		return window.location.pathname;
	}

	getCurrentRelativeUrl(): string {
		const url = this.getLocation();
		return url.pathname + url.search;
	}

	addViewerMessageEventListener(): void {
		window.addEventListener('message', window['receiveMessage'] as (e: MessageEvent) => void, false);
	}

	removeViewerMessageEventListener(): void {
		window.removeEventListener('message', window['receiveMessage'] as (e: MessageEvent) => void, false);
	}

	getCurrentDate(): Date {
		return new Date();
	}

	storeGlobally(name: string, obj: any): void {
		window[name] = obj;
	}
}
