import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
var Dropdown2 = /** @class */ (function () {
    function Dropdown2() {
        this.isRemoveEmptyItems = true;
        this.onSelect = new EventEmitter();
    }
    Object.defineProperty(Dropdown2.prototype, "items", {
        get: function () {
            return this.itemsList;
        },
        set: function (listOfItems) {
            this.itemsList = listOfItems;
            this.selected = this.itemsList[0];
            this.realSelectBoxValue = this.selected.value;
        },
        enumerable: true,
        configurable: true
    });
    Dropdown2.prototype.onSelected = function (item) {
        this.selected = item;
        this.onSelect.emit(item);
        this.realSelectBoxValue = item.value;
    };
    Dropdown2.prototype.ngOnChanges = function (changes) {
        if (changes.items !== undefined &&
            changes.items.currentValue !== undefined &&
            changes.items.currentValue !== null) {
            if (this.isRemoveEmptyItems) {
                changes.items.currentValue = changes.items.currentValue.filter(function (i) { return i.value !== null; });
            }
        }
        if (changes.selectedValue !== undefined &&
            changes.selectedValue.currentValue !== undefined &&
            changes.selectedValue.currentValue !== null) {
            if (this.items !== undefined && changes.selectedValue.currentValue !== 0) {
                var found = this.items.find(function (i) { return i.key === changes.selectedValue.currentValue; });
                this.selected = found !== undefined ? found : this.selected;
            }
        }
    };
    return Dropdown2;
}());
export { Dropdown2 };
