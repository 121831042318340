import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'eup-button',
	templateUrl: './eupButton.component.html',
	styleUrls: ['./eupButton.component.scss']
})
export class EupButtonComponent implements OnInit {
	@Input()
	public iconPath: string;

	@Input()
	public disabled: boolean;

	@Input()
	public isProcessing: boolean;

	@Input()
	public buttonText: string;

	@Input()
	public textAlign: string = 'left';

	@Input()
	public iconClass: string; // This has to be a global name, for example:  ::ng-deep my-icon-class-name

	@Input()
	public isAlert: boolean;

	@Input()
	public clickHandler: Function;

	public isIconAndText: boolean;

	@Input()
	public useHandler(handler: Function): void {
		// If the handler needs its original context (this), the handler MUST be an arrow function
		handler();
	}

	ngOnInit() {
		this.isIconAndText = ( Boolean(this.iconClass) || Boolean(this.iconPath) || Boolean(this.isProcessing) ) ? true : false;
	}
}
