import { EventEmitter, OnInit } from '@angular/core';
var DieOrdersComponent = /** @class */ (function () {
    function DieOrdersComponent() {
        this.numOfModels = 0;
        this.additionalDies = 0;
        this.emittedOrder = new EventEmitter();
    }
    Object.defineProperty(DieOrdersComponent.prototype, "disabled", {
        get: function () {
            return this.pDisabled;
        },
        set: function (disabledStatus) {
            if (disabledStatus) {
                this.backupAllValues();
                this.resetValuesToZero();
            }
            else {
                this.restoreDisabledValues();
            }
            this.pDisabled = disabledStatus;
        },
        enumerable: true,
        configurable: true
    });
    DieOrdersComponent.prototype.publishOrderInformation = function (orderInfo) {
        var orderInformation = orderInfo || {
            NumOfModels: this.numOfModels,
            AdditionalDies: this.additionalDies,
            DieDitch: this.dieDitch
        };
        this.emittedOrder.emit(orderInformation);
    };
    DieOrdersComponent.prototype.setModelOrders = function (modelsNum) {
        this.numOfModels = modelsNum;
        this.backupAllValues();
        this.publishOrderInformation();
    };
    DieOrdersComponent.prototype.setDieOrders = function (ordersNum) {
        this.additionalDies = ordersNum;
        this.backupAllValues();
        this.publishOrderInformation();
    };
    DieOrdersComponent.prototype.setDieDitch = function (evt) {
        this.dieDitch = evt;
        this.backupAllValues();
        this.publishOrderInformation();
    };
    DieOrdersComponent.prototype.ngOnInit = function () {
        this.backedUpValues = {
            NumOfModels: this.numOfModels,
            AdditionalDies: this.additionalDies,
            DieDitch: this.dieDitch
        };
    };
    DieOrdersComponent.prototype.backupAllValues = function () {
        this.backedUpValues = {
            NumOfModels: this.numOfModels,
            AdditionalDies: this.additionalDies,
            DieDitch: this.dieDitch
        };
    };
    DieOrdersComponent.prototype.resetValuesToZero = function () {
        this.publishOrderInformation({
            NumOfModels: 0,
            AdditionalDies: 0,
            DieDitch: false
        });
    };
    DieOrdersComponent.prototype.restoreDisabledValues = function () {
        this.publishOrderInformation(this.backedUpValues);
    };
    return DieOrdersComponent;
}());
export { DieOrdersComponent };
