import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate, Route } from '@angular/router';
import { AppConfigService } from 'app/services/appConfig/appConfigService';

@Injectable()
export class FeaturesToggleSettingsGuard implements CanLoad, CanActivate {
	constructor(
        private router: Router,
        private appConfigService: AppConfigService
    ) {}
    
    canLoad(route: Route): boolean {
        // Disable in production
        const result = !JSON.parse(this.appConfigService.appSettings.isProduction);
        if (!result){
			this.router.navigate(['/pagenotfound']);
        }
        return result;
    }

    canActivate(): boolean {
        // Disable in production
        const result = !JSON.parse(this.appConfigService.appSettings.isProduction);
        if (!result){
			this.router.navigate(['/pagenotfound']);
        }
        return result;
    }
}
