var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DesktopNotificationsService } from './desktopNotifications.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { AuthService } from 'app/services/authentication/auth.service';
import { of, throwError } from 'rxjs';
import { filter, switchMap, take, catchError, retryWhen, skip } from 'rxjs/operators';
import { SettingsService } from '@shared/settings.service';
import { LoggerService } from '../logging/logger.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import * as i0 from "@angular/core";
import * as i1 from "./desktopNotifications.service";
import * as i2 from "../../core/globalSettings.service";
import * as i3 from "../authentication/auth.service";
import * as i4 from "../../shared/settings.service";
import * as i5 from "../logging/logger.service";
import * as i6 from "../../core/eupRoutes.service";
var DesktopNotificationsInitializerService = /** @class */ (function () {
    function DesktopNotificationsInitializerService(desktopNotificationsService, globalSettingsService, authService, settingsService, loggerService, eupRoutesService) {
        var _this = this;
        this.desktopNotificationsService = desktopNotificationsService;
        this.globalSettingsService = globalSettingsService;
        this.authService = authService;
        this.settingsService = settingsService;
        this.loggerService = loggerService;
        this.eupRoutesService = eupRoutesService;
        this.logErrorWithMessage = function (message, error) {
            return _this.loggerService.error(message, { error: error, module: 'DesktopNotificationsInitializerService' });
        };
    }
    DesktopNotificationsInitializerService.prototype.trackAuthentication = function () {
        this.trackLogIn();
        this.trackLogOut();
    };
    DesktopNotificationsInitializerService.prototype.startNotifications = function () {
        return __awaiter(this, void 0, void 0, function () {
            var notificationAgentUrl, token, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        notificationAgentUrl = this.eupRoutesService.notificationAgentUrl;
                        token = this.globalSettingsService.get().jwtAccessToken;
                        if (!notificationAgentUrl) {
                            message = 'notificationAgentUrl url is not specified in global settings';
                            this.logErrorWithMessage(message);
                            throw Error(message);
                        }
                        return [4 /*yield*/, this.desktopNotificationsService.stopNotifications()];
                    case 1:
                        _a.sent();
                        this.desktopNotificationsService.initializeNotifications(notificationAgentUrl, token);
                        return [2 /*return*/];
                }
            });
        });
    };
    DesktopNotificationsInitializerService.prototype.stopNotifications = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.desktopNotificationsService.stopNotifications()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesktopNotificationsInitializerService.prototype.trackLogIn = function () {
        var _this = this;
        if (this.trackLogInSubscription) {
            return;
        }
        this.trackLogInSubscription = this.authService.authStatus$
            .pipe(filter(function (status) { return status.isAuthenticated; }), switchMap(function (_) {
            var settingsExist = !!_this.globalSettingsService.get();
            return settingsExist ? of(undefined) : _this.globalSettingsService.initializeCalled$.pipe(take(1));
        }), filter(function (_) { return !!_this.globalSettingsService.get().jwtAccessToken; }), switchMap(function (_) {
            var companyId = _this.globalSettingsService.getFirstCompany().id;
            return _this.settingsService.getSettings(companyId, true, false);
        }), filter(function (settings) { return settings.userSettings.allowDesktopNotifications; }), catchError(function (error) {
            _this.logErrorWithMessage('error in notification initializer: ', error);
            return throwError(error);
        }), retryWhen(function (_) { return _this.authService.authStatus$.pipe(skip(1)); })).subscribe(function (_) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, this.startNotifications()];
        }); }); }, function (error) { return _this.logErrorWithMessage('unhandled error in notification initializer: ', error); });
    };
    DesktopNotificationsInitializerService.prototype.trackLogOut = function () {
        var _this = this;
        if (this.trackLogOutSubscription) {
            return;
        }
        this.trackLogOutSubscription = this.authService.authStatus$
            .pipe(filter(function (status) { return !status.isAuthenticated && !status.isUnauthenticatedByTimeout; })).subscribe(function (_) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.stopNotifications()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DesktopNotificationsInitializerService.ngInjectableDef = i0.defineInjectable({ factory: function DesktopNotificationsInitializerService_Factory() { return new DesktopNotificationsInitializerService(i0.inject(i1.DesktopNotificationsService), i0.inject(i2.GlobalSettingsService), i0.inject(i3.AuthService), i0.inject(i4.SettingsService), i0.inject(i5.LoggerService), i0.inject(i6.EupRoutesService)); }, token: DesktopNotificationsInitializerService, providedIn: "root" });
    return DesktopNotificationsInitializerService;
}());
export { DesktopNotificationsInitializerService };
