﻿import {Injectable} from '@angular/core';
import {Appearance} from '../shared/enums';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs';

export const DEFAULT_THEME = Appearance.Dark;

@Injectable()
export class AppearanceService {
	private appearanceChangeSource: BehaviorSubject<Appearance>;
	appearanceChanged$: Observable<Appearance>;

	private currTheme: Appearance;

	constructor() {
		this.currTheme = DEFAULT_THEME;
		this.appearanceChangeSource = new BehaviorSubject(this.currTheme);
		this.appearanceChanged$ = this.appearanceChangeSource.asObservable().distinctUntilChanged();
	}

	private get theme() {
		return this.currTheme;
	}

	private set theme(theme: Appearance) {
		this.currTheme = theme;
		this.appearanceChangeSource.next(this.currTheme);
	}

	toTheme(theme: Appearance) {
		this.theme = theme;
	}

	toLightTheme() {
		this.theme = Appearance.Light;
	}

	toDarkTheme() {
		this.theme = Appearance.Dark;
	}
}
