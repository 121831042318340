import { Sorting, PagedQueryParameters } from '../generalInterfaces';
import { SortEventEmitterService } from './sortEventEmitter.service';
var SortableHeaderColumn = /** @class */ (function () {
    function SortableHeaderColumn(emitter) {
        this.emitter = emitter;
        this.active = false;
        this.sortingMode = Sorting.Descending;
    }
    Object.defineProperty(SortableHeaderColumn.prototype, "isAscending", {
        get: function () {
            // sortingMode converted to integer because when binding sortingMode from
            // html makes sortingMode to be string
            return +this.sortingMode === Sorting.Ascending;
        },
        enumerable: true,
        configurable: true
    });
    SortableHeaderColumn.prototype.sortWasClicked = function (event) {
        this.sortingMode = this.sortingMode === Sorting.Descending ? Sorting.Ascending : Sorting.Descending;
        var sortAndFilter = new PagedQueryParameters();
        sortAndFilter.sort = this.sortingMode;
        sortAndFilter.sortField = this.name;
        this.active = true;
        this.emitter.clickSort(sortAndFilter);
        event.stopPropagation();
        event.preventDefault();
    };
    return SortableHeaderColumn;
}());
export { SortableHeaderColumn };
