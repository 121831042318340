﻿<div>
    <div *ngIf="needsImmediatePasswordChange">
        {{('PasswordPolicy.AlertPopup_Part1_Must_Change' | translate)}}
    </div>
    <div *ngIf="!needsImmediatePasswordChange">
        <div>
            <div>
                {{('PasswordPolicy.AlertPopup_Part1' | translate)}} {{ expirationDate }}{{'PasswordPolicy.AlertPopup_Part2_Extension' | translate }}.
            </div>
            <div>
                <a href="javascript:void(0);" (click)="showLoginSettingsModal()">{{'PasswordPolicy.Alert_Popup_Click_Here' | translate }}</a> {{('PasswordPolicy.AlertPopup_Part2' | translate)}}
            </div>
            {{'PasswordPolicy.AlertPopup_Part3' | translate }} {{ expirationDate }} {{'PasswordPolicy.AlertPopup_Part4' | translate }} <br />
            {{'PasswordPolicy.AlertPopup_Part5' | translate }}
        </div>
    </div>
</div>
