﻿import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
	selector: '[show-buttons-row]'
})
export class ShowButtonsRowDirective {
	@HostBinding('class.hidden') isHidden = false;

	public constructor(private readonly el: ElementRef) {}

	ngAfterContentInit(): void {
		const buttons = this.el.nativeElement.querySelectorAll('* button');
		const anyVisibleButton =
			Array.prototype.slice.call(buttons).filter((x) => window.getComputedStyle(x).display !== 'none').length > 0;

		this.isHidden = !anyVisibleButton;
	}
}
