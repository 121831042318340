﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalSettingsService } from '../globalSettings.service';

@Injectable()
export class RolesGuard implements CanActivate {
	constructor(private globalSettings: GlobalSettingsService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const settings = this.globalSettings.get();
		if (settings) {
			const expectedRoles = route.data.expectedRoles;
			if (expectedRoles && expectedRoles.indexOf(settings.roleType) > -1) {
				return true;
			} else {
				// TODO: display error about role permissions?
				this.router.navigate([ '/login' ], { queryParams: { returnUrl: window.location.pathname } });
			}
		}
		this.router.navigate([ '/login' ], { queryParams: { returnUrl: window.location.pathname } });
		return false;
	}
}
