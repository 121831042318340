﻿import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
	selector: 'eup-generic-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent {
	private _checked: boolean;

	@Output()
	public checkBoxStateEmitter: EventEmitter<boolean> = new EventEmitter();

	@Input()
	public disabled: boolean;

	@Input()
    public set checked(value: boolean) {
		this._checked = value || false;
    }

	public changed(value) {
        this.checkBoxStateEmitter.emit(value);
	}

	public get checked(): boolean {
		return this._checked;
	}
}
