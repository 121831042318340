/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-page.component.ngfactory";
import * as i2 from "./error-page.component";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../core/globalSettings.service";
import * as i6 from "./forbidden.component";
var styles_ForbbidenComponent = [];
var RenderType_ForbbidenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForbbidenComponent, data: {} });
export { RenderType_ForbbidenComponent as RenderType_ForbbidenComponent };
export function View_ForbbidenComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "eup-error-page", [["statusCode", "403"]], null, null, null, i1.View_ErrorPage_0, i1.RenderType_ErrorPage)), i0.ɵdid(1, 2146304, null, 0, i2.ErrorPage, [i3.Router, i0.ElementRef, i4.TranslateService, i5.GlobalSettingsService], { statusCode: [0, "statusCode"], errorHeader: [1, "errorHeader"], errorDescription: [2, "errorDescription"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "403"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("Errors.Error_403_Title")), ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform("Errors.Error_403_Message")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ForbbidenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ForbbidenComponent_0, RenderType_ForbbidenComponent)), i0.ɵdid(1, 49152, null, 0, i6.ForbbidenComponent, [], null, null)], null, null); }
var ForbbidenComponentNgFactory = i0.ɵccf("ng-component", i6.ForbbidenComponent, View_ForbbidenComponent_Host_0, {}, {}, []);
export { ForbbidenComponentNgFactory as ForbbidenComponentNgFactory };
