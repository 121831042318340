const DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED = 'DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED';
const WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING = 'WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING';
const RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS = 'RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS';
const EDIT_WITH_ITERO_LAB_SUCCESS = 'EDIT_WITH_ITERO_LAB_SUCCESS';
const LAB_ACTION_RAISED = 'LAB_ACTION_RAISED';

export {
	DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED,
	WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING,
	RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS,
	EDIT_WITH_ITERO_LAB_SUCCESS,
	LAB_ACTION_RAISED
};
